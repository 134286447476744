import { useState } from "react";
import { registerPublishers } from "../../api/registerPublishers";
import { Layout } from "../../components/Layout";
import TiptapEditor from "../../components/TipTapEditor";
import { isValidEmail } from "../../utils/isValidEmail";
import { isValidPhone } from "../../utils/isValidPhone";
import { useToast } from "../../context/ToastContext";

const RegisterPublishers = () => {
  const { showToast } = useToast();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [sinpePhone, setSinpePhone] = useState("");
  const [isSinpePhoneValid, setIsSinpePhoneValid] = useState(false);
  const [name, setName] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [bankInformation, setBankInformation] = useState<string>("");

  const [error, setError] = useState<string | null>(null);
  const [confirmation, setConfirmation] = useState<string | null>(null);
  // State to controll the loading of the fetching of the data
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameValue = event.target.value;
    setName(nameValue);
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    let phoneValue = event.target.value.replace(/\D/g, "");
    if (phoneValue.length > 8) {
      phoneValue = phoneValue.slice(0, 8);
    }
    setPhone(phoneValue);
    setIsPhoneValid(isValidPhone(phoneValue));
  };

  const handleChangeSinpePhone = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let sinpePhoneValue = event.target.value.replace(/\D/g, "");
    if (sinpePhoneValue.length > 8) {
      sinpePhoneValue = sinpePhoneValue.slice(0, 8);
    }
    setSinpePhone(sinpePhoneValue);
    setIsSinpePhoneValid(isValidPhone(sinpePhoneValue));
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setIsEmailValid(isValidEmail(emailValue));
  };

  const handleRegister = () => {
    setIsLoading(true);
    registerPublishers({
      email,
      name,
      phoneNumber: phone,
      file: file as File,
      sinpePhoneNumber: sinpePhone,
      bankInformation
    })
      .then(() => {
        setIsLoading(false);
        showToast("Promotora registrado correctamente");
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
        showToast("Ha ocurrido un error al registrar el publisher");
      });
  };

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === "Enter") {
      handleRegister();
    }
  };

  const isFormValid =
    isEmailValid &&
    isPhoneValid &&
    name.length > 0 &&
    isSinpePhoneValid &&
    bankInformation.length > 0;

  return (
    <Layout>
      <div
        id="register-module"
        className="flex flex-col bg-primary w-full md:w-[450px] p-2 md:m-auto"
      >
        <div id="register-headers" className="mb-4">
          <h1 className="text-center text-bold text-xl text-tertiary">
            Registrar <span className="text-secondary">Promotora</span>
          </h1>
        </div>
        <div id="register-form">
          <form className="flex flex-col">
            <label className="text-lg mb-1 text-tertiary">Nombre *</label>
            <input
              className="border ronded-2 mb-2 p-2 text-primary"
              type="text"
              disabled={isLoading}
              value={name}
              onChange={handleChangeName}
            />
            <label className="text-lg mb-1 text-tertiary">Email *</label>
            <input
              className="border ronded-2 mb-2 p-2 text-primary"
              type="text"
              disabled={isLoading}
              value={email}
              onChange={handleEmailChange}
            />
            <label className="text-lg mb-1 text-tertiary">Teléfono *</label>
            <input
              className="border ronded-2 mb-2 p-2 text-primary"
              type="text"
              disabled={isLoading}
              value={phone}
              onChange={handleChangePhone}
            />
            <label className="text-lg mb-1 text-tertiary">
              Teléfono para sinpe *
            </label>
            <input
              className="border ronded-2 mb-2 p-2 text-primary"
              type="text"
              disabled={isLoading}
              value={sinpePhone}
              onChange={handleChangeSinpePhone}
            />

            <div className="mb-10">
              <label className="text-lg mb-1 text-tertiary">
                Información bancaria *
              </label>
              <TiptapEditor
                content={bankInformation}
                setContent={setBankInformation}
              />
            </div>

            <label className="text-lg mb-1 text-tertiary">Imagen </label>
            <input
              className="border ronded-2 mb-2 p-2 text-tertiary"
              type="file"
              pattern="\d*"
              disabled={isLoading}
              onChange={(e) => {
                if (e.target.files) {
                  setFile(e.target.files[0]);
                }
              }}
              onKeyDown={handleKeyDown}
            />
          </form>

          <button
            className={`w-full text-lg p-2 mt-4 text-primary ${isFormValid && !isLoading ? "bg-secondary " : "bg-gray-200 cursor-not-allowed"}`}
            type="button"
            onClick={handleRegister}
            disabled={!isFormValid || isLoading}
          >
            {isLoading ? "Registrando publisher..." : "Registrar"}
          </button>
          {confirmation ? (
            <div className="bg-tertiary">
              <p className="text-green-500">{confirmation}</p>
            </div>
          ) : null}
          {error ? (
            <div className="bg-tertiary">
              <p className="text-red-500">{error}</p>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default RegisterPublishers;
