import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { updatePublisherTiers } from "../../api/updatePublisherTiers";
import { useToast } from "../../context/ToastContext";
import { EventData, EventTiersData } from "../../types/EventDataTypes";

interface EditTiersModalProps {
  show: boolean;
  onHide: () => void;
  event: EventData | null;
  refresh?: () => void;
}

const EditTiersModal = ({
  show,
  onHide,
  event,
  refresh
}: EditTiersModalProps) => {
  const { showToast } = useToast();
  const [eventTiers, setEventTiers] = useState<EventTiersData[]>(
    event?.eventTiers || []
  );
  const [idEventTierSelected, setIdEventTierSelected] = useState<number>(0);
  const [nameTier, setNameTier] = useState<string>("");
  const [price, setPrice] = useState<number | undefined>(undefined);
  const [maxQuantity, setMaxQuantity] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSoldOut, setIsSoldOut] = useState<boolean | undefined>(undefined);

  const handleChange = (setState: (value: string) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState(e.target.value);
    };
  };

  const handleToggle = () => {
    setIsSoldOut(!isSoldOut);
  };
  const handleUpdateEventTier = async () => {
    setIsLoading(true);
    updatePublisherTiers({
      id: idEventTierSelected,
      name: nameTier,
      price: price,
      maxQuantity: maxQuantity,
      isSoldOut: isSoldOut
    })
      .then(() => {
        setIsLoading(false);
        showToast("Tier actualizado correctamente", "success");
        refresh && refresh();
        onHide();
      })
      .catch((error) => {
        setIsLoading(false);
        showToast(error.message, "error");
      });
  };

  const isValidForm =
    nameTier !== "" ||
    price !== undefined ||
    maxQuantity !== undefined ||
    idEventTierSelected !== 0;

  useEffect(() => {
    if (show) {
      const scrollBarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.paddingRight = `${scrollBarWidth}px`;
    }

    // Cleanup function to reset styles if the component unmounts
    return () => {
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.width = "";
      document.body.style.paddingRight = "";
    };
  }, [show]);

  useEffect(() => {
    if (idEventTierSelected !== 0) {
      const selectedTier = eventTiers.find(
        (tier) => tier.id === idEventTierSelected
      );
      if (selectedTier) {
        setNameTier(selectedTier.name);
        setPrice(selectedTier.price);
        setMaxQuantity(selectedTier.maxQuantity);
        setIsSoldOut(selectedTier.isSoldOut);
      }
    }
  }, [idEventTierSelected, eventTiers]);

  return (
    <>
      {show && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75"
          onClick={onHide}
        >
          <div
            className="bg-primary w-full md:w-[500px] p-4 mx-4  relative max-h-[80vh] overflow-y-auto modal-content"
            onClick={(e) => e.stopPropagation()} // Previene el cierre al hacer clic dentro del modal
          >
            <h1 className="text-secondary text-center text-xl">
              Editar localidades
            </h1>
            <button
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 z-50"
              onClick={onHide}
            >
              <MdClose size={24} />
            </button>

            <div id="register-form" className="pt-4">
              <form className="flex flex-col">
                <label className="text-lg mb-1 text-tertiary">
                  Seleccione un tier para editar
                </label>
                <div className="flex flex-wrap gap-4 items-center justify-center">
                  {eventTiers.length === 0 && (
                    <p className="text-lg text-tertiary">
                      No hay tiers disponibles para este evento
                    </p>
                  )}

                  {/* // create a select/options dropdown with the tiers */}
                  <select
                    className="border border-r-0 mb-2 p-2 text-primary w-full "
                    value={idEventTierSelected}
                    onChange={(e) =>
                      setIdEventTierSelected(Number(e.target.value))
                    }
                  >
                    <option value={0}>Seleccione un tier</option>
                    {eventTiers.map((tier) => (
                      <option key={tier.id} value={tier.id}>
                        {tier.name}
                      </option>
                    ))}
                  </select>
                </div>
                <label className="text-lg mb-1 text-tertiary">
                  Nuevo nombre{" "}
                </label>
                <input
                  className="border mb-2 p-2 text-primary"
                  type="text"
                  disabled={isLoading}
                  value={nameTier}
                  onChange={handleChange(setNameTier)}
                />
                <label className="text-lg mb-1 text-tertiary">Precio </label>
                <input
                  className="border mb-2 p-2 text-primary"
                  type="number"
                  disabled={isLoading}
                  value={price || ""}
                  onChange={(e) => setPrice(Number(e.target.value))}
                />
                <label className="text-lg mb-1 text-tertiary">
                  Cantidad máxima
                </label>
                <input
                  className="border mb-2 p-2 text-primary"
                  type="number"
                  disabled={isLoading}
                  value={maxQuantity || ""}
                  onChange={(e) => setMaxQuantity(Number(e.target.value))}
                />
                <label className="text-lg mb-1 text-tertiary">Agotado</label>
                <div>
                  <label
                    htmlFor="Toggle4"
                    className="inline-flex items-center p-1 cursor-pointer bg-gray-700 text-gray-100"
                  >
                    <input
                      id="Toggle4"
                      type="checkbox"
                      className="hidden peer"
                      onClick={handleToggle}
                    />
                    <span
                      className={`px-4 py-2  ${isSoldOut ? "bg-gray-700" : "bg-gray-400"}`}
                    >
                      No
                    </span>
                    <span
                      className={`px-4 py-2 ${isSoldOut ? "bg-secondary text-black" : ""}`}
                    >
                      Si
                    </span>
                  </label>
                </div>
              </form>
            </div>
            <div id="register-form">
              <button
                className={`w-full text-lg p-2 mt-4 text-primary ${!isLoading && isValidForm ? "bg-secondary" : "bg-gray-200 cursor-not-allowed"}`}
                type="button"
                disabled={isLoading || !isValidForm}
                onClick={handleUpdateEventTier}
              >
                {isLoading ? "Actualizando..." : "Actualizar"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditTiersModal;
