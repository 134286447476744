import { createContext, SetStateAction, useEffect, useState } from "react";
import { generateToken } from "../api/generateToken";
import { generateTokenFromGoogle } from "../api/generateTokenFromGoogle";
import { useRoute } from "../hooks/useRoute";
import {
  AppContextProviderProps,
  AppContextType
} from "../types/AppContextTypes";
import {
  GenerateTokenFromGooglePayload,
  GenerateTokenPayload
} from "../types/GenerateTokenTypes";
import { UserData } from "../types/UserDataTypes";
import { deleteCookie } from "../utils/deleteCookie";
import { getCookie } from "../utils/getCookie";
import { setCookie } from "../utils/setCookie";
import { UpdateUserInformationResponse } from "../types/UserProfileUpdate";

export const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const { setRoute } = useRoute();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<UserData | null>(null);
  const [jwt, setJwt] = useState<string | null>(null);
  const [authError, setAuthError] = useState<string | null>(() => null);

  const isGenerateTokenPayload = (
    payload: GenerateTokenPayload | GenerateTokenFromGooglePayload
  ): payload is GenerateTokenPayload => {
    return (payload as GenerateTokenPayload).email !== undefined;
  };

  const login = async (
    payload: GenerateTokenPayload | GenerateTokenFromGooglePayload,
    path: string
  ) => {
    setIsLoading(true);
    try {
      let data;
      if (isGenerateTokenPayload(payload)) {
        data = (await generateToken(payload)).data;
      } else {
        data = (await generateTokenFromGoogle(payload)).data;
      }
      setCookie({
        name: "jwt",
        value: data.jwt,
        days: 90,
        secure: true, // Asegúrate de estar en un entorno HTTPS
        sameSite: "None" // Necesario para que las cookies funcionen en contextos cross-site
      });
      setCookie({
        name: "user",
        value: JSON.stringify(data.user),
        days: 90,
        secure: true,
        sameSite: "None"
      });
      setJwt(data.jwt);
      setUser(data.user);
      setIsLoading(false);
      setAuthError(null);
      setIsAuthenticated(true);
      setRoute(path);
      window.history.pushState({}, "", path);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
      setAuthError(error.message);
      setIsLoading(false);
    }
  };

  const updateUserProfile = async (
    updatedUser: UpdateUserInformationResponse
  ) => {
    setUser(updatedUser.data);
    setCookie({
      name: "user",
      value: JSON.stringify(updatedUser.data),
      days: 90,
      secure: true,
      sameSite: "None"
    });
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    setJwt(null);
    deleteCookie("jwt");
    deleteCookie("user");
  };

  const checkAuthStatus = () => {
    const jwtFromCookie = getCookie("jwt");
    const userFromCookie = getCookie("user");
    if (jwtFromCookie && userFromCookie) {
      setJwt(jwtFromCookie);
      setUser(JSON.parse(userFromCookie));
      setIsAuthenticated(true);
    }
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  return (
    <AppContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isDarkMode,
        setIsDarkMode,
        isNavOpen,
        setIsNavOpen,
        isAuthenticated,
        setIsAuthenticated,
        updateUserProfile,
        user,
        login,
        logout,
        jwt,
        authError
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
