import { useAppContext } from "../hooks/useAppContext";

export const AppLoader = () => {
  const { isLoading } = useAppContext();

  if (!isLoading) {
    return null;
  }

  return (
    <div className="absolute h-[100vh] w-[100vw] opacity-50 bg-gray-600">
      <div className="m-auto">
        <div className="w-12 h-12 rounded-full animate-spin border border-solid border-yellow-500 border-t-transparent"></div>
      </div>
    </div>
  );
};
