import { useState } from "react";
import { EventData } from "../types/EventDataTypes";

type ModalType =
  | "editEventModal"
  | "editTierModal"
  | "editSpecificTierModal"
  | "addTierModal"
  | "confirmationModal"
  | null;

export const useModalManager = () => {
  const [currentEvent, setCurrentEvent] = useState<EventData | undefined>(
    undefined
  );
  const [currentTierId, setCurrentTierId] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState<ModalType>(null);

  const openSpecificModal = (
    modalName: ModalType,
    event?: EventData,
    tierId?: number
  ) => {
    if (modalName === "confirmationModal" && tierId !== undefined) {
      setCurrentTierId(tierId);
      setOpenModal(modalName);
    } else if (modalName === "editSpecificTierModal" && tierId !== undefined) {
      setCurrentTierId(tierId);
      setOpenModal(modalName);
    } else if (event) {
      setCurrentEvent(event);
      setOpenModal(modalName);
    } else {
      setOpenModal(null);
    }
  };

  const closeModal = () => {
    setCurrentEvent(undefined);
    setCurrentTierId(null);
    setOpenModal(null);
  };

  return {
    currentEvent,
    currentTierId,
    openModal,
    openSpecificModal,
    closeModal
  };
};
