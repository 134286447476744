import { useEffect, useState } from "react";
import { validateActivationCode } from "../../api/validateActivationCode";
import { Layout } from "../../components/Layout";
import { useToast } from "../../context/ToastContext";
import { useRoute } from "../../hooks/useRoute";
import { resendActivationCode } from "../../api/resendActivationCode";
import { useAppContext } from "../../hooks/useAppContext";
import { use } from "i18next";

export const ValidateAccount = () => {
  const { showToast } = useToast();
  const { user, jwt, updateUserProfile } = useAppContext();
  const { setRoute, setParams } = useRoute();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isValidationCodeValid, setIsValidationCodeValid] = useState(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setIsEmailValid(emailValue.length > 0);
  };

  const handleValidationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const validationCodeValue = event.target.value;
    setCode(validationCodeValue);
    setIsValidationCodeValid(
      validationCodeValue.length > 0 && validationCodeValue.length < 5
    );
  };

  const handleValidateAccount = async () => {
    // Activate account
    try {
      setIsLoading(true);
      const validation = await validateActivationCode({
        email,
        code: code
      });
      console.log(validation);
      if (validation.status_code === 422) {
        showToast(validation.message, "error");
        return;
      }
      console.log("has jwt: ", jwt);

      // accountValidated should be set to true
      // if the user is already logged in
      if (user) {
        const updatedUser = { ...user, isAccountValidated: true };
        updateUserProfile({
          data: updatedUser,
          message: "User profile updated",
          status: "success",
          status_code: 200
        });
      }

      if (jwt) {
        // If the user is already logged in, we route to the home page
        window.history.pushState({}, "", `/`);
        setRoute("/");
      } else {
        showToast(
          "Se ha validado tu cuenta exitosamente, ahora puedes iniciar sesión",
          "success"
        );
        setIsLoading(false);
        // setParams({ email, step: "1" });
        window.history.pushState({}, "", `/login`);
        setRoute(`/login`); // Asegúrate de que la ruta se actualiza
      }
    } catch (error) {
      showToast(
        "Ha ocurrido un error al activar tu cuenta, intentalo mas tarde",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendValidationCode = async () => {
    try {
      setIsLoading(true);
      const validation = await resendActivationCode({ email });
      if (validation.status_code === 422) {
        showToast(validation.message, "error");
        setIsLoading(false);
        return;
      }
      showToast("Se ha reenviado el código de validación", "success");
      setIsLoading(false);
    } catch (error) {
      showToast(
        "Ha ocurrido un error al reenviar el código de validación, intentalo mas tarde",
        "error"
      );
    }
  };

  useEffect(() => {
    if (user?.email) {
      setEmail(user.email);
      setIsEmailValid(true);
    }
  }, [user]);

  return (
    <Layout>
      <div
        id="account-validation-module"
        className="flex flex-col bg-primary w-full md:w-[450px] p-2 md:m-auto"
      >
        <div id="activate-account" className="mb-4">
          <h1 className="text-center text-bold text-xl text-tertiary">
            Activa tu cuenta
          </h1>
        </div>
        <div id="validate-account-form-fields">
          <form className="flex flex-col">
            {user?.email ? (
              <p className="text-lg mb-1 text-tertiary">Email: {user.email}</p>
            ) : (
              <>
                <label className="text-lg mb-1 text-tertiary">Email *</label>
                <input
                  className="border ronded-2 mb-2 p-2 text-primary"
                  type="text"
                  disabled={isLoading}
                  value={email}
                  onChange={handleEmailChange}
                />
              </>
            )}
            <label className="text-lg mb-1 text-tertiary">
              Código de validación *
            </label>
            <input
              className="border ronded-2 mb-2 p-2 text-primary"
              type="text"
              disabled={isLoading}
              value={code}
              onChange={handleValidationCodeChange}
            />
          </form>
        </div>
        <div id="activate-account-button" className="flex flex-col mb-4 mt-4">
          <button
            className={`${isLoading || !isEmailValid || !isValidationCodeValid ? "bg-gray-200 cursor-not-allowed" : "bg-secondary "} text-primary p-2`}
            disabled={isLoading || !isEmailValid || !isValidationCodeValid}
            onClick={handleValidateAccount}
          >
            Activar
          </button>
          <div id="resend-code" className="flex flex-col mb-4 mt-4">
            <button
              className={`${isLoading || !isEmailValid ? "bg-gray-200 cursor-not-allowed" : "bg-secondary "} text-primary p-2`}
              disabled={isLoading || !isEmailValid}
              onClick={handleResendValidationCode}
            >
              Reenviar código
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
