import React, { useCallback, useEffect, useState } from "react";
import { getAllGuestEvents } from "../../api/getAllGuestEvents";
import { Layout } from "../../components/Layout";
import { EventData } from "../../types/EventDataTypes";
import { GetAllEventsPayload } from "../../types/GetAllEventsTypes";
import EventCard from "../../components/EventCard";
import { useToast } from "../../context/ToastContext";

const Home: React.FC = () => {
  const [events, setEvents] = useState<EventData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] =
    useState<string>(searchTerm);
  const { showToast } = useToast();

  const fetchEvents = useCallback(async () => {
    try {
      setIsSearching(true);
      const payload: GetAllEventsPayload = {
        isStockAvailable: true,
        name: debouncedSearchTerm.trim(),
        sort: "eventDate,asc"
      };
      const eventsResponse = await getAllGuestEvents(payload);
      setEvents(eventsResponse.data.content);
    } catch (error) {
      showToast("Error cargando eventos", "error");
    } finally {
      setIsSearching(false);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    fetchEvents();
  }, [debouncedSearchTerm, fetchEvents]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  return (
    <Layout>
      <div id="home-page" className="w-full max-w-[1500px] m-auto">
        <div id="favorites-title-and-description">
          <div className="flex justify-center">
            <input
              id="search"
              type="text"
              placeholder="Buscar evento"
              className="w-full md:w-[350px] p-2 lora-bold "
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div
          id="favorite-events"
          className="flex flex-row flex-wrap m-auto justify-center mt-4"
        >
          {!isSearching && events.length > 0
            ? events.map((event) => <EventCard key={event.id} event={event} />)
            : null}

          {!isSearching && events.length === 0 ? (
            <div>
              <p className="text-primary text-lg lora-bold">
                No se encontraron eventos con el nombre ingresado
              </p>
            </div>
          ) : null}

          {isSearching ? (
            <div>
              <p className="text-primary text-lg lora-bold h-90">Cargando...</p>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default Home;
