// /user/getAllOrders

import { baseUrl } from "../constants/enviroment";
import { getCookie } from "../utils/getCookie";
import { OrderDataPayload, OrderDataResponse } from "../types/OrderDataTypes";

export const getAllUserOrders = async (
  data: OrderDataPayload,
): Promise<OrderDataResponse> => {
  const jwtFromCookie = getCookie("jwt");
  // Convertir el objeto a URLSearchParams, manejando booleanos y números
  const queryParams = new URLSearchParams(
    Object.entries(data).reduce(
      (acc, [key, value]) => {
        acc[key] = value.toString();
        return acc;
      },
      {} as Record<string, string>,
    ),
  ).toString();
  try {
    const response = await fetch(
      `${baseUrl}/user/getAllOrders?${queryParams}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtFromCookie}`,
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
