import { ReactNode } from "react";

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="w-full bg-bg-tertiary pt-4 px-4 pb-[170px] min-h-[700px]">
      <main className="flex h-full">{children}</main>
    </div>
  );
};
