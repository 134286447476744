import { baseUrl } from "../constants/enviroment";
import {
  RegisterPublishersPayload,
  RegisterPublishersResponse,
} from "../types/RegisterPublishersTypes";
import { getCookie } from "../utils/getCookie";

export const registerPublishers = async (
  data: RegisterPublishersPayload,
): Promise<RegisterPublishersResponse> => {
  try {
    const jwtFromCookie = getCookie("jwt");
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("sinpePhoneNumber", data.sinpePhoneNumber);
    formData.append("file", data.file);
    formData.append("bankInformation", data.bankInformation);
    const response = await fetch(`${baseUrl}/admin/registerPublisher`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${jwtFromCookie}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
