import { useEffect, useState } from "react";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { adminUpdateUser } from "../../api/adminUpdateUser";
import { deleteUser } from "../../api/deleteUser";
import { getAllUsers } from "../../api/getAllUsers";
import { Layout } from "../../components/Layout";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Pagination from "../../components/utils/Pagination";
import { useToast } from "../../context/ToastContext";
import { useRoute } from "../../hooks/useRoute"; // Ajusta la ruta según tu estructura
import {
  Content,
  GetAllUsersPayload,
  GetAllUsersResponse
} from "../../types/GetAllUsersTypes";
import { UpdateUserPayload } from "../../types/UpdateUserType";

const Users = () => {
  const { showToast } = useToast();
  const { params } = useRoute();

  const [users, setUsers] = useState<Content[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(params.page || "1", 10)
  );
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedUser, setEditedUser] = useState<UpdateUserPayload | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>(params.search || "");
  const [delayTerm, setDelayTerm] = useState<string>(searchTerm);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number>(0);

  const fetchUsers = async (currentPage: number, searchTerm: string) => {
    if (currentPage < 0) return; // Asegúrate de que currentPage sea válido

    const payload: GetAllUsersPayload = {
      isActive: true,
      offset: currentPage * 10,
      page: searchTerm ? 0 : currentPage - 1,
      pageNumber: currentPage,
      pageSize: 10,
      paged: true,
      size: 10,
      sort: "id,asc",
      unpaged: false,
      searchTerm: searchTerm // Agrega el término de búsqueda al payload
    };

    try {
      setIsLoading(true);
      const response: GetAllUsersResponse = await getAllUsers(payload);
      setUsers(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      showToast("Ha ocurrido un error al cargar los usuarios", "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchTerm !== "") {
      setCurrentPage(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchTerm(delayTerm);
    }, 500); // 500ms de retraso

    return () => {
      clearTimeout(handler);
    };
  }, [delayTerm]);

  useEffect(() => {
    fetchUsers(currentPage, searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchTerm, showToast]);

  const handleEditUser = (user: Content) => {
    setIsEditing(true);
    const { id, username, phoneNumber } = user;
    setEditedUser({ id, username, phoneNumber });
  };

  const handleDeleteUser = async (userid: number) => {
    try {
      setIsLoading(true);
      await deleteUser(userid.toString());

      // Volvemos a cargar los usuarios para asegurar que la tabla siga mostrando la misma cantidad
      await fetchUsers(currentPage, searchTerm);

      showToast("Usuario eliminado correctamente", "success");
      setOpenConfirmation(false);
    } catch (error) {
      showToast("Ha ocurrido un error al eliminar el usuario", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveChanges = async () => {
    if (!editedUser) return;

    try {
      setIsLoading(true);
      await adminUpdateUser(editedUser);

      const updatedUsers: Content[] = users.map((u) =>
        u.id === editedUser.id ? { ...u, ...editedUser } : u
      );
      setUsers(updatedUsers);

      setIsEditing(false);
      setEditedUser(null);
      setIsLoading(false);
      showToast("Usuario actualizado correctamente", "success");
    } catch (error) {
      showToast("Ha ocurrido un error al actualizar el usuario", "error");
    }
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    let phoneValue = event.target.value.replace(/\D/g, "");
    if (phoneValue.length > 8) {
      phoneValue = phoneValue.slice(0, 8);
    }
    setEditedUser({
      ...editedUser,
      id: editedUser?.id || 0,
      phoneNumber: phoneValue
    });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedUser(null);
  };

  return (
    <Layout>
      {isEditing && editedUser && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-primary w-[400px] p-4">
            <h1 className="text-secondary text-center pb-4">Editar Usuario</h1>
            <div className="flex flex-col gap-2">
              <input
                type="text"
                placeholder="Nombre de usuario"
                className="input py-1 px-2"
                disabled={isLoading}
                value={editedUser.username}
                onChange={(e) =>
                  setEditedUser({
                    ...editedUser,
                    username: e.target.value
                  })
                }
              />
              <input
                className="input py-1 px-2"
                type="text"
                placeholder="Teléfono"
                disabled={isLoading}
                value={editedUser.phoneNumber}
                onChange={handleChangePhone}
              />
              <div className="flex flex-row gap-2 w-full">
                <button
                  className="bg-secondary w-1/2 py-1"
                  onClick={handleSaveChanges}
                >
                  {isLoading ? "Guardando..." : "Guardar"}
                </button>
                <button
                  className="bg-red-500/80 w-1/2 py-1"
                  onClick={handleCancelEdit}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex-col w-full p-2 h-full">
        <h1 className="text-secondary bg-primary inline-block px-4 py-2 h-full">
          Lista de Usuarios
        </h1>
        <div className="flex justify-center mt-4">
          <input
            id="search"
            type="text"
            placeholder="Buscar usuario"
            className="w-[350px] p-2 lora-bold"
            value={delayTerm}
            onChange={(e) => setDelayTerm(e.target.value)}
          />
        </div>
        <div className="w-full h-full my-8 min-h-[520px]">
          <div className="hidden sm:flex flex-row py-2 font-bold text-md px-4">
            <div className="w-[30%] text-left">Nombre de usuario</div>
            <div className="w-[20%] text-left">Rol</div>
            <div className="w-[20%] text-left">Teléfono</div>
            <div className="w-[20%] text-left">Email</div>
            <div className="w-[5%]"></div>
            <div className="w-[5%]"></div>
          </div>
          <div className="flex flex-col w-full h-full gap-2">
            {users.length
              ? users.map((user) => (
                  <div
                    key={user.id}
                    className="flex flex-col sm:flex-row w-full h-full py-2 px-4 bg-primary text-secondary shadow-md shadow-black items-center"
                  >
                    <div className="sm:w-[30%] w-full flex gap-1">
                      <span className="sm:hidden font-bold">
                        Nombre de usuario:
                      </span>
                      {user.username}
                    </div>
                    <div className="sm:w-[20%] w-full flex gap-1">
                      <span className="sm:hidden font-bold">Rol:</span>
                      {user.role}
                    </div>
                    <div className="sm:w-[20%] w-full flex gap-1">
                      <span className="sm:hidden font-bold">Teléfono:</span>
                      {user.phoneNumber || "N/A"}
                    </div>
                    <div className="sm:w-[20%] w-full flex gap-1">
                      <span className="sm:hidden font-bold">Email:</span>
                      {user.email || "N/A"}
                    </div>
                    <div className="sm:w-[5%] w-full h-full flex justify-end">
                      <FaRegEdit
                        className="cursor-pointer"
                        onClick={() => handleEditUser(user)}
                      />
                    </div>
                    <div className="sm:w-[5%] w-full h-full flex justify-end">
                      <FaTrash
                        className="cursor-pointer"
                        onClick={() => {
                          setSelectedUserId(user.id); // Guardamos el ID del usuario seleccionado
                          setOpenConfirmation(true); // Abrimos el modal
                        }}
                      />
                    </div>
                  </div>
                ))
              : null}
            <ConfirmationModal
              isOpen={openConfirmation}
              onClose={() => setOpenConfirmation(false)}
              onConfirm={() => handleDeleteUser(selectedUserId)} // Usamos el ID del usuario seleccionado
              header="Eliminar usuario"
              body="¿Estás seguro que deseas eliminar este usuario?"
              confirmText="Eliminar"
            />
          </div>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
    </Layout>
  );
};

export default Users;
