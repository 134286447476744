import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useState } from "react";

interface TiptapEditorProps {
  content?: string;
  setContent: (content: string) => void;
}
// Define your extension array
const extensions = [StarterKit];

const TiptapEditor = ({ content, setContent }: TiptapEditorProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const editor = useEditor({
    extensions,
    content,
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    onUpdate: ({ editor }) => {
      const jsonContent = editor.getHTML();
      setContent(jsonContent); // Auto-save on every content change
    }
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    editor?.chain().focus().toggleBold().run();
  };

  if (!editor) {
    return null;
  }

  return (
    <div className="h-full ring-2 p-2 ring-yellow-500">
      <div className="flex flex-wrap gap-2 mb-4">
        <button
          type="button"
          onClick={handleClick}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={`bg-primary hover:bg-opacity-80 text-white font-bold py-2 px-4  ${editor.isActive("bold") ? "ring-2 ring-yellow-500" : ""}`}
        >
          Negrita
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={`bg-primary hover:bg-opacity-80 text-white font-bold py-2 px-4  ${editor.isActive("italic") ? "ring-2 ring-yellow-500" : ""}`}
        >
          Cursiva
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().setHardBreak().run()}
          className={`${editor.isActive("blockquote") ? "ring-2 ring-yellow-500" : ""} hover:bg-opacity-80 text-white font-bold py-2 px-4 `}
        >
          Salto de linea
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          className="bg-secondary hover:bg-opacity-80 text-black font-bold py-2 px-4 "
        >
          Deshacer
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          className="bg-secondary hover:bg-opacity-80 text-black font-bold py-2 px-4 "
        >
          Rehacer
        </button>
      </div>
      <div>
        <EditorContent
          editor={editor}
          className="text-black ring-2 p-2 ring-tertiary/50 mb-4 bg-white"
        />
      </div>
    </div>
  );
};

export default TiptapEditor;
