import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { registerEventTier } from "../../api/registerEvent";
import { useToast } from "../../context/ToastContext";
import { EventData } from "../../types/EventDataTypes";

interface EditTiersModalProps {
  show: boolean;
  onHide: () => void;
  event: EventData | null;
  refresh?: () => void;
}

const AddTiersModal = ({
  show,
  onHide,
  event,
  refresh
}: EditTiersModalProps) => {
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tierName, setTierName] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [maxQuantity, setMaxQuantity] = useState<number>(0);

  const handleChange = (setState: (value: number) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = Number(e.target.value);
      if (!isNaN(value) && value > 0) {
        setState(value);
      } else {
        setState(0);
      }
    };
  };

  const handleChangeName = (setState: (value: string) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState(e.target.value);
    };
  };
  const handleRegisterTier = () => {
    setIsLoading(true);
    registerEventTier({
      eventId: event?.id as number,
      name: tierName,
      price: price as number,
      maxQuantity: maxQuantity as number,
      commission: 0
    })
      .then(() => {
        setIsLoading(false);
        showToast("Tier registrado correctamente", "success");
        refresh && refresh();
        onHide();
      })
      .catch((error) => {
        setIsLoading(false);
        showToast(error.data[0], "error");
      });
  };

  const isValidForm = tierName !== "" && price !== 0 && maxQuantity !== 0;

  useEffect(() => {
    if (show) {
      const scrollBarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.paddingRight = `${scrollBarWidth}px`;
    }

    // Cleanup function to reset styles if the component unmounts
    return () => {
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.width = "";
      document.body.style.paddingRight = "";
    };
  }, [show]);

  return (
    <>
      {show && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75"
          onClick={onHide}
        >
          <div
            className="bg-primary w-full md:w-[500px] p-4 mx-4  relative max-h-[80vh] overflow-y-auto modal-content"
            onClick={(e) => e.stopPropagation()} // Previene el cierre al hacer clic dentro del modal
          >
            <h1 className="text-secondary text-center text-xl">
              Agregar localidades
            </h1>
            <button
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 z-50"
              onClick={onHide}
            >
              <MdClose size={24} />
            </button>

            <div id="register-form" className="pt-4">
              <form className="flex flex-col">
                <label className="text-lg mb-1 text-tertiary">
                  Nombre del tier *
                </label>
                <input
                  className="border  mb-2 p-2 text-primary"
                  type="text"
                  disabled={isLoading}
                  value={tierName}
                  onChange={handleChangeName(setTierName)}
                />
                <label className="text-lg mb-1 text-tertiary">Precio *</label>
                <input
                  className="border  mb-2 p-2 text-primary"
                  type="number"
                  disabled={isLoading}
                  value={price > 0 ? price : ""}
                  onChange={handleChange(setPrice)}
                />
                <label className="text-lg mb-1 text-tertiary">
                  Cantidad máxima *
                </label>
                <input
                  className="border  mb-2 p-2 text-primary"
                  type="number"
                  disabled={isLoading}
                  value={maxQuantity > 0 ? maxQuantity : ""}
                  onChange={handleChange(setMaxQuantity)}
                />
              </form>
            </div>
            <div id="register-form">
              <button
                className={`w-full text-lg p-2 mt-4 text-primary ${!isLoading && isValidForm ? "bg-secondary" : "bg-gray-200 cursor-not-allowed"}`}
                type="button"
                disabled={isLoading}
                onClick={handleRegisterTier}
              >
                {!isLoading ? "Agregar" : "Agregando..."}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddTiersModal;
