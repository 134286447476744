// bouncer/validateTicket
import { baseUrl } from "../constants/enviroment";
import { ValidateTicketPayload } from "../types/ValidateTicket";
import { getCookie } from "../utils/getCookie";

export const validateTicket = async (
  data: ValidateTicketPayload,
): Promise<{
  status: string;
  message: string;
  status_code: number;
  data?: any;
}> => {
  try {
    const jwtFromCookie = getCookie("jwt");
    const response = await fetch(`${baseUrl}/bouncer/validateTicket`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtFromCookie}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }
    const responseData = await response.json();
    return {
      status: responseData.status,
      message: responseData.message,
      status_code: response.status,
      data: responseData.data,
    };
  } catch (error: any) {
    throw error;
  }
};

export default validateTicket;
