import { baseUrl } from "../constants/enviroment";

export const resendActivationCode = async (payload: {
  email: string;
}): Promise<{
  data: string;
  message: string;
  status: string;
  status_code: number;
}> => {
  try {
    const response = await fetch(
      `${baseUrl}/authentication/resendActivationCode`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return await response.json();
  } catch (error: any) {
    // throw error;
    return error;
  }
};
