import { baseUrl } from "../constants/enviroment";
import {
  UpdateEventPayload,
  UpdateEventResponse,
} from "../types/UpdateEventTypes";
import { getCookie } from "../utils/getCookie";

export const updatePublisherEvents = async (
  data: UpdateEventPayload,
): Promise<UpdateEventResponse> => {
  const jwtFromCookie = getCookie("jwt");
  const formData = new FormData();
  formData.append("id", data.id.toString());
  formData.append("name", data.name?.toString() || "");
  formData.append("description", data.description?.toString() || "");
  formData.append("startDate", data.startDate?.toString() || "");
  formData.append("endDate", data.endDate?.toString() || "");
  formData.append("eventDate", data.eventDate?.toString() || "");
  formData.append("location", data.location?.toString() || "");
  formData.append("isActive", data.isActive?.toString() || "");
  formData.append("isPaid", data.isPaid?.toString() || "");
  formData.append("file", data.file || "");

  try {
    const response = await fetch(`${baseUrl}/publisherAdmin/updateEvent`, {
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${jwtFromCookie}`,
        Accept: "*/*",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
