import { useRoute } from "../hooks/useRoute";
import { removeSearchParams } from "../utils/removeURLSearchParams";
import { Link } from "./router/Link";

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { route } = useRoute();
  const routeWithoutParams = removeSearchParams(route);

  if (routeWithoutParams === "/event/") {
    return <></>;
  }

  return (
    <footer className="bg-primary text-tertiary p-4 md:fixed bottom-0 w-full lora-regular">
      <div className="flex flex-col md:flex-row justify-center md:justify-between">
        <div>
          <p className="text-center md:text-left">
            Digiticket<sup>®</sup> StoreHQ<sup>®</sup>
          </p>
          <p className="text-center md:text-left">
            Todos los derechos reservados {currentYear}
          </p>
        </div>
        <div>
          <p className="text-center md:text-right">
            <Link to="/terms-and-conditions">
              <span className="underline">Términos & Condiciones</span>
            </Link>
          </p>
          <p className="text-center md:text-right">
            <Link to="/contact-us">
              <span className="underline">Contactenos</span>
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};
