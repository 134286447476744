import React, { useState } from "react";
// import facebookIcon from "../../assets/facebookIcon.svg";
// import googleIcon from "../../assets/googleIcon.svg";
import { registerUser } from "../../api/registerUser";
import { resendActivationCode } from "../../api/resendActivationCode";
import { validateActivationCode } from "../../api/validateActivationCode";
import { Layout } from "../../components/Layout";
import { Link } from "../../components/router/Link";
import { appName } from "../../constants/enviroment";
import { useAppContext } from "../../hooks/useAppContext";
import { RegisterUserPayload } from "../../types/RegisterUserTypes";
import { isValidEmail } from "../../utils/isValidEmail";

export const Register = () => {
  const { login } = useAppContext();
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isValidationCodeValid, setIsValidationCodeValid] = useState(false);
  const [activationError, setActivationError] = useState("");
  const [code, setCode] = useState("");
  const [registrationError, setRegistrationError] = useState<string | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showValidationForm, setShowValidationForm] = useState(false);
  const [isReadTerms, setIsReadTerms] = useState(false);

  const register = async (payload: RegisterUserPayload) => {
    setIsLoading(true);
    try {
      const registerUserRequest = await registerUser(payload);
      if (registerUserRequest.status_code !== 200) {
        setRegistrationError(registerUserRequest.message);
        return;
      }
      setShowValidationForm(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setRegistrationError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const validationCodeValue = event.target.value;
    setCode(validationCodeValue);
    setIsValidationCodeValid(
      validationCodeValue.length > 0 && validationCodeValue.length < 5
    );
  };

  const handleValidateAccount = async () => {
    // Activate account
    try {
      setIsLoading(true);
      const validation = await validateActivationCode({
        email,
        code: code
      });

      if (validation.status_code !== 200) {
        setActivationError(validation.message);
        setIsLoading(false);
        return;
      } else {
        login({ email, password }, `/`);
      }
    } catch (error) {
      setActivationError(
        "Ha ocurrido un error al activar tu cuenta, intentalo mas tarde"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendValidationCode = async () => {
    try {
      setIsLoading(true);
      const validation = await resendActivationCode({
        email
      });

      if (validation.status_code !== 200) {
        // show error in toast
        setActivationError(
          "No se ha conseguido enviar el codigo de validacion, intentalo mas tarde"
        );
        setIsLoading(false);
        return;
      } else {
        // show success in toast
        setActivationError(
          "Se ha enviado un nuevo codigo de validacion a tu correo"
        );
      }
    } catch (error) {
      setActivationError("Ha ocurrido un error enviando el nuevo codigo");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameValue = event.target.value;
    setUserName(nameValue);
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phoneValue = event.target.value;
    setPhoneNumber(phoneValue);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setIsEmailValid(isValidEmail(emailValue));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);
    setIsPasswordValid(passwordValue === passwordConfirmation);
  };

  const handlePasswordConfirmationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const passwordConfirmationValue = event.target.value;
    setPasswordConfirmation(passwordConfirmationValue);
    setIsPasswordValid(passwordConfirmationValue === password);
  };

  const handleRegister = () => {
    register({
      email,
      password,
      phoneNumber,
      username
    });
  };

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === "Enter") {
      handleRegister();
    }
  };

  const handleAcceptTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsReadTerms(true);
  };

  const isFormValid = () => {
    return isEmailValid && isPasswordValid && isReadTerms;
  };

  return (
    <Layout>
      {!showValidationForm ? (
        <div
          id="register-module"
          className="flex flex-col bg-primary w-full md:w-[450px] p-2 md:m-auto"
        >
          <div id="register-headers" className="mb-4">
            <h1 className="text-center text-bold text-xl text-tertiary">
              Bienvenido a <span className="text-secondary">{appName}</span>
            </h1>
          </div>
          <div id="register-form">
            <form className="flex flex-col">
              <label className="text-lg mb-1 text-tertiary">Nombre *</label>
              <input
                className="border ronded-2 mb-2 p-2 text-primary"
                type="text"
                disabled={isLoading}
                value={username}
                onChange={handleChangeName}
              />
              <label className="text-lg mb-1 text-tertiary">Email *</label>
              <input
                className="border ronded-2 mb-2 p-2 text-primary"
                type="text"
                disabled={isLoading}
                value={email}
                onChange={handleEmailChange}
              />
              <label className="text-lg mb-1 text-tertiary">Telefono *</label>
              <input
                className="border ronded-2 mb-2 p-2 text-primary"
                type="text"
                disabled={isLoading}
                value={phoneNumber}
                onChange={handleChangePhone}
              />
              <label className="text-lg mb-1 text-tertiary">Password *</label>
              <input
                className="border ronded text-lg mb-2 p-2 text-primary"
                type="password"
                disabled={isLoading}
                value={password}
                onChange={handlePasswordChange}
                onKeyDown={handleKeyDown}
              />
              <label className="text-lg mb-1 text-tertiary">
                Confirmar Password *
              </label>
              <input
                className="border ronded text-lg mb-2 p-2 text-primary"
                type="password"
                disabled={isLoading}
                value={passwordConfirmation}
                onChange={handlePasswordConfirmationChange}
                onKeyDown={handleKeyDown}
              />
            </form>

            {/** Has read terms and conditions */}
            <div className="flex flex-row items-center">
              <input
                type="checkbox"
                checked={isReadTerms}
                className="mr-2 h-4 w-4"
                onChange={(e) => handleAcceptTerms(e)}
              />
              <p className="text-tertiary">
                He leido y acepto los{" "}
                <Link to="/terms-and-conditions">
                  <span className="text-secondary cursor-pointer">
                    terminos y condiciones
                  </span>
                </Link>
              </p>
            </div>

            <button
              className={`w-full uppercase text-lg p-2 mt-4 text-primary ${!isFormValid() ? "bg-gray-200 cursor-not-allowed" : "bg-secondary "}`}
              type="button"
              onClick={handleRegister}
              disabled={!isFormValid()}
            >
              {isLoading ? "Registrando usuario..." : "Registrarse"}
            </button>
            <Link to={`/login`}>
              <p className="uppercase text-lg mt-4 text-primary bg-secondary text-center p-2">
                Ingresar
              </p>
            </Link>
            {registrationError ? (
              <div className="h-5">
                <p className="text-red-500 text-center">{registrationError}</p>
                <p className="text-tertiary text-center">
                  Desea{" "}
                  <span className="text-secondary cursor-pointer">
                    recuperar su contrasenha
                  </span>{" "}
                  o{" "}
                  <span className="text-secondary cursor-pointer">
                    validar su cuenta ya registrada
                  </span>
                </p>
              </div>
            ) : null}
          </div>
          {/* <div id="register-social-media" className="mt-4">
            <h3 className="text-lg text-tertiary text-center">
              Ingreso con Redes Sociales
            </h3>
            <div className="flex flex-row justify-center mt-2">
              <img src={googleIcon} alt="google icon" className="w-8 h-8 m-2" />
              <img
                src={facebookIcon}
                alt="facebook icon"
                className="w-8 h-8 m-2"
              />
            </div>
          </div> */}
        </div>
      ) : null}

      {showValidationForm ? (
        <div
          id="account-validation-module"
          className="flex flex-col bg-primary  w-full md:w-[450px] p-2 md:m-auto"
        >
          <div id="activate-account" className="mb-4">
            <h1 className="lora-bold text-center text-bold text-xl text-tertiary">
              Activa tu cuenta
            </h1>
            <h2 className="lora-regular text-center text-tertiary">
              Se ha enviado un codigo de cuatro digitos a tu correo{" "}
              <span className="lora-bold text-secondary">{email}</span>
            </h2>
          </div>
          <div id="validate-account-form-fields">
            <form className="flex flex-col">
              <label className="lora-bold text-lg mb-1 text-tertiary">
                Código de validación *
              </label>
              <input
                className="lora-bold border ronded-2 mb-2 p-2 text-primary"
                type="text"
                disabled={isLoading}
                value={code}
                onChange={handleValidationCodeChange}
              />
            </form>
          </div>
          <div id="activate-account-button" className="flex flex-col mb-4 mt-4">
            <button
              className="bg-secondary text-primary p-2  "
              disabled={isLoading || !isEmailValid || !isValidationCodeValid}
              onClick={() => handleValidateAccount()}
            >
              Activar
            </button>

            <button
              className="bg-secondary text-primary p-2 mt-2"
              // disabled={isLoading || !isEmailValid || !isValidationCodeValid}
              onClick={() => handleResendValidationCode()}
            >
              Volver a enviar codigo
            </button>

            {activationError ? (
              <p className="text-red-500">{activationError}</p>
            ) : null}
          </div>
        </div>
      ) : null}
    </Layout>
  );
};
