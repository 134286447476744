import React from "react";
import { useAppContext } from "../../hooks/useAppContext";
import { useRoute } from "../../hooks/useRoute";
import Users from "../../routes/adminModule//Users";
import { Publishers } from "../../routes/adminModule/Publishers";
import RegisterAdmin from "../../routes/adminModule/RegisterAdmin";
import RegisterPublishers from "../../routes/adminModule/RegisterPublishers";
import { NotFound } from "../../routes/NotFound";
import EventPublisherDetails from "../../routes/publisherAdminModule/EventPublisherDetails";
import PublisherEvents from "../../routes/publisherAdminModule/PublisherEvents";
import PublisherOrders from "../../routes/publisherAdminModule/PublisherOrders";
import PublishersApproval from "../../routes/publisherAdminModule/PublishersApproval";
import RegisterEvent from "../../routes/publisherAdminModule/RegisterEvent";
import RegisterPublisherApproval from "../../routes/publisherAdminModule/RegisterPublisherApproval";
import RegisterPublisherBouncer from "../../routes/publisherAdminModule/RegisterPublisherBouncer";
import { OrdersPendingApproval } from "../../routes/publisherApproverModule/OrdersPendingApproval";
import { QRScanAndRedeem } from "../../routes/publisherBouncer/QRScanAndRedeem";
import { TermsAndConditions } from "../../routes/TermsAndConditions";
import { EditProfile } from "../../routes/usersModule/EditProfile";
import { Event } from "../../routes/usersModule/Event";
import { ForgotPassword } from "../../routes/usersModule/ForgotPassword";
import ContactUs from "../../routes/ContactUs";
import Home from "../../routes/usersModule/Home";
import { Login } from "../../routes/usersModule/Login";
import { Logout } from "../../routes/usersModule/Logout";
import { Order } from "../../routes/usersModule/Order";
import { Orders } from "../../routes/usersModule/Orders";
import { OrderTickets } from "../../routes/usersModule/OrderTickets";
import { Register } from "../../routes/usersModule/Register";
import { ValidateAccount } from "../../routes/usersModule/ValidateActivationCode";
import { removeSearchParams } from "../../utils/removeURLSearchParams";

const ADMIN = "ROLE_ADMIN";
const ROLE_PUBLISHER_ADMIN = "ROLE_PUBLISHER_ADMIN";
const ROLE_PUBLISHER_APPROVAL = "ROLE_PUBLISHER_APPROVAL";
const ROLE_PUBLISHER_BOUNCER = "ROLE_PUBLISHER_BOUNCER";
const routes = [
  "",
  "/",
  "/orders",
  "/order-tickets/",
  "/profile",
  "/event/",
  "/event-publisher-details/",
  "/order/",
  "/login",
  "/register",
  "/validate-account",
  "/validate-account/",
  "/forgot-password",
  "/publishers",
  "/register-publishers",
  "/register-publishers-approver",
  "/register-publishers-bouncer",
  "/register-user",
  "/users",
  "/register-event",
  "/publisher-events",
  "/publishers-approval",
  "/publisher-orders",
  "/publisher-orders-pending-approval",
  "/terms-and-conditions",
  "/qr-ticket-scan",
  "/contact-us"
];

export const Router: React.FC = () => {
  const { route } = useRoute();
  const { isAuthenticated, user } = useAppContext();
  const routeWithoutParams = removeSearchParams(route);
  const isUserValidated = user?.isAccountValidated;

  if (!routes.includes(routeWithoutParams)) {
    return <NotFound />;
  }

  if (isAuthenticated && !isUserValidated) {
    if (routeWithoutParams === "/login") {
      return <Logout />;
    }
    return <ValidateAccount />;
  }

  if (!isAuthenticated) {
    switch (routeWithoutParams) {
      case "/":
      case "":
        return <Home />;
      case "/login":
        return <Login />;
      case "/register":
        return <Register />;
      case "/validate-account":
      case "/validate-account/":
        return <ValidateAccount />;
      case "/forgot-password":
        return <ForgotPassword />;
      case "/event/":
        return <Event />;
      case "/terms-and-conditions":
        return <TermsAndConditions />;
      case "/contact-us":
        return <ContactUs />;
      default:
        return <NotFound />;
    }
  }

  if (isAuthenticated && user?.role === ADMIN) {
    switch (routeWithoutParams) {
      case "/publishers":
        return <Publishers />;
      case "/order/":
        return <Order />;
      case "/order-tickets/":
        return <OrderTickets />;
      case "/register-publishers":
        return <RegisterPublishers />;
      case "/register-publishers-approver":
        return <RegisterPublisherApproval />;
      case "/register-publishers-bouncer":
        return <RegisterPublisherBouncer />;
      case "/publisher-events":
        return <PublisherEvents />;
      case "/event-publisher-details/":
        return <EventPublisherDetails />;
      case "/publisher-orders":
        return <PublisherOrders />;
      case "/register-user":
        return <RegisterAdmin />;
      case "/publisher-orders-pending-approval":
        return <OrdersPendingApproval />;
      case "/terms-and-conditions":
        return <TermsAndConditions />;
      case "/contact-us":
        return <ContactUs />;
      case "/users":
        return <Users />;
      case "/event/":
        return <Event />;
      case "/orders":
        return <Orders />;
      case "/forgot-password":
        return <ForgotPassword />;
      case "/register-event":
        return <RegisterEvent />;
      case "/profile":
        return <EditProfile />;
      case "/":
      case "":
        return <Home />;
      case "/login":
        return <Logout />;
      case "/validate-account":
      case "/validate-account/":
        return <ValidateAccount />;
      case "/qr-ticket-scan":
        return <QRScanAndRedeem />;
      default:
        return <NotFound />;
    }
  }

  if (isAuthenticated && user?.role === ROLE_PUBLISHER_ADMIN) {
    switch (routeWithoutParams) {
      case "/register-event":
        return <RegisterEvent />;
      case "/register-publishers-approver":
        return <RegisterPublisherApproval />;
      case "/register-publishers-bouncer":
        return <RegisterPublisherBouncer />;
      case "/order/":
        return <Order />;
      case "/users":
        return <PublishersApproval />;
      case "/terms-and-conditions":
        return <TermsAndConditions />;
      case "/contact-us":
        return <ContactUs />;
      case "/publisher-events":
        return <PublisherEvents />;
      case "/event-publisher-details/":
        return <EventPublisherDetails />;
      case "/publisher-orders":
        return <PublisherOrders />;
      case "/publisher-orders-pending-approval":
        return <OrdersPendingApproval />;
      case "/profile":
        return <EditProfile />;
      case "/forgot-password":
        return <ForgotPassword />;
      case "/orders":
        return <Orders />;
      case "/event/":
        return <Event />;
      case "/":
      case "":
        return <Home />;
      case "/order-tickets/":
        return <OrderTickets />;
      case "/login":
        return <Logout />;
      case "/validate-account":
      case "/validate-account/":
        return <ValidateAccount />;
      case "/qr-ticket-scan":
        return <QRScanAndRedeem />;
      default:
        return <NotFound />;
    }
  }

  if (isAuthenticated && user?.role === ROLE_PUBLISHER_APPROVAL) {
    switch (routeWithoutParams) {
      case "/order/":
        return <Order />;
      case "/event/":
        return <Event />;
      case "/profile":
        return <EditProfile />;
      case "/orders":
        return <Orders />;
      case "/order-tickets/":
        return <OrderTickets />;
      case "/publisher-orders-pending-approval":
        return <OrdersPendingApproval />;
      case "/terms-and-conditions":
        return <TermsAndConditions />;
      case "/contact-us":
        return <ContactUs />;
      case "/forgot-password":
        return <ForgotPassword />;
      case "/":
      case "":
        return <Home />;
      case "/login":
        return <Logout />;
      case "/validate-account":
      case "/validate-account/":
        return <ValidateAccount />;
      case "/qr-ticket-scan":
        return <QRScanAndRedeem />;
      default:
        return <NotFound />;
    }
  }

  if (isAuthenticated && user?.role === ROLE_PUBLISHER_BOUNCER) {
    switch (routeWithoutParams) {
      case "/order/":
        return <Order />;
      case "/event/":
        return <Event />;
      case "/profile":
        return <EditProfile />;
      case "/orders":
        return <Orders />;
      case "/order-tickets/":
        return <OrderTickets />;
      case "/terms-and-conditions":
        return <TermsAndConditions />;
      case "/contact-us":
        return <ContactUs />;
      case "/forgot-password":
        return <ForgotPassword />;
      case "/":
      case "":
        return <Home />;
      case "/login":
        return <Logout />;
      case "/validate-account":
      case "/validate-account/":
        return <ValidateAccount />;
      case "/qr-ticket-scan":
        return <QRScanAndRedeem />;
      default:
        return <NotFound />;
    }
  }

  switch (routeWithoutParams) {
    case "/":
    case "":
      return <Home />;
    case "/login":
      return <Logout />;
    case "/register":
      return <Logout />;
    case "/validate-account":
    case "/validate-account/":
      return <ValidateAccount />;
    case "/order/":
      return <Order />;
    case "/event/":
      return <Event />;
    case "/profile":
      return <EditProfile />;
    case "/orders":
      return <Orders />;
    case "/order-tickets/":
      return <OrderTickets />;
    case "/terms-and-conditions":
      return <TermsAndConditions />;
    case "/forgot-password":
      return <ForgotPassword />;
    case "/contact-us":
      return <ContactUs />;
    default:
      return <NotFound />;
  }
};
