import { useEffect } from "react";
import { useRoute } from "../hooks/useRoute";
import { Layout } from "../components/Layout";

export const NotFound = () => {
  // redirect the user to the home page after 5 seconds
  const { setRoute } = useRoute();

  useEffect(() => {
    const timer = setTimeout(() => {
      setRoute("/");
    }, 5000);

    // Cleanup function to clear the timeout if the user changes the route before the 5 seconds are up
    return () => clearTimeout(timer);
  }, [setRoute]);

  return (
    <Layout>
      <div className="flex flex-col">
        <h1 className="lora-regular text-4xl text-center">404</h1>
        <p className="lora-regular ">No se encontro esta pagina</p>
      </div>
    </Layout>
  );
};
