const milisecondsInDay = 86400000;

interface SetCookieProps {
  name: string;
  value: string;
  days: number;
  path?: string;
  secure?: boolean;
  sameSite?: "Lax" | "Strict" | "None";
}

export const setCookie = (props: SetCookieProps): void => {
  const date = new Date();
  date.setTime(date.getTime() + props.days * milisecondsInDay);
  const expires = `expires=${date.toUTCString()}`;
  const formattedValue = encodeURIComponent(props.value);

  let cookieString = `${encodeURIComponent(props.name)}=${formattedValue}; ${expires}; path=${props.path || "/"}`;

  if (props.secure) {
    cookieString += "; Secure";
  }

  if (props.sameSite) {
    cookieString += `; SameSite=${props.sameSite}`;
  }

  document.cookie = cookieString;
};
