import { useEffect, useState } from "react";
import { useAppContext } from "../../hooks/useAppContext";
import { Link } from "../../components/router/Link";
import { UserData } from "../../types/UserDataTypes";
import { updateUserInformation } from "../../api/updateUserInformation";
import { useToast } from "../../context/ToastContext";
import { Layout } from "../../components/Layout";

export const EditProfile: React.FC = () => {
  const { user, updateUserProfile } = useAppContext();
  const { showToast } = useToast();
  const [editedUser, setEditedUser] = useState<UserData | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const saveProfileChanges = async () => {
    try {
      const response = await updateUserInformation({
        phoneNumber: editedUser?.phoneNumber || "",
        username: editedUser?.username || ""
      });
      updateUserProfile(response);
      showToast(response.message);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showToast(error.message, "error");
    } finally {
      setEditedUser(null);
    }
  };

  const toggleEdit = () => {
    console.log("editedUser", editedUser);
    if (editedUser) {
      setEditedUser(null);
    } else {
      setEditedUser(user);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!editedUser) {
      console.log("No edited user");
      return;
    }

    setEditedUser({
      ...editedUser,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (isSaving) {
      saveProfileChanges();
      setIsSaving(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  console.log("editedUser", editedUser);

  return (
    <Layout>
      <div id="edit-user-profile" className="w-[300px] m-auto p-4 lora-regular">
        <h2 className="mb-4">
          <span className="lora-bold text-xl">Perfil de usuario</span>
        </h2>
        {editedUser ? (
          <div className="lora-regular">
            <div className="h-[300px] mb-4">
              <div className="">
                <label htmlFor="username-input" className="mb-4">
                  Nombre a mostrar
                </label>
                <input
                  type="text"
                  id="username-input"
                  name="username"
                  className="p-2 mb-4 text-primary w-full"
                  value={editedUser?.username}
                  onChange={handleChange}
                />
              </div>
              <div className="">
                <label htmlFor="phone-number-input" className="mb-4">
                  Telefono (8 Digitos)
                </label>
                <input
                  type="text"
                  id="phone-number-input"
                  name="phoneNumber"
                  className="p-2 text-primary w-full mb-4"
                  value={editedUser?.phoneNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-2 items-center justify-center">
              <button
                className="lora-regular bg-secondary p-2 shadow-md w-full md:w-[140px] disabled:opacity-50"
                disabled={isSaving}
                onClick={() => setIsSaving(true)}
              >
                Guardar
              </button>
              <button
                className="lora-regular text-tertiary bg-primary p-2 shadow-md w-full md:w-[140px] disabled:opacity-50"
                disabled={isSaving}
                onClick={() => toggleEdit()}
              >
                Cancelar
              </button>
            </div>
          </div>
        ) : (
          <div className="lora-regular">
            <div className="h-[300px] mb-4">
              <p className="lora-regular mb-2">
                Nombre a mostrar:{" "}
                <span className="lora-bold uppercase">{user?.username}</span>
              </p>
              <p className="lora-regular mb-2">
                Nombre: <span className="lora-bold">{user?.firstName}</span>
              </p>
              <p className="lora-regular mb-2">
                Apellidos: <span className="lora-bold">{user?.lastName}</span>
              </p>
              <p className="lora-regular mb-2">
                Correo: <span className="lora-bold">{user?.email}</span>
              </p>
              <p className="lora-regular">
                Telefono: <span className="lora-bold">{user?.phoneNumber}</span>
              </p>
            </div>
            <div className="flex flex-col md:flex-row gap-2 items-center justify-center">
              <button
                className="uppercase bg-secondary p-2 shadow-md w-full md:w-[140px]"
                onClick={() => toggleEdit()}
              >
                Editar
              </button>

              <button className="text-tertiary uppercase bg-primary p-2 shadow-md w-full md:w-[140px]">
                <Link to={"/login"}>Cerrar sesión</Link>
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
