import { useCallback, useEffect, useState } from "react";
import { deleteEvent } from "../../api/deleteEvent";
import { getAllPublisherEvents } from "../../api/getAllPublisherEvents";
import EventCard from "../../components/EventCard";
import { Layout } from "../../components/Layout";
import AddTiersModal from "../../components/modals/AddTiersModal";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import EditEventsModal from "../../components/modals/EditEventsModal";
import EditTiersModal from "../../components/modals/EditTiersModal";
import Pagination from "../../components/utils/Pagination";
import { useToast } from "../../context/ToastContext";
import { useModalManager } from "../../hooks/useModalManager";
import { useRoute } from "../../hooks/useRoute";
import { EventData } from "../../types/EventDataTypes";
import {
  GetAllEventsPayload,
  GetAllEventsResponse
} from "../../types/GetAllEventsTypes";

const PublisherEvents = () => {
  const [events, setEvents] = useState<EventData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] =
    useState<string>(searchTerm);
  const { showToast } = useToast();
  const { params } = useRoute();
  const { currentEvent, openModal, openSpecificModal, closeModal } =
    useModalManager();

  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(params.page || "1", 10)
  );
  const [totalPages, setTotalPages] = useState<number>(0);
  const [refresh, setRefresh] = useState<boolean>(false);

  const handleDeleteEvent = async (event: EventData) => {
    try {
      await deleteEvent(event.id.toString());
      showToast("Evento eliminado correctamente", "success");
      setEvents((prevEvents) => prevEvents.filter((u) => u.id !== event.id));
      closeModal();
    } catch (error: any) {
      showToast(error.message, "error");
    }
  };

  const fetchEvents = useCallback(async () => {
    const payload: GetAllEventsPayload = {
      name: searchTerm,
      offset: currentPage * 10,
      page: currentPage - 1,
      pageNumber: currentPage,
      pageSize: 10,
      paged: true,
      size: 10,
      sort: "eventDate,desc",
      unpaged: false
    };
    try {
      const response: GetAllEventsResponse =
        await getAllPublisherEvents(payload);
      setEvents(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      showToast("Ha ocurrido un error al cargar los eventos", "error");
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    fetchEvents();
  }, [debouncedSearchTerm, fetchEvents]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  return (
    <Layout>
      <div id="home-page" className="w-full max-w-[1500px] m-auto mb-7">
        <div id="favorites-title-and-description">
          <h1 className="lora-bold text-4xl text-center">Eventos</h1>
          <div className="flex justify-center mt-4">
            <input
              id="search"
              type="text"
              placeholder="Buscar evento"
              className="w-[350px] p-2 lora-bold"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col w-full h-full m-auto my-4 justify-center items-center">
          <div
            id="favorite-events"
            className="flex flex-row flex-wrap justify-center"
          >
            {events.map((event) => (
              <div key={event.id}>
                <EventCard
                  event={event}
                  onEditEvent={() => openSpecificModal("editEventModal", event)}
                  onEditTier={() => openSpecificModal("editTierModal", event)}
                  onAddTier={() => openSpecificModal("addTierModal", event)}
                  onDeleteEvent={() =>
                    openSpecificModal("confirmationModal", event)
                  }
                />
              </div>
            ))}
          </div>
          {openModal === "editEventModal" && currentEvent && (
            <EditEventsModal
              show={openModal === "editEventModal"}
              onHide={closeModal}
              event={currentEvent}
              refresh={() => setRefresh((prev) => !prev)}
            />
          )}
          {openModal === "editTierModal" && currentEvent && (
            <EditTiersModal
              show={openModal === "editTierModal"}
              onHide={closeModal}
              event={currentEvent}
              refresh={() => setRefresh((prev) => !prev)}
            />
          )}
          {openModal === "addTierModal" && currentEvent && (
            <AddTiersModal
              show={openModal === "addTierModal"}
              onHide={closeModal}
              event={currentEvent}
              refresh={() => setRefresh((prev) => !prev)}
            />
          )}
          {openModal === "confirmationModal" && currentEvent && (
            <ConfirmationModal
              isOpen={openModal === "confirmationModal"}
              onClose={closeModal}
              onConfirm={() => handleDeleteEvent(currentEvent)}
              header="Eliminar Evento"
              body="¿Estás seguro que deseas eliminar este evento?"
              confirmText="Eliminar"
            />
          )}
          <div className="flex w-full  justify-center items-center">
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PublisherEvents;
