import { baseUrl } from "../constants/enviroment";
import { RegisterEventTierResponse } from "../types/RegisterEventTypes";
import { getCookie } from "../utils/getCookie";

export const deleteEvent = async (
  id: string,
): Promise<RegisterEventTierResponse> => {
  const jwtFromCookie = getCookie("jwt");
  try {
    const response = await fetch(
      `${baseUrl}/publisherAdmin/deleteEvent/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwtFromCookie}`,
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
