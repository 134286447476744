import { useState } from "react";
import { registerPublisherBouncer } from "../../api/registerPublisherBouncer";
import { Layout } from "../../components/Layout";
import PublisherIdDropdown from "../../components/PublisherIdDropdown";
import { useToast } from "../../context/ToastContext";
import { useAppContext } from "../../hooks/useAppContext";
import { isValidEmail } from "../../utils/isValidEmail";
import { isValidPhone } from "../../utils/isValidPhone";

export const RegisterPublisherBouncer = () => {
  const { user } = useAppContext();
  const { showToast } = useToast();
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [publisherId, setPublisherId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<any>(user);

  const handleChange =
    (setter: React.Dispatch<React.SetStateAction<any>>) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setter(event.target.value);
    };
  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    let phoneValue = event.target.value.replace(/\D/g, "");
    if (phoneValue.length > 8) {
      phoneValue = phoneValue.slice(0, 8);
    }
    setPhoneNumber(phoneValue);
    setIsPhoneValid(isValidPhone(phoneValue));
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setIsEmailValid(isValidEmail(emailValue));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleRegisterApproval();
    }
  };

  const handleRegisterApproval = () => {
    setIsLoading(true);
    registerPublisherBouncer({
      username,
      email,
      phoneNumber,
      publisherId: publisherId || userData.publishers[0].id
    })
      .then((response) => {
        setIsLoading(false);
        showToast("Portero registrado correctamente", "success");
      })
      .catch((error) => {
        setIsLoading(false);
        showToast(error.message, "error");
      });
  };

  const isFormValid = isEmailValid && isPhoneValid && username.length > 0;

  return (
    <Layout>
      <div
        id="register-module"
        className="flex flex-col bg-primary  w-full md:w-[450px] p-2 md:m-auto"
      >
        <div id="register-headers" className="mb-4">
          <h1 className="text-center text-bold text-xl text-tertiary">
            Registrar <span className="text-secondary">Portero</span>
          </h1>
        </div>
        <div id="register-form">
          <form className="flex flex-col">
            <label className="text-lg mb-1 text-tertiary">
              Nombre de usuario*
            </label>
            <input
              className="border ronded-2 mb-2 p-2 text-primary"
              type="text"
              disabled={isLoading}
              value={username}
              onChange={handleChange(setUsername)}
            />
            <label className="text-lg mb-1 text-tertiary">Email *</label>
            <input
              className="border ronded-2 mb-2 p-2 text-primary"
              type="text"
              disabled={isLoading}
              value={email}
              onChange={handleEmailChange}
            />
            <label className="text-lg mb-1 text-tertiary">Teléfono *</label>
            <input
              className="border ronded-2 mb-2 p-2 text-primary"
              type="text"
              disabled={isLoading}
              value={phoneNumber}
              onChange={handleChangePhone}
              onKeyDown={handleKeyDown}
            />
            {userData?.role !== "ROLE_PUBLISHER_ADMIN" && (
              <>
                <PublisherIdDropdown
                  publisherId={publisherId}
                  setPublisherId={setPublisherId}
                />
              </>
            )}
          </form>
        </div>
        <div id="register-form">
          <button
            className={`w-full  text-lg p-2 mt-4 text-primary ${isFormValid && !isLoading ? "bg-secondary " : "bg-gray-200 cursor-not-allowed"}`}
            type="button"
            onClick={handleRegisterApproval}
            disabled={!isFormValid || isLoading}
          >
            {isLoading ? "Registrando..." : "Registrar"}
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default RegisterPublisherBouncer;
