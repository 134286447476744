import { Layout } from "../components/Layout";
import { facebookUrlId, twitterUrlId, instagramUrlId}  from "../constants/enviroment";

export const TermsAndConditions = () => {
  return (
    <Layout>
      <div id="terms-and-condition" className="flex flex-col  bg-tertiary">
        <h1 id="terms-and-condition" className="text-xl lora-bold my-4">
        Terminos y Condiciones
        </h1>
        <section>
          <ul className="lora-regular text-primary">
            <li>
              <span className="lora-bold">
              Responsabilidad de las Promotoras: <br></br>
              </span>
              Las promotoras, como clientes de DigiTicket, son las únicas
              responsables de la creación, organización, gestión y ejecución de
              los eventos que publican en la plataforma StoreTickets®. StoreHQ® y
              DigiTicket® no tienen control ni participación directa en la
              organización de estos eventos y, por lo tanto, no se hacen
              responsables de su contenido, calidad, seguridad o éxito.
            </li>

            <li>
              <span className="lora-bold">
               Exclusión de Responsabilidad de StoreHQ® : <br></br>
              </span>
              StoreHQ proporciona la infraestructura tecnológica a través de
              StoreTickets para facilitar la venta de entradas, pero no verifica
              ni garantiza la veracidad de la información proporcionada por las
              promotoras, ni la realización efectiva de los eventos. Cualquier
              reclamación o disputa relacionada con los eventos debe dirigirse
              directamente a la promotora responsable.
            </li>

            <li>
              <span className="lora-bold">
              Exclusión de Responsabilidad de DigiTicket®: <br></br>
              </span>
              DigiTicket, como empresa promotora que brinda soporte e
              información a las promotoras, no se responsabiliza por los eventos
              creados y gestionados por estas últimas. DigiTicket no interviene
              en la organización de los eventos ni en la relación contractual
              entre las promotoras y los compradores de entradas.
            </li>

            <li>
              <span className="lora-bold">
              Indemnización: <br></br>
              </span>
              El usuario acepta indemnizar y mantener indemnes a StoreHQ® y
              DigiTicket® , así como a sus empleados, directores, agentes y
              afiliados, de cualquier reclamación, demanda, daño o gasto
              (incluyendo honorarios legales razonables) que surjan de o estén
              relacionados con el uso del sitio web StoreTickets®, la
              participación en un evento, o la violación de estos términos y
              condiciones por parte del usuario o promotora.
            </li>
            <li>
              <span className="lora-bold">
              Protección de Datos Personales: <br></br>
              </span>
              StoreHQ® y DigiTicket® se comprometen a proteger la privacidad y
              seguridad de los datos personales de los usuarios y promotoras
              registrados en StoreTickets®. La información proporcionada por los
              usuarios se utiliza únicamente para la gestión de eventos
              publicados en la plataforma y no se comparte con terceros sin su
              consentimiento.
            </li>
          </ul>
        </section>
      </div>
    </Layout>
  );
};
