import { baseUrl } from "../constants/enviroment";
import { UpdateUserPayload, UpdateUserResponse } from "../types/UpdateUserType";
import { getCookie } from "../utils/getCookie";

export const adminUpdateUser = async (
  data: UpdateUserPayload,
): Promise<UpdateUserResponse> => {
  try {
    const jwtFromCookie = getCookie("jwt");
    const response = await fetch(`${baseUrl}/admin/updateUser`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtFromCookie}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
