import { useEffect, useState } from "react";
import { getGuestEventDetails } from "../../api/getGuestEventDetails";
import { getAllUserTickets } from "../../api/getAllUserTickets";
import { GuestEventData } from "../../types/EventDataTypes";
import { QRCode } from "react-qrcode-logo";
import { TicketContent } from "../../types/OrderTickets";
import { Layout } from "../../components/Layout";

export const OrderTickets = () => {
  const [eventDetails, setEventDetails] = useState<GuestEventData | null>(null);
  const [eventTickets, setEventTickets] = useState<TicketContent[]>([]);

  // Función para obtener la fecha formateada
  const getDate = (date: string) => {
    const eventDate = new Date(date);
    const day = eventDate.getDate().toString().padStart(2, "0");
    const month = (eventDate.getMonth() + 1).toString().padStart(2, "0"); // Los meses van de 0 a 11
    const year = eventDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  // Función para obtener la hora formateada
  const getHour = (date: string) => {
    const eventDate = new Date(date);
    const hours = eventDate.getHours();
    const minutes = eventDate.getMinutes().toString().padStart(2, "0");

    const formattedHours = hours.toString().padStart(2, "0");

    return `${formattedHours}:${minutes} `;
  };

  const fetchEventDetails = async (eventId: number | string) => {
    try {
      const eventResponse = await getGuestEventDetails(eventId);
      setEventDetails(eventResponse.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTickets = async (eventId: number) => {
    try {
      const ticketsResponse = await getAllUserTickets({
        eventId: eventId,
        offset: 0,
        page: 0,
        pageNumber: 0,
        pageSize: 0,
        paged: false,
        size: 0,
        sort: [],
        sortSorted: false,
        sortUnsorted: false,
        unpaged: false
      });
      setEventTickets(ticketsResponse.data.content);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const eventId = searchParams.get("eventId");
    const customUrl = searchParams.get("name");
    console.log(eventId, customUrl);
    if (customUrl) {
      fetchEventDetails(customUrl);
    }
    if (eventId) {
      fetchTickets(Number(eventId));
    }
    /* if (eventId) {
      setEventId(Number(eventId));
    }
    if (customUrl) {
      setCustomUrl(customUrl);
    } */
  }, []);

  /* useEffect(() => {
    if (eventId && firstLoad) {
      fetchTickets(eventId);
      setFirstLoad(false);
    }
  }, [eventId]);

  useEffect(() => {
    if (customUrl && firstLoad) {
      fetchEventDetails(customUrl);
      setFirstLoad(false);
    }
  }, [customUrl]); */

  return (
    <Layout>
      <div className="w-full">
        {eventDetails ? (
          <div id="event-summary">
            <h1 className="text-2xl lora-bold">
              Tus Tickets para <span className="">{eventDetails?.name}</span>
            </h1>

            <p>Fecha: {getDate(eventDetails?.eventDate)} </p>
            <p>Hora: {getHour(eventDetails?.eventDate)}</p>
          </div>
        ) : (
          <div id="event-summary-skeleton">
            <h1>...</h1>

            <h2>...</h2>
          </div>
        )}

        {eventTickets.length > 0 ? (
          <div
            id="tickets"
            className="flex flex-col justify-center items-center"
          >
            {eventTickets.map((ticket, index) => (
              <div
                className="border border-primary bg-primary text-tertiary my-8 justify-center items-center w-full md:w-[320px] py-8"
                key={index}
              >
                <p className="text-center text-xl">
                  Número de Ticket:{" "}
                  <span className="text-secondary">{ticket.id}</span>
                </p>
                <p className="text-center text-xl">
                  Estado:{" "}
                  {ticket.isValidated ? (
                    <span className="text-red-500">Redimido</span>
                  ) : (
                    <span className="text-secondary">Disponible</span>
                  )}
                </p>
                <p className="text-center text-xl mb-4">
                  Localidad:{" "}
                  <span className="text-secondary">
                    {ticket.orderEventTier.name}
                  </span>
                </p>
                <div
                  className={`m-auto w-[320px] ${ticket.isValidated ? " blur-md " : ""}`}
                >
                  <QRCode
                    value={`${ticket.id}`}
                    size={297}
                    style={{ margin: "auto" }}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div id="tickets-skeleton">
            <p>...</p>
            <p>...</p>
            <p>...</p>
          </div>
        )}
      </div>
    </Layout>
  );
};
