import { useState } from "react";
import { forgotPassword } from "../../api/forgotPassword";
import { useAppContext } from "../../hooks/useAppContext";
import { ForgotPasswordPayload } from "../../types/ForgotPassword";
import { UpdatePasswordTemporalPayload } from "../../types/UpdateTemporaryPassword";
import { updatePasswordTemporal } from "../../api/updatePasswordTemporal";
import { useToast } from "../../context/ToastContext";
import { generateToken } from "../../api/generateToken";
import { GenerateTokenPayload } from "../../types/GenerateTokenTypes";
import { UserData } from "../../types/UserDataTypes";
import { setCookie } from "../../utils/setCookie";
import { Layout } from "../../components/Layout";
import { useRoute } from "../../hooks/useRoute";

export const ForgotPassword: React.FC = () => {
  const { login } = useAppContext();
  const { showToast } = useToast();
  const { params } = useRoute();
  const [step, setStep] = useState<number>(
    params.step ? parseInt(params.step) : 0
  );

  const [email, setEmail] = useState<string>(params.email || "");
  const [tempPassword, setTempPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [tempUser, setTempUser] = useState<UserData | null>(null);

  const handleForgotPassword = async () => {
    try {
      const payload: ForgotPasswordPayload = {
        email
      };
      await forgotPassword(payload);
      showToast(
        "Se ha enviado un correo con las instrucciones para recuperar tu contraseña"
      );
      setStep(1);
    } catch (error) {
      showToast(
        "Ocurrió un error al intentar recuperar tu contraseña",
        "error"
      );
    }
  };

  const handleTempLogin = async () => {
    try {
      const { data } = await generateToken({
        email,
        password: tempPassword
      });

      if (!data.user) {
        showToast("Ocurrió un error al intentar recuperar tu contraseña");
        return;
      }
      setCookie({
        name: "jwt",
        value: data.jwt,
        days: 90
      });
      showToast("Se ingreso exitosamente tu contraseña temporal");
      setStep(2);
      setTempUser(data.user);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showToast("Por favor revise su contraseña temporal", "error");
    }
  };

  const handleUpdatePassword = async () => {
    try {
      const payload: UpdatePasswordTemporalPayload = {
        password: newPassword
      };
      const tempPasswordUpdateReq = await updatePasswordTemporal(payload);
      if (tempPasswordUpdateReq.status_code !== 200) {
        showToast(
          "Ocurrió un error al intentar actualizar tu contraseña",
          "error"
        );
        return;
      }
      showToast("Se ha actualizado tu contraseña");
      const loginWithNewPassword: GenerateTokenPayload = {
        email: tempUser?.email || "",
        password: newPassword
      };
      login(loginWithNewPassword, "/");
    } catch (error) {
      showToast(
        "Ocurrió un error al intentar actualizar tu contraseña",
        "error"
      );
    }
  };

  return (
    <Layout>
      <div className="flex flex-col w-full md:w-[450px] m-auto">
        <h1 className="text-xl lora-bold">Recupera tu contraseña</h1>
        <p className="mb-2">
          {step === 0
            ? "Ingresa el correo electronico asociado a tu cuenta"
            : null}
          {step === 1 ? "Paso 2: ingresa la contraseña temporal" : null}
          {step === 2 ? "Paso 3: ingresa tu nueva contraseña" : null}
        </p>
        {step === 0 ? (
          <div
            id="request-temp-password"
            className="flex flex-col bg-primary p-4"
          >
            <label className="text-lg mb-1 text-tertiary">Email*</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mb-2 p-2 text-primary"
            />
            <button
              className="lora-regular bg-secondary p-2 shadow-md mt-2 uppercase"
              onClick={() => handleForgotPassword()}
            >
              Enviar password temporal
            </button>
          </div>
        ) : null}
        {step === 1 ? (
          <div className="w-full flex flex-col bg-primary h-[300px] p-4">
            <p className="mb-2 text-tertiary">Correo:</p>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="p-2 mb-2 text-primary"
            />
            <p className="text-tertiary">Contraseña Temporal:</p>
            <input
              type="text"
              value={tempPassword}
              onChange={(e) => setTempPassword(e.target.value)}
              className="p-2 mb-2 text-primary"
            />
            <button
              className="lora-regular bg-secondary p-2 shadow-md mt-4"
              onClick={() => handleTempLogin()}
            >
              Enviar mi contraseña temporal
            </button>
          </div>
        ) : null}
        {step === 2 ? (
          <div className="w-full flex flex-col bg-primary h-[300px] p-4">
            <p className="border p-2 text-tertiary">Nueva Contraseña</p>
            <input
              type="text"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="mb-2 p-2 text-primary"
            />
            <p className="border p-2 text-tertiary">Confirmar Contraseña</p>
            <input
              type="text"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              className="mb-2 p-2 text-primary"
            />
            <button
              className="lora-regular bg-secondary p-2 shadow-md mt-4"
              disabled={
                newPassword !== confirmNewPassword && newPassword.length >= 5
              }
              onClick={() => handleUpdatePassword()}
            >
              Actualizar contraseña
            </button>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};
