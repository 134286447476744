// /guest/getEventDetails/{id}
import { baseUrl } from "../constants/enviroment";
import { GetAllEventsResponse } from "../types/GetAllEventsTypes";
import { getCookie } from "../utils/getCookie";

export const getPublisherEventDetails = async (
  id: number,
): Promise<GetAllEventsResponse> => {
  const jwtFromCookie = getCookie("jwt");
  try {
    const response = await fetch(
      `${baseUrl}/publisherAdmin/getAllEvents?ids=${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtFromCookie}`,
          "Content-Type": "application/json",
        },
      },
    );
    return await response.json();
  } catch (error: any) {
    return error;
  }
};
