export const getCookie = (name: string): string | undefined => {
  const encodedName = encodeURIComponent(name) + "=";
  const ca = document.cookie.split(";");

  for (let c of ca) {
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }

    if (c.indexOf(encodedName) === 0) {
      const cookieValue = c.substring(encodedName.length, c.length);
      const decodedCookieValue = decodeURIComponent(cookieValue);
      return decodedCookieValue !== "undefined" && decodedCookieValue
        ? decodeURIComponent(cookieValue)
        : undefined;
    }
  }

  return undefined;
};
