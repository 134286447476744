import { baseUrl } from "../constants/enviroment";
import {
  RegisterUserPayload,
  RegisterUserResponse,
} from "../types/RegisterUserTypes";

export const registerUser = async (
  data: RegisterUserPayload,
): Promise<RegisterUserResponse> => {
  try {
    const response = await fetch(`${baseUrl}/authentication/registerUser`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
