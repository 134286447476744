import React, { ReactNode, useEffect, useState } from "react";
import { RouteContextType } from "../types/RouteContextTypes";

export const RouteContext = React.createContext<RouteContextType | undefined>(
  undefined
);

export const RouteProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [route, setRoute] = useState<string>(window.location.pathname);
  const [params, setParams] = useState<{ [key: string]: string }>({});

  const handleRouteChange = () => {
    setRoute(window.location.pathname);
  };

  useEffect(() => {
    window.addEventListener("popstate", handleRouteChange);
    return () => window.removeEventListener("popstate", handleRouteChange);
  }, []);

  useEffect(() => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const searchParams = new URLSearchParams(url.search);

    const extractedParams: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      extractedParams[key] = value;
    });
    setParams(extractedParams);
    handleRouteChange();
  }, [route]);

  return (
    <RouteContext.Provider value={{ route, params, setRoute, setParams }}>
      {children}
    </RouteContext.Provider>
  );
};
