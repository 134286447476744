import { useEffect, useState } from "react";
import { IoIosAlert, IoIosTrash } from "react-icons/io";
import { PiHandTapBold } from "react-icons/pi";
import { getGuestEventDetails } from "../../api/getGuestEventDetails";
import { registerEventOrder } from "../../api/registerEventOrder";
import LoginRegisterModal from "../../components/modals/LoginRegisterModal";
import { currencySymbol } from "../../constants/enviroment";
import { useToast } from "../../context/ToastContext";
import { useAppContext } from "../../hooks/useAppContext";
import { useRoute } from "../../hooks/useRoute";
import { GuestEventData } from "../../types/EventDataTypes";
import { EventOrderPayloadItem } from "../../types/EventOrder";
import { formatNumbersToPriceString } from "../../utils/numberPriceFormat";

export const Event = () => {
  const { setRoute } = useRoute();
  const { user } = useAppContext();
  const { showToast } = useToast();

  const [eventId, setEventId] = useState<number | null>(null);
  const [customUrl, setCustomUrl] = useState<string | null>(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [eventDetails, setEventDetails] = useState<GuestEventData | null>(null);
  const [ticketOrders, setTicketOrders] = useState<EventOrderPayloadItem[]>([]);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [ticketsRemaining, setTicketsRemaining] = useState<number>(8);

  const fetchEventDetails = async (eventId: number|string) => {
    try {
      const eventResponse = await getGuestEventDetails(eventId);
      setEventDetails(eventResponse.data);
    } catch (error) {
      showToast("ha ocurrido un error al cargar los detalles del evento", "error");
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const eventId = searchParams.get("eventId");
    const customUrl = searchParams.get("name");
    if (eventId) {
      setEventId(Number(eventId));
    }
    if (customUrl) {
      setCustomUrl(customUrl);
    }
  }, []);

  useEffect(() => {
    if (eventId && firstLoad) {
      fetchEventDetails(eventId);
      setFirstLoad(false);
    }
  }, [eventId]);

  useEffect(() => {
    if (customUrl && firstLoad) {
      fetchEventDetails(customUrl);
      setFirstLoad(false);
    }
  }, [customUrl]);

  const getTotalTickets = () => {
    return ticketOrders.reduce((total, order) => total + order.quantity, 0);
  };

  const getTotalPrice = () => {
    const totalPrice = ticketOrders.reduce((total, order) => {
      const tier = eventDetails?.tiers.find((t) => t.id === order.eventTierId);
      return total + (tier ? tier.price * order.quantity : 0);
    }, 0);

    return formatNumbersToPriceString(totalPrice);
  };

  const handleSelectTickets = (tierId: number, quantity: number) => {
    setTicketOrders((prevOrders) => {
      const existingOrder = prevOrders.find(
        (order) => order.eventTierId === tierId
      );

      // Si se intenta agregar una cantidad que hace que ticketsRemaining sea menor a 0, no permitir la acción
      const adjustedQuantity = existingOrder
        ? quantity - existingOrder.quantity
        : quantity;
      if (ticketsRemaining - adjustedQuantity < 0) {
        showToast("No se pueden agregar más tiquetes. Límite alcanzado.");
        return prevOrders; // No modificar el estado de ticketsRemaining ni ticketOrders
      }

      if (quantity === 0) {
        // Si el valor es 0, eliminar la orden y ajustar ticketsRemaining
        if (existingOrder) {
          // Calcula la nueva cantidad restante de tiquetes
          const newRemaining = ticketsRemaining + existingOrder.quantity;
          setTicketsRemaining(newRemaining);
          return prevOrders.filter((order) => order.eventTierId !== tierId);
        }
        return prevOrders;
      }

      if (existingOrder) {
        // Si ya existe la orden, actualizar la cantidad y ajustar ticketsRemaining
        const newQuantity = quantity - existingOrder.quantity;
        setTicketsRemaining(ticketsRemaining - newQuantity);
        return prevOrders.map((order) =>
          order.eventTierId === tierId ? { ...order, quantity } : order
        );
      }

      // Si no existe, agregar la nueva orden y ajustar ticketsRemaining
      setTicketsRemaining(ticketsRemaining - quantity);
      return [...prevOrders, { eventTierId: tierId, quantity }];
    });
  };

  const getTotalTicketsForTier = (tierId: number) => {
    const order = ticketOrders.find((order) => order.eventTierId === tierId);
    return order ? order.quantity : 0;
  };

  const getShowSummaryAndActions = () => {
    return (
      user &&
      getTotalTickets() > 0 &&
      (!ticketsRemaining || ticketsRemaining > 0)
    );
  };

  const handleBuyTickets = async () => {
    setLoadingOrder(true);
    if (ticketOrders.length === 0) {
      showToast("No has seleccionado ninguna cantidad de ninguna localidad");
      setLoadingOrder(false);
      return;
    }
    try {
      const createOrderReq: any = await registerEventOrder(ticketOrders);
      // handle if message is a negagive number as a string
      if (createOrderReq.status_code === 422) {
        if (createOrderReq.message.length > 1) {
          showToast(createOrderReq.message, "error");
          return;
        }
        const ticketsAlreadyAcquired = parseInt(createOrderReq.message); // the message is the number of tickets user has already acquired for thiw venue
        if (ticketsAlreadyAcquired > 0) {
          showToast(
            `Solo puedes comprar ${ticketsAlreadyAcquired} tickets mas para este evento`,
            "error"
          );
          return;
        }
        if (ticketsAlreadyAcquired === 0) {
          showToast(
            `Ya no puedes comprar mas tickets para este evento`,
            "error"
          );
          return;
        }
      }
      if (createOrderReq.status_code === 200) {
        showToast(
          `La Orden #${createOrderReq.data.id} ha sido creada exitosamente, por favor adjunta tu comprobante de pago`,
          "success"
        );
        window.history.pushState(
          {},
          "",
          `/order/?id=${createOrderReq.data.id}`
        );
        setRoute(`/order/?id=${createOrderReq.data.id}`); // Asegúrate de que la ruta se actualiza
        setLoadingOrder(false);
        handleClearTickets();
      }
    } catch (error: any) {
      setTicketsRemaining(0);
    } finally {
      setLoadingOrder(false);
    }
  };

  const handleClearTickets = () => {
    setTicketOrders([]);
  };

  if (!eventDetails) {
    return <div>Event not found</div>;
  }

  return (
    <div id="event-page">
      {loadingOrder ? (
        <div className="absolute top-[96px] h-[100vh] w-full opacity-50 bg-gray-600 z-0">
          <div className="m-auto w-full">
            <div className="w-12 h-12 rounded-full animate-spin border border-solid border-yellow-500 border-t-transparent "></div>
          </div>
        </div>
      ) : null}
      <div className="w-full max-w-[1400px] m-auto bg-tertiary">
        {/** Mobile Layout Start*/}
        {!getShowSummaryAndActions() ? (
          <div id="event-details" className="md:hidden">
            <div className="overflow-hidden relative">
              <img
                src={eventDetails?.imageUrl}
                alt={eventDetails?.name}
                className="opacity-20 absolute top-0 left-0 w-full h-auto"
              />
              <div className="relative px-4 py-8">
                <h1 className="lora-bold text-4xl text-center">
                  {eventDetails?.name}
                </h1>
                <h2 className="lora-regular text-xl text-center text-shadow-default">
                  {eventDetails?.description}
                </h2>
                <p>
                  Fecha:{" "}
                  <span className="lora-bold">{eventDetails?.eventDate}</span>
                </p>
                <p>
                  Lugar:{" "}
                  <span className="lora-bold">{eventDetails?.location}</span>
                </p>
                <p>
                  Traido por:{" "}
                  <span className="lora-bold">
                    {eventDetails?.publisherName}
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-[140px] md:hidden"></div>
        )}
        {/* The following conditional render is fixed to the top  MOBILE ONLY*/}
        {getShowSummaryAndActions() ? (
          <div
            id="order-summary-mobile"
            className="fixed top-0 w-full p-4 bg-tertiary text-primary shadow-md md:hidden z-10"
          >
            {ticketsRemaining < 8 ? (
              <div className="w-full flex items-center justify-between mb-4 ">
                <IoIosAlert size={30} className="text-red-500" />
                <p className="lora-bold text-primary">{`Puedes comprar ${ticketsRemaining} tickets mas para este evento`}</p>
              </div>
            ) : null}

            <ul className="mt-2 h-24 overflow-scroll mb-4">
              {ticketOrders.map((order) => {
                const tier = eventDetails?.tiers.find(
                  (t) => t.id === order.eventTierId
                );
                return (
                  <li
                    key={order.eventTierId}
                    className="flex justify-between lora-regular text-primary text-xl"
                  >
                    <span>{tier?.name}:</span>
                    <span>
                      {order.quantity} x {currencySymbol}
                      {formatNumbersToPriceString(tier?.price ?? 0)}
                    </span>
                  </li>
                );
              })}
            </ul>
            <p className="lora-bold text-xl text-primary px-4 text-right">
              Total: {currencySymbol} {getTotalPrice()}
            </p>
          </div>
        ) : null}

        {!ticketsRemaining || ticketsRemaining > 0 ? (
          <div className="bg-primary p-2 md:hidden" id="event-tiers">
            {user !== null ? (
              eventDetails?.tiers.map((tier) => (
                <div key={tier.id} className="flex w-full justify-between p-4">
                  <div className="flex flex-col">
                    <p className="lora-regular text-secondary text-lg">
                      {tier.name}
                    </p>
                    <p className="lora-regular text-secondary text-lg">
                      {currencySymbol}
                      {formatNumbersToPriceString(tier.price)}
                    </p>
                  </div>
                  {tier.isAvailable ? (
                    <div className="flex items-center">
                      <button
                        type="button"
                        onClick={() => {
                          const currentValue = getTotalTicketsForTier(tier.id);
                          const newValue = Math.max(0, currentValue - 1);
                          handleSelectTickets(tier.id, newValue);
                        }}
                        className="h-8 w-8 text-center bg-secondary text-primary"
                      >
                        -
                      </button>
                      <select
                        value={getTotalTicketsForTier(tier.id)}
                        onChange={(e) => {
                          const value = Math.max(
                            0,
                            Math.min(8, parseInt(e.target.value) || 0)
                          );
                          handleSelectTickets(tier.id, value);
                        }}
                        className="h-8 w-12 text-center rounded-none"
                      >
                        {Array.from({ length: 9 }, (_, i) => (
                          <option key={i} value={i}>
                            {i}
                          </option>
                        ))}
                      </select>
                      <button
                        type="button"
                        onClick={() => {
                          const currentValue = getTotalTicketsForTier(tier.id);
                          const newValue = Math.min(8, currentValue + 1);
                          handleSelectTickets(tier.id, newValue);
                        }}
                        className="h-8 w-8 text-center rounded-r-md bg-secondary text-primary"
                      >
                        +
                      </button>
                    </div>
                  ) : (
                    <div>
                      <p className="lora-bold text-red-600">No disponible</p>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <LoginRegisterModal />
            )}
          </div>
        ) : null}

        {ticketsRemaining === 0 ? (
          <div className="p-2 md:hidden" id="ticket-limit-reached">
            <div className="flex w-full justify-between p-4">
              <div className="flex flex-col">
                <p className="lora-regular text-primary text-lg">
                  No es posible comprar mas tickets para este evento, el maximo de tickets permitidos por evento es de <span className="lora-bold">8</span>
                </p>
              </div>
            </div>
          </div>
        ) : null}

        {/* The following conditional render is fixed to the bottom MOBILE ONLY*/}
        {getShowSummaryAndActions() ? (
          <div
            id="event-actions-mobile"
            className="fixed bottom-0 w-full md:hidden"
          >
            <button
              className="bg-secondary w-full p-8 uppercase text-4xl"
              onClick={handleBuyTickets}
            >
              <div className="flex items-center justify-center">
                <PiHandTapBold size={30} className="md:hidden" />
                <span className="lora-bold">Comprar</span>
              </div>
            </button>

            <button
              className="bg-tertiary w-full p-8 uppercase text-4xl"
              onClick={handleClearTickets}
            >
              <div className="flex items-center justify-center">
                <IoIosTrash size={30} className="md:hidden" />
                <span className="lora-bold">Cancelar</span>
              </div>
            </button>
          </div>
        ) : null}
        {/** Mobile Layout End*/}

        {/** Desktop Layout Start*/}
        <div className="hidden md:flex md:visible w-full bg-primary pt-4 pb-4 mt-4">
          <div className="w-1/2 min-w-1/2" id="event-flyer">
            <div className="p-4 text-tertiary">
              <h1 className="lora-bold text-4xl mb-2 uppercase text-center text-secondary">
                {eventDetails?.name}
              </h1>
              <h2 className="lora-regular text-xl mb-2">
                {eventDetails?.description}
              </h2>
              <p>
                Fecha:{" "}
                <span className="lora-bold mb-2">
                  {eventDetails?.eventDate}
                </span>
              </p>
              <p>
                Lugar:{" "}
                <span className="lora-bold mb-2">{eventDetails?.location}</span>
              </p>
              <p>
                Traido por:{" "}
                <span className="lora-bold mb-2">
                  {eventDetails?.publisherName}
                </span>
              </p>
            </div>
            <img
              src={eventDetails?.imageUrl}
              alt={eventDetails?.name}
              className="w-[350px] m-auto"
            />
          </div>
          <div className="w-1/2 pl-4 pb-4 pr-4" id="event-details-and-summary">
            <div className="flex flex-col">
              <p className="p-4 text-xl lora-bold text-tertiary">Localidades</p>
              <div id="event-tiers" className="grid grid-cols-1 ">
                {user !== null ? (
                  eventDetails?.tiers.map((tier) => (
                    <div key={tier.id} className="flex justify-between p-4 ">
                      <div className="flex flex-col">
                        <p className="lora-regular text-secondary text-lg">
                          {tier.name}
                        </p>
                        <p className="lora-bold text-secondary text-lg">
                          {currencySymbol}
                          {formatNumbersToPriceString(tier.price)}
                        </p>
                      </div>
                      {tier.isAvailable ? (
                        <div className="flex items-center">
                          <button
                            type="button"
                            onClick={() => {
                              const currentValue = getTotalTicketsForTier(
                                tier.id
                              );
                              const newValue = Math.max(0, currentValue - 1);
                              handleSelectTickets(tier.id, newValue);
                            }}
                            className="h-8 w-8 text-center bg-secondary text-primary"
                          >
                            -
                          </button>
                          <select
                            value={getTotalTicketsForTier(tier.id)}
                            onChange={(e) => {
                              const value = Math.max(
                                0,
                                Math.min(8, parseInt(e.target.value) || 0)
                              );
                              handleSelectTickets(tier.id, value);
                            }}
                            className="h-8 w-12 text-center rounded-none"
                          >
                            {Array.from({ length: 9 }, (_, i) => (
                              <option key={i} value={i}>
                                {i}
                              </option>
                            ))}
                          </select>
                          <button
                            type="button"
                            onClick={() => {
                              const currentValue = getTotalTicketsForTier(
                                tier.id
                              );
                              const newValue = Math.min(8, currentValue + 1);
                              handleSelectTickets(tier.id, newValue);
                            }}
                            className="h-8 w-8 text-center bg-secondary text-primary"
                          >
                            +
                          </button>
                        </div>
                      ) : (
                        <div>
                          <p className="lora-bold text-red-600">
                            No disponible
                          </p>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="pl-4 ">
                    <LoginRegisterModal />
                  </div>
                )}
              </div>
            </div>

            <div
              id="order-summary-desktop"
              className="w-full text-tertiary  mt-4 p-4"
            >
              <h3 className="lora-bold text-xl">Resumen de la Orden</h3>
              {ticketsRemaining > 0 && ticketsRemaining < 8 ? (
                <div className="w-full flex items-start mb-4">
                  <IoIosAlert size={30} className="text-red-500" />
                  <p className="lora-bold text-red-500">{`Solo puedes comprar ${ticketsRemaining} tickets mas para este evento`}</p>
                </div>
              ) : null}

              <ul className="mt-2 mb-4">
                {ticketOrders.length > 0
                  ? ticketOrders.map((order) => {
                      const tier = eventDetails?.tiers.find(
                        (t) => t.id === order.eventTierId
                      );
                      return (
                        <li
                          key={order.eventTierId}
                          className="flex justify-between lora-regular text-tertiary text-xl"
                        >
                          <span>{tier?.name}:</span>
                          <span>
                            {order.quantity} x {currencySymbol}
                            {formatNumbersToPriceString(tier?.price ?? 0)}
                          </span>
                        </li>
                      );
                    })
                  : null}
              </ul>
              {ticketsRemaining === 0 ? (
                <p>No puedes comprar mas tickets de este evento</p>
              ) : null}

              {ticketOrders.length === 0 ? (
                <p>Selecciona alguna localidad para empezar</p>
              ) : null}
              <p className="lora-regular">Metodo de pago:</p>
              <p className="lora-bold">SINPE Movil</p>
              <p className="lora-bold text-xl text-secondary text-right">
                Total: {currencySymbol} {getTotalPrice()}
              </p>
            </div>
            <div id="event-actions-mobile" className="w-full flex flex-col">
              <button
                className="bg-secondary w-full p-2 uppercase"
                onClick={handleBuyTickets}
              >
                <div className="flex items-center justify-center">
                  <PiHandTapBold size={30} className="md:hidden" />
                  <span className="lora-bold">Comprar</span>
                </div>
              </button>

              <button
                className="bg-primary w-full p-2 uppercase"
                onClick={handleClearTickets}
              >
                <div className="flex items-center justify-center text-tertiary">
                  <IoIosTrash size={30} className="md:hidden" />
                  <span className="lora-bold">Cancelar</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        {/** Desktop Layout End*/}
      </div>
    </div>
  );
};
