import { baseUrl } from "../constants/enviroment";
import {
  GenerateTokenFromGooglePayload,
  GenerateTokenReponse,
} from "../types/GenerateTokenTypes";

export const generateTokenFromGoogle = async (
  payload: GenerateTokenFromGooglePayload,
): Promise<GenerateTokenReponse> => {
  try {
    const response = await fetch(
      `${baseUrl}/authentication/generateTokenFromGoogle`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
