import React from "react";
import { Link } from "../router/Link";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  onPageChange
}) => {
  const handlePreviousPage = () => {
    if (currentPage > 0) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <Link to={`?page=${currentPage - 1}`}>
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-primary text-secondary disabled:bg-gray-400"
        >
          Anterior
        </button>
      </Link>

      <span>
        Página {currentPage} de {totalPages}
      </span>
      <Link to={`?page=${currentPage === 1 ? 2 : currentPage + 1}`}>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-primary text-secondary disabled:bg-gray-400"
        >
          Siguiente
        </button>
      </Link>
    </div>
  );
};

export default Pagination;
