import { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { getAllPublishers } from "../../api/getAllPublishers";
import { updatePublishers } from "../../api/updatePublishers";
import { Layout } from "../../components/Layout";
import TiptapEditor from "../../components/TipTapEditor";
import Pagination from "../../components/utils/Pagination";
import { useToast } from "../../context/ToastContext";
import { useRoute } from "../../hooks/useRoute";
import {
  Content,
  GetAllPublishersPayload,
  GetAllPublishersResponse
} from "../../types/GetAllPublishers";
import { UpdatePublishersPayload } from "../../types/UpdatePublishersTypes";
import { isValidEmail } from "../../utils/isValidEmail";
// import { IoAddCircle } from "react-icons/io5";

export const Publishers = () => {
  const { showToast } = useToast();
  const { params } = useRoute();
  const [publishers, setPublishers] = useState<Content[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(params.page || "1", 10)
  );
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedPublisher, setEditedPublisher] =
    useState<UpdatePublishersPayload | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>(params.search || "");
  const [delayTerm, setDelayTerm] = useState<string>(searchTerm);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [bankInformation, setBankInformation] = useState<string>("");
  const [sinpePhoneNumber, setSinpePhoneNumber] = useState<string>("");
  useEffect(() => {
    if (searchTerm !== "") {
      setCurrentPage(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchTerm(delayTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [delayTerm]);

  useEffect(() => {
    const fetchPublishers = async () => {
      const payload: GetAllPublishersPayload = {
        isActive: true,
        offset: currentPage * 10,
        page: currentPage - 1,
        pageNumber: currentPage,
        pageSize: 2,
        paged: true,
        size: 10,
        sort: "id,asc",
        unpaged: false,
        searchTerm: searchTerm
      };

      try {
        setIsLoading(true);
        const response: GetAllPublishersResponse =
          await getAllPublishers(payload);
        setPublishers(response.data.content);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      } catch (error) {
        showToast("Ha ocurrido un error al cargar los publishers", "error");
        setIsLoading(false);
      }
    };

    fetchPublishers();
  }, [currentPage, showToast, searchTerm]);

  const handleEditPublisher = (publisher: Content) => {
    setIsEditing(true);
    const { id, name, phoneNumber, email, sinpePhoneNumber, bankInformation } =
      publisher;

    setEditedPublisher({
      id,
      name,
      phoneNumber,
      email,
      sinpePhoneNumber,
      bankInformation
    });
    setIsEmailValid(isValidEmail(email)); // Validar inmediatamente el correo existente
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEditedPublisher((prev) => prev && { ...prev, email: emailValue });
    setIsEmailValid(isValidEmail(emailValue));
  };

  const handleBankInformationChange = (content: string) => {
    setBankInformation(content);
    setEditedPublisher((prev) => prev && { ...prev, bankInformation: content });
  };
  const handleChangeSinpePhone = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let sinpePhoneValue = event.target.value.replace(/\D/g, "");
    if (sinpePhoneValue.length > 8) {
      sinpePhoneValue = sinpePhoneValue.slice(0, 8);
    }
    setSinpePhoneNumber(sinpePhoneValue);
    setEditedPublisher(
      (prev) => prev && { ...prev, sinpePhoneNumber: sinpePhoneValue }
    );
  };
  const handleSaveChanges = async () => {
    if (!editedPublisher || !isEmailValid) return;

    try {
      setIsLoading(true);
      await updatePublishers(editedPublisher);

      const updatedPublishers: Content[] = publishers.map((p) =>
        p.id === editedPublisher.id ? { ...p, ...editedPublisher } : p
      );
      setPublishers(updatedPublishers);

      setIsEditing(false);
      setEditedPublisher(null);
      setIsLoading(false);
      showToast("Promotora actualizado correctamente", "success");
    } catch (error) {
      showToast("Ha ocurrido un error al actualizar la Promotora", "error");
      setIsLoading(false);
    }
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    let phoneValue = event.target.value.replace(/\D/g, "");
    if (phoneValue.length > 8) {
      phoneValue = phoneValue.slice(0, 8);
    }
    setEditedPublisher((prev) => prev && { ...prev, phoneNumber: phoneValue });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedPublisher(null);
  };

  return (
    <Layout>
      {isEditing && editedPublisher && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-primary w-[400px] p-4">
            <h1 className="text-secondary text-center pb-4">
              Editar Publisher
            </h1>
            <div className="flex flex-col gap-2">
              <input
                type="text"
                placeholder="Nombre"
                className="input py-1 px-2"
                disabled={isLoading}
                value={editedPublisher.name}
                onChange={(e) =>
                  setEditedPublisher(
                    (prev) => prev && { ...prev, name: e.target.value }
                  )
                }
              />
              <input
                className="input py-1 px-2"
                type="text"
                placeholder="Teléfono"
                disabled={isLoading}
                value={editedPublisher.phoneNumber}
                onChange={handleChangePhone}
              />
              <input
                className="border ronded-2 mb-2 p-2 text-primary"
                type="text"
                placeholder="Teléfono para sinpe"
                disabled={isLoading}
                value={editedPublisher.sinpePhoneNumber}
                onChange={handleChangeSinpePhone}
              />
              <input
                type="text"
                placeholder="Email"
                className={`input py-1 px-2 ${isEmailValid ? "" : "border-red-500"}`}
                value={editedPublisher.email}
                disabled={isLoading}
                onChange={handleEmailChange}
              />
              <div className="mb-2">
                <label className="text-lg mb-1 text-tertiary">
                  Información bancaria *
                </label>
                <TiptapEditor
                  content={editedPublisher.bankInformation}
                  setContent={handleBankInformationChange}
                />
              </div>
              <label className="text-lg text-tertiary">
                Imagen de promotora{" "}
              </label>
              <input
                className="border ronded-2 mb-2 p-2 text-tertiary"
                type="file"
                pattern="\d*"
                disabled={isLoading}
                onChange={(e) => {
                  const files = e.target.files;
                  if (files && files.length > 0) {
                    setEditedPublisher(
                      (prev) => prev && { ...prev, file: files[0] }
                    );
                  }
                }}
              />
              <div className="flex flex-row gap-2 w-full">
                <button
                  className={`${isEmailValid ? "bg-secondary" : "bg-gray-200 cursor-not-allowed"} w-1/2 py-1 `}
                  onClick={handleSaveChanges}
                  disabled={isLoading || !isEmailValid}
                >
                  {isLoading ? "Guardando..." : "Guardar"}
                </button>
                <button
                  className="bg-red-500/80 w-1/2 py-1 "
                  onClick={handleCancelEdit}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex-col w-full p-2 h-full">
        {/* <div className="flex justify-between">
          <h1 className="lora-bold text-primary inline-block py-2  h-full">
            Lista de Publishers
          </h1>
          <button onClick={() => null}>
          <IoAddCircle size={30} className="bg-secondary"/>
        </button>
        </div> */}
        <div className="flex justify-center mt-4">
          <input
            id="search"
            type="text"
            placeholder="Buscar Productora por nombre"
            className="w-[350px] p-2 lora-bold "
            value={delayTerm}
            onChange={(e) => setDelayTerm(e.target.value)}
          />
        </div>
        <div className="w-full h-full my-8 min-h-[520px]">
          <div className="hidden sm:flex flex-row py-2 font-bold text-md px-4">
            <div className="w-[30%] text-left">Productora</div>
            <div className="w-[25%]">Teléfono</div>
            <div className="w-[25%]">Email</div>
            <div className="w-[10%]">Estado</div>
            <div className="w-[10%]"></div>
          </div>
          <div className="flex flex-col w-full h-full gap-2">
            {publishers.length
              ? publishers.map((publisher) => (
                  <div
                    key={publisher.id}
                    className="flex flex-col sm:flex-row w-full h-full py-2 px-4  bg-primary text-secondary shadow-md shadow-black items-center"
                  >
                    <div className="w-full sm:w-[30%] flex gap-1">
                      <span className="sm:hidden font-bold">Productora: </span>
                      {publisher.name}
                    </div>
                    <div className="w-full sm:w-[25%] flex gap-1">
                      <span className="sm:hidden font-bold">Teléfono: </span>
                      {publisher.phoneNumber || "N/A"}
                    </div>
                    <div className="w-full sm:w-[25%] flex gap-1">
                      <span className="sm:hidden font-bold">Email: </span>
                      {publisher.email || "N/A"}
                    </div>
                    <div className="w-full sm:w-[10%] flex gap-1">
                      <span className="sm:hidden font-bold">Estado: </span>
                      {publisher.isActive ? "Activo" : "Inactivo"}
                    </div>
                    <div className="w-full sm:w-[10%] flex justify-end">
                      <FaRegEdit
                        className="cursor-pointer"
                        onClick={() => handleEditPublisher(publisher)}
                      />
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
    </Layout>
  );
};
