export function formatDateString(dateString: string) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

/**
 * Parses a date string and calculates the time left if the difference is less than 9 minutes.
 * @param {string} dateString - The date string in the format 'YYYY-MM-DD HH:mm'.
 * @returns {string} - The time left in minutes and seconds, or 'Time is up' if more than 9 minutes have passed.
 */
export function timeLeftSince(
  creationDate: string,
  currentTimeAndDate: Date,
): { minutes: number; seconds: number } | null {
  const dateParts = creationDate.split(" ");
  const date = dateParts[0].split("-");
  const time = dateParts[1].split(":");

  const givenDate = new Date(
    parseInt(date[0], 10),
    parseInt(date[1], 10) - 1,
    parseInt(date[2], 10),
    parseInt(time[0], 10),
    parseInt(time[1], 10),
  );

  const diffInSeconds = Math.floor(
    (currentTimeAndDate.getTime() - givenDate.getTime()) / 1000,
  );

  if (diffInSeconds < 0 || diffInSeconds > 10 * 60) {
    return null; // Return null if time is up or hasn't started yet
  }

  const TIMER_BASE_SECONDS = 10 * 60;

  const remainingSeconds = TIMER_BASE_SECONDS - diffInSeconds;
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  return { minutes, seconds };
}
