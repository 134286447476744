import { baseUrl } from "../constants/enviroment";
import { UpdatePublishersPayload } from "../types/UpdatePublishersTypes";
import { getCookie } from "../utils/getCookie";

export const updatePublishers = async (
  data: UpdatePublishersPayload,
): Promise<UpdatePublishersPayload> => {
  const jwtFromCookie = getCookie("jwt");
  const formData = new FormData();
  formData.append("id", data.id.toString());
  formData.append("name", data.name?.toString() || "");
  formData.append("phoneNumber", data.phoneNumber?.toString() || "");
  formData.append("email", data.email?.toString() || "");
  formData.append("bankInformation", data.bankInformation?.toString() || "");
  formData.append("sinpePhoneNumber", data.sinpePhoneNumber?.toString() || "");
  formData.append("file", data.file || "");

  try {
    const response = await fetch(`${baseUrl}/admin/updatePublisher`, {
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${jwtFromCookie}`,
        Accept: "*/*",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
