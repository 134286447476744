import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { updatePublisherEvents } from "../../api/updatePublisherEvent";
import { useToast } from "../../context/ToastContext";
import { EventData } from "../../types/EventDataTypes";
import { formatDateString } from "../../utils/formatDate";
interface EditEventsModalProps {
  show: boolean;
  onHide: () => void;
  event: EventData | null;
  refresh?: () => void;
}

const EditEventsModal = ({
  show,
  onHide,
  event,
  refresh
}: EditEventsModalProps) => {
  const { showToast } = useToast();
  const [name, setName] = useState<string>(event?.name || "");
  const [location, setLocation] = useState<string>(event?.location || "");
  const [startDate, setStartDate] = useState<string>(event?.startDate || "");
  const [endDate, setEndDate] = useState<string>(event?.endDate || "");
  const [eventDate, setEventDate] = useState<string>(event?.eventDate || "");
  const [description, setDescription] = useState<string>(
    event?.description || ""
  );
  const [isPaid, setIsPaid] = useState<boolean>(event?.isPaid || false);
  const [isActive, setIsActive] = useState<boolean>(event?.isActive || false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (setState: (value: string) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState(e.target.value);
    };
  };

  const handleSelectChange = (setState: (value: boolean) => void) => {
    return (e: React.ChangeEvent<HTMLSelectElement>) => {
      setState(e.target.value === "true");
    };
  };

  const handleUpdateEvent = async () => {
    setIsLoading(true);
    updatePublisherEvents({
      id: event?.id || 0,
      name,
      description,
      startDate:
        startDate === event?.startDate
          ? undefined
          : formatDateString(startDate),
      endDate:
        endDate === event?.endDate ? undefined : formatDateString(endDate),
      eventDate:
        eventDate === event?.eventDate
          ? undefined
          : formatDateString(eventDate),
      location,
      isActive,
      isPaid,
      file: (file as File) || null
    })
      .then(() => {
        setIsLoading(false);
        showToast("Evento actualizado correctamente", "success");
        refresh && refresh();
        onHide();
      })
      .catch((error) => {
        setIsLoading(false);
        showToast(error.message, "error");
      });
  };

  useEffect(() => {
    if (show) {
      const scrollBarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.paddingRight = `${scrollBarWidth}px`;
    }

    // Cleanup function to reset styles if the component unmounts
    return () => {
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.width = "";
      document.body.style.paddingRight = "";
    };
  }, [show]);

  return (
    <>
      {show && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75"
          onClick={onHide}
        >
          <div
            className="bg-primary w-full md:w-[500px] p-4 mx-4  relative max-h-[80vh] overflow-y-auto modal-content"
            onClick={(e) => e.stopPropagation()} // Previene el cierre al hacer clic dentro del modal
          >
            <div>
              <h1 className="text-secondary text-center text-xl ">
                Editar evento
              </h1>
            </div>
            <button
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 z-50"
              onClick={onHide}
            >
              <MdClose size={24} />
            </button>

            <div id="register-form" className="pt-4">
              <form className="flex flex-col">
                <label className="text-lg mb-1 text-tertiary">
                  Nombre del evento
                </label>
                <input
                  className="border mb-2 p-2 text-primary"
                  type="text"
                  disabled={isLoading}
                  value={name}
                  onChange={handleChange(setName)}
                />
                <label className="text-lg mb-1 text-tertiary">Ubicación </label>
                <input
                  className="border mb-2 p-2 text-primary"
                  type="text"
                  disabled={isLoading}
                  value={location}
                  onChange={handleChange(setLocation)}
                />
                <label className="text-lg mb-1 text-tertiary">
                  Fecha de inicio
                </label>
                <input
                  className="border mb-2 p-2 text-primary"
                  type="datetime-local"
                  disabled={isLoading}
                  value={startDate}
                  onChange={handleChange(setStartDate)}
                />
                <label className="text-lg mb-1 text-tertiary">
                  Fecha de finalización
                </label>
                <input
                  className="border text-lg mb-2 p-2 text-primary"
                  type="datetime-local"
                  disabled={isLoading}
                  value={endDate}
                  onChange={handleChange(setEndDate)}
                />
                <label className="text-lg mb-1 text-tertiary">
                  Fecha del evento
                </label>
                <input
                  className="border mb-2 p-2 text-primary"
                  type="datetime-local"
                  disabled={isLoading}
                  value={eventDate}
                  onChange={handleChange(setEventDate)}
                />
                <label className="text-lg mb-1 text-tertiary">
                  Descripción
                </label>
                <textarea
                  className="border mb-2 p-2 text-primary resize-none h-[140px]"
                  disabled={isLoading}
                  value={description}
                  onChange={handleChange(setDescription)}
                  maxLength={1000}
                />
                <label className="text-lg mb-1 text-tertiary">
                  ¿Es un evento pagado?
                </label>
                <select
                  className="border mb-2 p-2 text-primary"
                  disabled={isLoading}
                  value={isPaid.toString()}
                  onChange={handleSelectChange(setIsPaid)}
                >
                  <option value={"true"}>Sí</option>
                  <option value={"false"}>No</option>
                </select>
                <label className="text-lg mb-1 text-tertiary">
                  ¿Está activo?
                </label>
                <select
                  className="border mb-2 p-2 text-primary"
                  disabled={isLoading}
                  value={isActive.toString()}
                  onChange={handleSelectChange(setIsActive)}
                >
                  <option value={"true"}>Sí</option>
                  <option value={"false"}>No</option>
                </select>
                <label className="text-lg mb-1 text-tertiary">Imagen </label>
                <input
                  className="border mb-2 p-2 text-tertiary"
                  type="file"
                  disabled={isLoading}
                  onChange={(e) => {
                    if (e.target.files) {
                      setFile(e.target.files[0]);
                    }
                  }}
                />
              </form>
            </div>
            <div id="register-form">
              <button
                className={`w-full text-lg p-2 mt-4 text-primary ${!isLoading ? "bg-secondary" : "bg-gray-200 cursor-not-allowed"}`}
                type="button"
                disabled={isLoading}
                onClick={handleUpdateEvent}
              >
                {isLoading ? "Actualizando..." : "Actualizar"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditEventsModal;
