import React, { useEffect, useState } from "react";
import { useAppContext } from "../../hooks/useAppContext";
import { getAllUserOrders } from "../../api/getAllUserOrders";
import { OrderData } from "../../types/OrderDataTypes";
import { timeLeftSince } from "../../utils/formatDate";
import { updateSinpeValidation } from "../../api/updateSinpeValidation";
import { FaFileUpload } from "react-icons/fa";
import { IoAlertCircle } from "react-icons/io5";
import { Layout } from "../../components/Layout";
import { Link } from "../../components/router/Link";

export const Orders = () => {
  const { user } = useAppContext();
  const [userOrders, setUserOrders] = useState<OrderData[]>([]);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [fileUploadForOrder, setFileUploadForOrder] =
    useState<OrderData | null>(null);
  const [isUploadingProofOfPayment, setIsUploadingProofOfPayment] =
    useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  const openProofOfPaymentModalOpen = (orderId: OrderData) => {
    setFileUploadForOrder(orderId);
  };

  const fetchUserOrders = async () => {
    try {
      const userOrders = await getAllUserOrders({});
      setUserOrders(userOrders.data.content);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadProofOfPayment = async () => {
    setIsUploadingProofOfPayment(true);
    try {
      const formData = new FormData();
      formData.append("file", file as Blob);
      await updateSinpeValidation({
        id: fileUploadForOrder?.id as number,
        file: file as File
      });
      // show toast with success message
      // close modal
      setFile(undefined);
      setFileUploadForOrder(null);
      fetchUserOrders();
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploadingProofOfPayment(false);
    }
  };

  useEffect(() => {
    if (!user) return;
    fetchUserOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update currentTime every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (!user) {
    return (
      <div>
        <div id="orders-not-found">
          <p>Ordenes no encontradas</p>
        </div>
      </div>
    );
  }

  return (
    <Layout>
      <div id="user-orders" className="m-auto max-w-[1200px]">
        <h1 className="lora-bold text-primary text-3xl text-center py-4">
          Mis Ordenes
        </h1>
        {/* proofOfPaymentId shows modal when not null: {proofOfPaymentId} */}
        {fileUploadForOrder ? (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-primary w-[400px] p-4">
              <h1 className="lora-bold text-tertiary text-xl text-center pb-4">
                Sube tu comprobante de pago para la orden #
                {fileUploadForOrder.id} del evento{" "}
                {fileUploadForOrder.event.name}
              </h1>
              <div className="flex flex-col gap-2">
                <label className="lora-regular text-lg mb-1 text-tertiary">
                  Selecciona un archivo
                </label>
                <input
                  className="border  mb-2 p-2 text-tertiary"
                  type="file"
                  disabled={isUploadingProofOfPayment}
                  onChange={(e) => {
                    if (e.target.files) {
                      setFile(e.target.files[0]);
                    }
                  }}
                />
                {file ? (
                  <button
                    className="flex flex-row  p-2 bg-secondary justify-center"
                    onClick={uploadProofOfPayment}
                    disabled={!file || isUploadingProofOfPayment}
                  >
                    <div>
                      <p className="lora-bold text-primary text-xl">Upload</p>
                    </div>
                    <FaFileUpload size={20} />
                  </button>
                ) : null}

                <button
                  className="lora-bold text-xl bg-tertiary text-primary  p-2"
                  onClick={() => setFileUploadForOrder(null)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : null}
        <div className="flex flex-col p-2">
          {userOrders.map((order) => (
            <div
              key={order.id}
              className={
                "flex flex-col md:flex-row border-primary border-2 justify-between p-4 mb-4 sh shadow-md"
              }
            >
              <div id="order-details" className="mr-2 w-full">
                
                <p className="lora-regular text-md mb-2">
                  Evento: <span className="lora-bold uppercase">{order.event.name}</span>
                </p>
                <p className="lora-regular text-md mb-2">
                  Fecha:
                  <span className="lora-bold ml-2 ">{order.event.eventDate}</span>
                </p>
                <div className="flex flex-row items-center mb-4">
                  <p className="lora-regular text-md">{`Estado: `}</p>
                  <span
                    className={
                      `lora-bold text-md ml-2 text-center ${order.status === "CANCELADA" ? "text-red-500" : null} ${order.status === "APROBADA" ? "text-green-500" : null} ${order.status === "EN APROBACIÓN" || order.status === "EN VALIDACIÓN" ? "text-orange-500" : null} ${order.status === "APROBACIÓN" ? "text-blue-500" : null}`
                    }
                  >
                    {order.status}
                  </span>
                </div>

                <Link to={`/order/?id=${order.id}`}>
                  <p className="p-4 flex flex-row items-center justify-center shadow-md mb-4 bg-secondary h-[62px]">
                    <span className="uppercase lora-bold text-center">
                      Ver mas detalles
                    </span>
                  </p>
                </Link>

                {order.status === "APROBADA" ? (
                  <Link to={`/order-tickets/?name=${order.event.customUrl}&eventId=${order.event.id}`}>
                    <p className="p-4 flex flex-row items-center justify-center shadow-md mb-4 bg-secondary h-[62px]">
                      <span className="uppercase lora-bold text-center">
                        Ver mis entradas
                      </span>
                    </p>
                  </Link>
                ) : null}
                {order.status === "EN VALIDACIÓN" ? (
                <div className="flex flex-col justify-center w-full">
                  <button
                    onClick={() => openProofOfPaymentModalOpen(order)}
                    className="bg-secondary p-4 my-2  min-w-[270px] shadow-md"
                  >
                    <div className="flex flex-row items-center justify-center">
                      <FaFileUpload size={30} />
                      <p className="ml-2 lora-bold uppercase">
                        Adjuntar comprobante de pago
                      </p>
                    </div>
                  </button>

                  <div className="flex flex-col">
                    <p className="text-center lora-bold">
                      Tiempo restante para adjuntar tu comprobante de pago:{" "}
                    </p>
                    <Countdown
                      creationDate={order.creationDate}
                      currentTime={currentTime}
                    />
                  </div>
                </div>
              ) : null}

              {order.status === "EN APROBACIÓN" ? (
                <div className="h-full ">
                  <p className="text-wrap text-center">
                    Tan pronto el comprobante sea validado recibiras un correo
                    con informacion actualizada sobre tu orden
                  </p>
                </div>
              ) : null}
              </div>
              
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

// Countdown Component
const Countdown: React.FC<{ creationDate: string; currentTime: Date }> = ({
  creationDate,
  currentTime
}) => {
  const timeLeft = timeLeftSince(creationDate, currentTime);

  if (!timeLeft) {
    return <p className="lora-bold text-center text-red-600">Se acabo el tiempo</p>;
  }

  const { minutes, seconds } = timeLeft;

  if (minutes < 0 || seconds < 0) {
    return (
      <p className="lora-bold text-center text-red-600">
        <p className="lora-bold text-center text-red-600">Se acabo el tiempo</p>
      </p>
    );
  }

  return (
    <p className="lora-bold text-center text-red-600">
      {minutes}:{seconds.toString().padStart(2, "0")}
    </p>
  );
};
