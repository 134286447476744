import { baseUrl } from "../constants/enviroment";
import { GetAllTicketsPayload, GetAllTicketsResponse } from "../types/OrderTickets";
import { getCookie } from "../utils/getCookie";

export const getAllUserTickets = async (
  params: GetAllTicketsPayload
): Promise<GetAllTicketsResponse> => {
  const jwtFromCookie = getCookie("jwt");
  
  const queryParams = new URLSearchParams({
    eventId: params.eventId?.toString() || "",
    offset: params.offset?.toString() || "",
    page: params.page?.toString() || "",
    pageNumber: params.pageNumber?.toString() || "",
    pageSize: params.pageSize?.toString() || "",
    paged: params.paged?.toString() || "",
    size: params.size?.toString() || "",
    sort: params.sort?.join(",") || "",
    "sort.sorted": params.sortSorted?.toString() || "",
    "sort.unsorted": params.sortUnsorted?.toString() || "",
    unpaged: params.unpaged?.toString() || ""
  });

  try {
    const response = await fetch(`${baseUrl}/user/getAllTickets?${queryParams.toString()}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtFromCookie}`,
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
