import { useEffect, useState } from "react";
import { FaCalendarAlt, FaRegUserCircle } from "react-icons/fa";
import { FcApproval } from "react-icons/fc";
import { GiNotebook } from "react-icons/gi";
import { IoTicket } from "react-icons/io5";
import { MdAdd, MdEvent } from "react-icons/md";
import { getAllPublishersOrders } from "../../api/getAllPublisherOrders";
import { Link } from "../../components/router/Link";
import Pagination from "../../components/utils/Pagination";
import { useToast } from "../../context/ToastContext";
import { useAppContext } from "../../hooks/useAppContext";
import { useRoute } from "../../hooks/useRoute";
import {
  OrderData,
  OrderDataPayload,
  OrderDataResponse,
  OrderEventTiers
} from "../../types/OrderDataTypes";
import { removeUnderscores } from "../../utils/formatRole";
import { currencySymbol } from "../../constants/enviroment";

const AddDateIcon: React.FC = () => {
  return (
    <div className="relative inline-block">
      <FaCalendarAlt className="text-tertiary" />
      <MdAdd
        size={12}
        className="text-green-500 absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2"
      />
    </div>
  );
};

const PublisherOrders = () => {
  const { user } = useAppContext();
  const { showToast } = useToast();
  const { params } = useRoute();
  const formattedRole = removeUnderscores(user?.role);
  const [isloading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState<OrderData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(params.page || "1", 10)
  );
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para el menú de hamburguesa
  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [approvingId, setApprovingId] = useState<number | null>(null);

  const getQuanity = (orderEventTiers: OrderEventTiers[]) => {
    return orderEventTiers.reduce((total, tier) => total + tier.quantity, 0);
  };

  const getTotal = (orderEventTiers: OrderEventTiers[]): string => {
    const total = orderEventTiers.reduce(
      (sum, tier) => sum + tier.quantity * tier.price,
      0
    );
    return total.toLocaleString();
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (currentPage < 0) return; // Asegúrate de que currentPage sea válido

      const payload: OrderDataPayload = {
        offset: currentPage * 10,
        page: currentPage - 1,
        pageNumber: currentPage,
        pageSize: 10,
        paged: true,
        size: pageSize,
        sort: "id,asc"
      };

      try {
        setIsLoading(true);
        const response: OrderDataResponse =
          await getAllPublishersOrders(payload);
        setOrders(response.data.content);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      } catch (error) {
        showToast("Ha ocurrido un error al cargar las ordenes", "error");
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [currentPage, showToast, pageSize]);

  return (
    <div className="flex flex-col">
      {/* Contenedor principal */}
      <div className="w-full flex flex-col md:flex-row px-4 py-8">
        {/* Contenido principal */}
        <div className="flex flex-col w-full pl-4">
          <div className="bg-primary w-fit px-4 py-2 ">
            <h1 className="text-secondary">Historial de ordenes</h1>
          </div>
          <hr className="border-t-2 border-gray-300 my-8" />
          <div>
            <div className="hidden sm:flex flex-row py-2 font-bold text-md px-4">
              <div className="w-[80%] text-left">Orden</div>
              <div className="w-[70%]">Evento</div>
              <div className="w-[80%]">Fecha de recepción</div>
              <div className="w-[80%]">Status</div>
              <div className="w-[80%]">Cantidad</div>
              <div className="w-[80%]">Total</div>
            </div>
            <div className="flex flex-col w-full h-full gap-2">
              {orders.map((order) => (
                <div
                  key={order.id}
                  className="flex flex-col sm:flex-row w-full h-full py-2 px-4  bg-primary text-secondary shadow-md shadow-black items-center"
                >
                  <div className="sm:w-[80%] w-full flex gap-1">
                    <span className="sm:hidden font-bold">Orden:</span>#
                    {order.id}
                  </div>
                  <div className="sm:w-[70%] w-full flex gap-1">
                    <span className="sm:hidden font-bold">Evento:</span>
                    {order.event.name || "N/A"}
                  </div>
                  <div className="sm:w-[80%] w-full flex gap-1">
                    <span className="sm:hidden font-bold">
                      Fecha de recepción:
                    </span>
                    {order.creationDate || "N/A"}
                  </div>
                  <div className="sm:w-[80%] w-full flex gap-1">
                    <span className="sm:hidden font-bold">Status:</span>
                    {order.status}
                  </div>
                  <div className="sm:w-[80%] w-full flex gap-1">
                    <span className="sm:hidden font-bold">Cantidad:</span>
                    {getQuanity(order.orderEventTiers)}
                  </div>
                  <div className="sm:w-[80%] w-full flex gap-1">
                    <span className="sm:hidden font-bold">Total:</span>
                    {currencySymbol}
                    {getTotal(order.orderEventTiers)}
                  </div>
                </div>
              ))}
              <div className="flex justify-end mt-4 mb-4">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublisherOrders;
