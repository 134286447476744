type ResizeConfig = {
  maxSizeKb: number;
  width: number;
  height: number;
  checkSquare?: boolean; // Optional flag to check if the image is square
  outputFormat?: "image/webp" | "image/jpeg" | "image/png"; // Option to specify output format
};

export function resizeImage(
  file: File,
  config: ResizeConfig,
): Promise<File | string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      const img = new Image();

      img.onload = function () {
        // Check if the image is square, if required
        if (config.checkSquare && img.width !== img.height) {
          reject("Image is not square.");
          return;
        }

        // Create an off-screen canvas for resizing
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          reject("Failed to get canvas context.");
          return;
        }

        // Set canvas dimensions to the desired width and height
        canvas.width = config.width;
        canvas.height = config.height;

        // Draw the image onto the canvas with the new size
        ctx.drawImage(img, 0, 0, config.width, config.height);

        // Convert the canvas data back to a Blob in the specified format (default WebP)
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject("Image conversion to Blob failed.");
              return;
            }

            // Check if the image size is under the max size
            const sizeInKb = blob.size / 1024;
            if (sizeInKb > config.maxSizeKb) {
              reject("Resized image exceeds the maximum file size.");
              return;
            }

            // Create a new file from the blob
            const resizedFile = new File(
              [blob],
              file.name.replace(/\.\w+$/, ".jpg"),
              {
                type: config.outputFormat || "image/jpeg", // Default to WebP
                lastModified: Date.now(),
              },
            );

            resolve(resizedFile);
          },
          config.outputFormat || "image/jpeg", // Default to WebP
          0.9, // Image quality (adjustable)
        );
      };

      img.onerror = function () {
        reject("Failed to load the image.");
      };

      // Load the image from the file input
      img.src = event.target?.result as string;
    };

    reader.onerror = function () {
      reject("Failed to read the file.");
    };

    // Read the image file
    reader.readAsDataURL(file);
  });
}
