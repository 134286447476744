import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

interface TipTapShowProps {
  htmlContent: string;
}
const TipTapShow = ({ htmlContent }: TipTapShowProps) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content: htmlContent, // Aquí pasamos el HTML como contenido inicial
    editable: false
  });

  if (!editor) {
    return null;
  }

  return (
    <div className="tiptap">
      <EditorContent editor={editor} />
    </div>
  );
};

export default TipTapShow;
