import { baseUrl } from "../constants/enviroment";
import {
  UpdateEventTierPayload,
  UpdateEventTierResponse,
} from "../types/UpdateEventTierTypes";
import { getCookie } from "../utils/getCookie";

export const updatePublisherTiers = async (
  data: UpdateEventTierPayload,
): Promise<UpdateEventTierResponse> => {
  const jwtFromCookie = getCookie("jwt");

  try {
    const response = await fetch(`${baseUrl}/publisherAdmin/updateEventTier`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${jwtFromCookie}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
