import { baseUrl } from "../constants/enviroment";
import {
  UpdatePasswordTemporalPayload,
  UpdatePasswordTemporalResponse,
} from "../types/UpdateTemporaryPassword";
import { getCookie } from "../utils/getCookie";

export const updatePasswordTemporal = async (
  data: UpdatePasswordTemporalPayload,
): Promise<UpdatePasswordTemporalResponse> => {
  const jwtFromCookie = getCookie("jwt");
  try {
    const response = await fetch(`${baseUrl}/user/updatePasswordFromTemporal`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${jwtFromCookie}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
