import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useState } from "react";
import facebookIcon from "../../assets/facebookIcon.svg";
import googleIcon from "../../assets/googleIcon.svg";
import GoogleLoginButton from "../../components/GoogleLoginButton";
import { Layout } from "../../components/Layout";
import { Link } from "../../components/router/Link";
import { appName } from "../../constants/enviroment";
import { useAppContext } from "../../hooks/useAppContext";
import { isValidEmail } from "../../utils/isValidEmail";
import { useTranslation } from "react-i18next";

export const Login = () => {
  const { isAuthenticated, login, isLoading, authError } = useAppContext();
  const { t } = useTranslation("global");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const validatePassword = (password: string) => {
    return password.length >= 3;
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setIsEmailValid(isValidEmail(emailValue));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);
    setIsPasswordValid(validatePassword(passwordValue));
  };

  const handleLogin = () => {
    login({ email, password }, "/");
  };

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const isFormValid = isEmailValid && isPasswordValid;

  if (isAuthenticated) {
    return (
      <Layout>
        <div
          id="login-module"
          className="flex flex-col bg-primary w-full md:w-[450px] p-2 md:m-auto"
        >
          <div id="login-headers" className="mb-4">
            <h1 className="text-center text-bold text-xl text-tertiary">
              Bienvenido a <span className="text-secondary">{appName}</span>
            </h1>
          </div>
          <div id="email-login">
            <form className="flex flex-col">
              <label className="text-lg mb-1 text-tertiary">
                Email<sup className="text-red-500">*</sup>
              </label>
              <input
                className="border ronded ronded-2 mb-2 p-2 text-primary"
                type="text"
                disabled={isLoading}
                value={email}
                onChange={handleEmailChange}
              />
              <label className="text-lg mb-1 text-tertiary">
                Password<sup className="text-red-500">*</sup>
              </label>
              <input
                className="border ronded ronded-2 text-lg mb-2 p-2 text-primary"
                type="password"
                disabled={isLoading}
                value={password}
                onChange={handlePasswordChange}
                onKeyDown={handleKeyDown}
              />
              <button
                className={`lora-regular uppercase text-lg mt-4 p-2 text-primary ${isFormValid && !isLoading ? "bg-secondary " : "bg-secondary opacity-30 cursor-not-allowed"}`}
                type="button"
                onClick={handleLogin}
                disabled={!isFormValid || isLoading}
                title={
                  !isFormValid || isLoading
                    ? "Completa el formulario"
                    : "Ingresar"
                }
              >
                {isLoading ? "Ingresando..." : "Ingresar"}
              </button>
              <Link to={`/register`}>
                <p className="lora-regular uppercase text-lg mt-4 p-2 text-primary bg-secondary text-center">
                  Registrarse
                </p>
              </Link>
              <div className="mt-2">
                {authError ? (
                  <p className="text-red-500 ">{authError}</p>
                ) : null}
              </div>
            </form>
          </div>
          {/* <div id="social-login" className="mt-4">
            <h3 className="text-lg text-tertiary text-center">
              Ingreso con Redes Sociales
            </h3>
            <div className="flex flex-row justify-around mt-2">
              <img src={googleIcon} alt="google icon" className="w-8 h-8" />
              <img src={facebookIcon} alt="facebook icon" className="w-8 h-8" />
            </div>
          </div> */}
        </div>
      </Layout>
    );
  }

  return (
    <GoogleOAuthProvider clientId={`${clientId}`}>
      <Layout>
        <div
          id="login-module"
          className="flex flex-col bg-primary w-full md:w-[450px] p-2 md:m-auto"
        >
          <div id="login-headers" className="mb-4">
            <h1 className="text-center text-bold text-xl text-tertiary">
              Bienvenido a <span className="text-secondary">{appName}</span>
            </h1>
          </div>
          <div id="email-login">
            <form className="flex flex-col">
              <label className="text-lg mb-1 text-tertiary">Email</label>
              <input
                className="border ronded ronded-2 mb-2 p-2 text-primary"
                type="text"
                disabled={isLoading}
                value={email}
                onChange={handleEmailChange}
              />
              <label className="text-lg mb-1 text-tertiary">Password</label>
              <input
                className="border ronded ronded-2 text-lg mb-2 p-2 text-primary"
                type="password"
                disabled={isLoading}
                value={password}
                onChange={handlePasswordChange}
                onKeyDown={handleKeyDown}
              />
              <button
                className={`text-lg mt-4 p-2 text-primary uppercase ${isFormValid && !isLoading ? "bg-secondary " : "bg-secondary opacity-30 cursor-not-allowed"}`}
                type="button"
                onClick={handleLogin}
                disabled={!isFormValid || isLoading}
                title={
                  !isFormValid || isLoading
                    ? "Completa el formulario"
                    : "Ingresar"
                }
              >
                {isLoading ? "Ingresando..." : "Ingresar"}
              </button>
              <Link to={`/register`}>
                <p className="text-lg uppercase mt-4 p-2 text-primary bg-secondary text-center">
                  Registrarse
                </p>
              </Link>
              <Link to={`/forgot-password`}>
                <p className="text-lg uppercase mt-4 p-2 text-primary bg-secondary text-center">
                  Olvide mi contraseña
                </p>
              </Link>
              <div className="mt-2">
                {authError ? (
                  <p className="text-red-500 ">{authError}</p>
                ) : null}
              </div>
            </form>
            <div id="social-login" className="mt-4">
              {/* [ST-5]Temporarily comment out Google login button  */}
             {/*  <div className="flex flex-row justify-around mt-2">
                <GoogleLoginButton path="/" />
              </div> */}
            </div>
            <p className="text-tertiary mt-4">
              {t("auth.by-using-our-services")}{" "}
              <Link to="/terms-and-conditions">
                <span className="text-secondary cursor-pointer">
                  {t("auth.terms-and-conditions")}
                </span>
              </Link>
            </p>
          </div>
        </div>
      </Layout>
    </GoogleOAuthProvider>
  );
};
