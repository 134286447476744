import { baseUrl } from "../constants/enviroment";
import { GetTicketInformationResponse } from "../types/GetTicketInformation";
import { getCookie } from "../utils/getCookie";

export const getTicketInformation = async (
  id: number,
): Promise<GetTicketInformationResponse> => {
  try {
    const jwtFromCookie = getCookie("jwt");
    const response = await fetch(
      `${baseUrl}/bouncer/getTicketInformation/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtFromCookie}`,
        },
      },
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};

export default getTicketInformation;
