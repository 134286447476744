// Toast.tsx
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

type ToastType = "error" | "success" | "info";

// Definir el tipo para las propiedades del componente Toast
interface ToastProps {
  message: string;
  show: boolean;
  duration: number;
  type?: ToastType;
}

const Toast: React.FC<ToastProps> = ({ message, show, duration, type }) => {
  const [progress, setProgress] = useState(100);
  const [isVisible, setIsVisible] = useState(show);

  const getColorClasses = () => {
    switch (type) {
      case "error":
        return "bg-red-500 text-white";
      case "success":
        return "bg-green-500 text-white";
      case "info":
        return "bg-blue-500 text-white";
      default:
        return "bg-gray-500 text-white";
    }
  };
  const progressBarColor = () => {
    switch (type) {
      case "error":
        return "bg-red-700";
      case "success":
        return "bg-green-700";
      case "info":
        return "bg-blue-700";
      default:
        return "bg-gray-700";
    }
  };
  useEffect(() => {
    if (show) {
      setIsVisible(true);
      setProgress(100);
      const interval = setInterval(() => {
        setProgress((prev) => prev - 100 / (duration / 100));
      }, 100);

      setTimeout(() => {
        clearInterval(interval);
        setIsVisible(false);
      }, duration);

      return () => clearInterval(interval);
    } else {
      setProgress(100); // Reset the progress when the toast is hidden
    }
  }, [show, duration]);

  return ReactDOM.createPortal(
    <div
      className={`fixed top-4 right-4 ${getColorClasses()} shadow-md z-50 transition-opacity duration-500 transform ${
        isVisible
          ? "opacity-100 translate-y-0 px-4 py-2"
          : "opacity-0 translate-y-[-10px]"
      }`}
    >
      <div className="flex items-center">
        <span className="flex-1">{message}</span>
      </div>
      <div className="h-1 bg-white mt-2 ">
        <div
          className={`h-full transition-all duration-300 ${progressBarColor()}`}
          style={{
            width: `${progress}%`
          }}
        ></div>
      </div>
    </div>,
    document.body
  );
};

export default Toast;
