// /guest/getEventDetails/{id}
import { baseUrl } from "../constants/enviroment";
import { EventDataResponse } from "../types/EventDataTypes";
import { getCookie } from "../utils/getCookie";

export const getGuestEventDetails = async (
  customUrl: number | string,
): Promise<EventDataResponse> => {
  const jwtFromCookie = getCookie("jwt");
  try {
    const response = await fetch(`${baseUrl}/guest/getEventDetails/${customUrl}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtFromCookie}`,
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (error: any) {
    return error;
  }
};
