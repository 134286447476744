import { baseUrl } from "../constants/enviroment";
import {
  GenerateTokenPayload,
  GenerateTokenReponse,
} from "../types/GenerateTokenTypes";

export const generateToken = async (
  payload: GenerateTokenPayload,
): Promise<GenerateTokenReponse> => {
  try {
    const response = await fetch(`${baseUrl}/authentication/generateToken`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
