import React from "react";
import { useRoute } from "../../hooks/useRoute";

type LinkProps = {
  to: string;
  children: React.ReactNode;
  onSuccess?: () => void;
};

export const Link: React.FC<LinkProps> = ({ to, children, onSuccess }) => {
  const { setRoute } = useRoute();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const newRoute = to;
    if (newRoute !== window.location.pathname) {
      if (onSuccess) {
        onSuccess();
      }
      window.history.pushState({}, "", newRoute);
      setRoute(newRoute);
    }
    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <a href={to} onClick={handleClick}>
      {children}
    </a>
  );
};
