import { useState } from "react";
import { registerAdmin } from "../../api/registerAdmin";
import { Layout } from "../../components/Layout";
import PublisherIdDropdown from "../../components/PublisherIdDropdown";
import { useToast } from "../../context/ToastContext";
import { isValidEmail } from "../../utils/isValidEmail";
import { isValidPhone } from "../../utils/isValidPhone";

type Role = "ROLE_ADMIN" | "ROLE_PUBLISHER_ADMIN";

const RegisterAdmin = () => {
  const { showToast } = useToast();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [username, setUsername] = useState("");

  const [role, setRole] = useState<Role | "">("");
  const [publisherId, setPublisherId] = useState<string>("");
  // State to controll the loading of the fetching of the data
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameValue = event.target.value;
    setUsername(nameValue);
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    let phoneValue = event.target.value.replace(/\D/g, "");
    if (phoneValue.length > 8) {
      phoneValue = phoneValue.slice(0, 8);
    }
    setPhone(phoneValue);
    setIsPhoneValid(isValidPhone(phoneValue));
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setIsEmailValid(isValidEmail(emailValue));
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRole(event.target.value as Role);
  };

  const handleRegister = () => {
    setIsLoading(true);
    const payload: {
      email: string;
      username: string;
      phoneNumber: string;
      role: string;
      publisherId?: number; // Opcional
    } = {
      email,
      username,
      phoneNumber: phone,
      role
    };
    if (publisherId !== null) {
      payload.publisherId = parseInt(publisherId);
    }
    registerAdmin(payload)
      .then(() => {
        setIsLoading(false);
        showToast("Registrado correctamente", "success");
      })
      .catch((error) => {
        setIsLoading(false);
        showToast(error.message, "error");
      });
  };

  const isFormValid =
    isEmailValid &&
    isPhoneValid &&
    username.length > 0 &&
    role !== "" &&
    (role === "ROLE_ADMIN" || publisherId !== "");

  return (
    <Layout>
      <div
        id="register-module"
        className="flex flex-col bg-primary w-full md:w-[450px] p-2 md:m-auto"
      >
        <div id="register-headers" className="mb-4">
          <h1 className="text-center text-bold text-xl text-tertiary">
            Registrar <span className="text-secondary">Administradores</span>
          </h1>
        </div>
        <div id="register-form">
          <form className="flex flex-col">
            <label className="text-lg mb-1 text-tertiary">
              Nombre de usuario*
            </label>
            <input
              className="border ronded-2 mb-2 p-2 text-primary"
              type="text"
              disabled={isLoading}
              value={username}
              onChange={handleChangeName}
            />
            <label className="text-lg mb-1 text-tertiary">Email *</label>
            <input
              className="border ronded-2 mb-2 p-2 text-primary"
              type="text"
              disabled={isLoading}
              value={email}
              onChange={handleEmailChange}
            />
            <label className="text-lg mb-1 text-tertiary">Teléfono *</label>
            <input
              className="border ronded-2 mb-2 p-2 text-primary"
              type="text"
              disabled={isLoading}
              value={phone}
              onChange={handleChangePhone}
            />

            <label className="text-lg mb-1 text-tertiary">
              Seleccionar Rol *
            </label>
            <select
              className="border rounded-2 mb-2 p-2 text-primary"
              value={role}
              onChange={handleRoleChange}
              disabled={isLoading}
            >
              <option value="" disabled>
                Seleccione un rol
              </option>
              <option value="ROLE_ADMIN">ROL ADMIN</option>
              <option value="ROLE_PUBLISHER_ADMIN">ROLE PUBLISHER ADMIN</option>
            </select>

            {role !== "ROLE_ADMIN" && role !== "" && (
              <>
                <PublisherIdDropdown
                  publisherId={publisherId}
                  setPublisherId={setPublisherId}
                />
                {!publisherId && (
                  <p className="text-red-500">
                    {"Por favor seleccione el publisher"}
                  </p>
                )}
              </>
            )}
          </form>

          <button
            className={`w-full text-lg p-2 mt-4 text-primary ${isFormValid && !isLoading ? "bg-secondary " : "bg-gray-200 cursor-not-allowed"}`}
            type="button"
            onClick={handleRegister}
            disabled={!isFormValid || isLoading}
          >
            {isLoading ? "Registrando..." : "Registrar"}
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default RegisterAdmin;
