import { useEffect, useState } from "react";
import { getAllOrdersForApproval } from "../../api/getAllOrdersForApproval";
import { OrderApprovalContent } from "../../types/OrderApprovalTypes";
import { approveOrder } from "../../api/approveOrder";
import { cancelOrder } from "../../api/cancelOrder";
import { useToast } from "../../context/ToastContext";
import { currencySymbol } from "../../constants/enviroment";

export const OrdersPendingApproval = () => {
  const { showToast } = useToast();

  const [orders, setOrders] = useState<OrderApprovalContent[]>([]);
  const [approveId, setApproveId] = useState<number | null>(null);
  const [cancellationMessage, setCancellationMessage] = useState("");
  const [showCancelForm, setShowCancelForm] = useState(false);

  const fetchOrders = async () => {
    try {
      const orders = await getAllOrdersForApproval({});
      setOrders(orders.data.content);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (approveId === null) {
      fetchOrders();
    }
  }, [approveId]);

  const handleApprove = async () => {
    try {
      if (approveId === null) return;
      await approveOrder(approveId);
      showToast("Orden Aprobada", "success");
    } catch (error) {
      showToast("Error al aprobar la orden", "error");
    } finally {
      setApproveId(null);
    }
  };

  const handleCancel = async () => {
    try {
      if (approveId === null) return;
      await cancelOrder(approveId, { notes: cancellationMessage });
      showToast("Orden Cancelada", "success");
      setApproveId(null);
    } catch (error) {
      showToast("Error al cancelar la orden", "error");
    } finally {
      setApproveId(null);
    }
  };

  return (
    <div id="orders-pending-for-approval" className="flex flex-col p-2">
      <h1 className="text-xl lora-bold">Comprobantes por validar</h1>

      <div className="mt-4">
        {orders
          ? orders.map((order) => (
              <div
                key={order.id}
                className="border-2 border-primary p-2 mb-2 flex justify-between lora-regular"
              >
                <div className="">
                  <p>
                    Evento:{" "}
                    <span className="lora-bold">{order.event.name}</span>
                  </p>
                  <p>
                    Numero de orden{" "}
                    <span className="lora-bold">#{order.id}</span>
                  </p>
                  {/* <p>{order.cardTransaction}</p> */}
                  {/* <p>{order.creationDate}</p> */}
                  {/* <p>{order.status}</p> */}
                  <p>
                    Monto:{" "}
                    <span className="lora-bold">
                      {currencySymbol} {order.totalPrice}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col justify-center">
                  <button
                    className="bg-secondary p-4"
                    onClick={() => setApproveId(order.id)}
                  >
                    Validar
                  </button>
                  {approveId === order.id ? (
                    <div className="bg-tertiary p-2 mb-[200px] top-[96px] h-[86vh] w-full left-0 absolute">
                      <img
                        className="m-auto max-h-[500px] max-w-[500px]"
                        src={order.imageSinpeUrl}
                        alt="order proof of payment"
                      />
                      {!showCancelForm ? (
                        <>
                          <button
                            className="w-full lora-bold p-4 bg-green-500 text-white"
                            onClick={() => handleApprove()}
                          >
                            Aprobar Comprobante
                          </button>
                          <button
                            className="w-full lora-bold p-4 bg-red-500 text-white"
                            onClick={() => {
                              setShowCancelForm(true);
                            }}
                          >
                            Rechazar Comprobante y Cancelar Orden
                          </button>
                          <button
                            className="w-full lora-bold p-4 bg-secondary text-white"
                            onClick={() => {
                              setApproveId(null);
                              setShowCancelForm(false);
                            }}
                          >
                            Volver al inicio
                          </button>
                        </>
                      ) : null}

                      {showCancelForm ? (
                        <div className="flex flex-col gap-2">
                          <textarea
                            className="border-2 border-primary p-2"
                            value={cancellationMessage}
                            onChange={(e) =>
                              setCancellationMessage(e.target.value)
                            }
                            placeholder="Motivo de cancelación"
                          />
                          <button
                            className="bg-red-500 p-2 text-white"
                            onClick={() => handleCancel()}
                          >
                            Cancelar
                          </button>
                          <button
                            className="bg-secondary p-2 text-white"
                            onClick={() => setShowCancelForm(false)}
                          >
                            Volver
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};
