import { baseUrl } from "../constants/enviroment";
import {
  RegisterEventPayload,
  RegisterEventResponse,
  RegisterEventTierResponse,
  RegisterEventTierPayload,
} from "../types/RegisterEventTypes";
import { getCookie } from "../utils/getCookie";

export const registerEvent = async (
  data: RegisterEventPayload,
): Promise<RegisterEventResponse> => {
  try {
    const jwtFromCookie = getCookie("jwt");
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("customUrl", data.customUrl);
    formData.append("description", data.description);
    formData.append("location", data.location);
    formData.append("startDate", data.startDate);
    formData.append("endDate", data.endDate);
    formData.append("eventDate", data.eventDate);
    formData.append("publisherId", data.publisherId);
    formData.append("file", data.file);
    const response = await fetch(`${baseUrl}/publisherAdmin/registerEvent`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${jwtFromCookie}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};

export const registerEventTier = async (
  data: RegisterEventTierPayload,
): Promise<RegisterEventTierResponse> => {
  try {
    const jwtFromCookie = getCookie("jwt");
    const response = await fetch(
      `${baseUrl}/publisherAdmin/registerEventTier`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${jwtFromCookie}`,
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
