import { useEffect, useState } from "react";
import { FaFileUpload } from "react-icons/fa";
import { IoAlertCircle } from "react-icons/io5";
import { getAllUserOrders } from "../../api/getAllUserOrders";
import { updateSinpeValidation } from "../../api/updateSinpeValidation";
import { Layout } from "../../components/Layout";
import { Link } from "../../components/router/Link";
import TipTapShow from "../../components/TipTapShow";
import { useToast } from "../../context/ToastContext";
import { OrderData } from "../../types/OrderDataTypes";
import { timeLeftSince } from "../../utils/formatDate";
import { useTranslation } from "react-i18next";
import { currencySymbol } from "../../constants/enviroment";

export const Order = () => {
  const { showToast } = useToast();
  const { t } = useTranslation("global");

  const [idFromParams] = useState<string | null>(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("id");
  });

  const [order, setOrder] = useState<OrderData | null>(null);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  const fetchOrderData = async () => {
    if (!idFromParams) return;
    const orderData = await getAllUserOrders({ id: idFromParams });
    if (!orderData.data.content.length) {
      // tost error
    }
    const order = orderData.data.content.find(
      (order) => order.id === Number(idFromParams)
    );
    if (!order) {
      // toast error
      return;
    }
    setOrder(order);
  };
  const [isProofOfPaymentModalOpen, setIsProofOfPaymentModalOpen] =
    useState<boolean>(false);
  const [isUploadingProofOfPayment, setIsUploadingProofOfPayment] =
    useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  useEffect(() => {
    if (!idFromParams) return;
    if (idFromParams === "") return;
    fetchOrderData();
  }, [idFromParams]);

  // Update currentTime every second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const uploadProofOfPayment = async () => {
    setIsUploadingProofOfPayment(true);
    try {
      const formData = new FormData();
      formData.append("file", file as Blob);
      const validation = await updateSinpeValidation({
        id: order?.id as number,
        file: file as File
      });
      // show toast with success message
      // close modal
      setFile(undefined);
      setIsProofOfPaymentModalOpen(false);
      fetchOrderData();
    } catch (error: any) {
      showToast(error.message, "error");
    } finally {
      setIsUploadingProofOfPayment(false);
    }
  };

  if (!order) return <p>Loading...</p>;

  return (
    <Layout>
      <div className="w-auto md:w-1/2 m-auto">
        {isProofOfPaymentModalOpen ? (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-primary w-[400px] p-4">
              <h1 className="lora-bold text-tertiary text-xl text-center pb-4">
                Sube tu comprobante de pago para la orden #{order.id} del evento{" "}
                {order.event.name}
              </h1>
              <div className="flex flex-col gap-2">
                <label className="lora-regular text-lg mb-1 text-tertiary">
                  Selecciona un archivo, solo se aceptan archivos tipo png o
                  jpeg
                </label>
                <input
                  className="border  mb-2 p-2 text-tertiary"
                  type="file"
                  disabled={isUploadingProofOfPayment}
                  onChange={(e) => {
                    if (e.target.files) {
                      setFile(e.target.files[0]);
                    }
                  }}
                />
                {file ? (
                  <button
                    className="flex flex-row  p-2 bg-secondary justify-center"
                    onClick={uploadProofOfPayment}
                    disabled={!file || isUploadingProofOfPayment}
                  >
                    <div>
                      <p className="lora-bold text-primary text-xl">
                        {isUploadingProofOfPayment ? "Uploading..." : "Upload"}
                      </p>
                    </div>
                    <FaFileUpload size={20} />
                  </button>
                ) : null}

                <button
                  disabled={isUploadingProofOfPayment}
                  className="lora-bold text-xl bg-tertiary text-primary p-2"
                  onClick={() => setIsProofOfPaymentModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : null}
        <div id="order-actions" className="flex flex-col">
          <div id="order-details" className="mr-2">
            <p className="lora-regular text-xl mb-2">
              Orden <span className="lora-bold">#{order.id}</span>
            </p>

            {order.status === "EN VALIDACIÓN" ? (
              <div className="flex flex-col justify-center h-full">
                <p className="lora-regular">{t("orders.proof-of-payment-1")}</p>
                <p className="lora-regular">
                  <TipTapShow
                    htmlContent={order.event.publisher.bankInformation}
                  />
                </p>
                <p className="lora-regular">{t("orders.proof-of-payment-2")}</p>
                <button
                  onClick={() => setIsProofOfPaymentModalOpen(true)}
                  className="bg-secondary p-4 my-2  min-w-[270px] shadow-md"
                >
                  <div className="flex flex-row items-center justify-center">
                    <FaFileUpload size={30} />
                    <p className="ml-2 lora-bold uppercase">
                      Selecciona tu comprobante de pago
                    </p>
                  </div>
                </button>

                <div className="flex flex-col">
                  <p className="text-center lora-bold">
                    Tiempo restante para adjuntar tu comprobante de pago:{" "}
                  </p>
                  <Countdown
                    creationDate={order.creationDate}
                    currentTime={currentTime}
                  />
                </div>
              </div>
            ) : null}

            <p className="lora-regular text-md mb-2">
              Evento: <span className="lora-bold">{order.event.name}</span>
            </p>
            <p className="lora-regular text-md mb-2">
              Fecha: <span className="lora-bold">{order.event.eventDate}</span>
            </p>
            {/* <div className="flex flex-row items-center mb-4 md:mb-0">
              <p className="lora-regular text-md">Estado:</p>
              <span
                className={`lora-bold text-md text-center ${order.status === "CANCELADA" ? "text-red-500" : null} ${order.status === "APROBADA" ? "text-green-500" : null} ${order.status === "VALIDACIÓN" ? "text-secondary" : null}`}
              >
                {order.status === "APROBACIÓN" ? "Validando Comprobante" : null}
                {order.status === "CANCELADA" ? "Cancelada" : null}
                {order.status === "VALIDACIÓN" ? "Esperando comprobante de pago" : null}
              </span>
            </div> */}
          </div>

          {order.status === "CANCELADA" ? (
            <div className="mt-2">
              <div className="flex flex-row items-center mb-4 md:mb-0">
                <p className="lora-bold text-lg text-center text-red-500">
                  Tu orden ha sido cancelada Cancelada
                </p>
              </div>
              <p>Motivo de la cancelacion:</p>
              <p className="lora-bold">{order.notes}</p>
              <p>
                Para mas informacion comunicate con la productora{" "}
                <a
                  className="lora-bold hover:pointer"
                  href={`tel:${order.event.publisher.phoneNumber}`}
                >
                  llamando al numero de telefono{" "}
                  {order.event.publisher.phoneNumber}
                </a>{" "}
                o por{" "}
                <a
                  className="lora-bold hover:pointer"
                  aria-label="Chat WhatsApp"
                  href={`https://wa.me/${order.event.publisher.phoneNumber}`}
                >
                  WhatsApp
                </a>
              </p>
            </div>
          ) : null}

          {order.status === "APROBACIÓN" ? (
            <div className="h-full">
              <div className="flex flex-row items-center mb-4">
                <IoAlertCircle size={30} />
                <p className="uppercase lora-bold p-2 text-center">
                  Estamos validando tu Comprobante
                </p>
              </div>
              <p className="text-wrap">
                Tan pronto el comprobante sea validado por la productora
                recibiras un correo con informacion actualizada sobre tu orden,
                gracias por tu paciencia.
              </p>
            </div>
          ) : null}

          {order.status === "APROBADA" ? (
            <Link
              to={`/order-tickets/?eventId=${order.event.id}&name=${order.event.customUrl}`}
            >
              <p className="uppercase text-center lora-bold bg-secondary my-2 p-4 w-full shadow-md">
                Ver mis entradas
              </p>
            </Link>
          ) : null}

          <div className="flex flex-col lora-regular">
            <p className="text-xl mt-4 mb-2">Detalles de la orden</p>
            <div className="flex flex-row justify-between">
              <p className="text-md lora-bold">Entradas</p>
              <p className="text-md lora-bold">Cantidad x Precio</p>
            </div>
            {order.orderEventTiers.map((tier) => (
              <div
                key={tier.id}
                className="flex flex-row justify-between border-b-2 border-b-primary"
              >
                <p className="text-md">{tier.name}</p>
                <p className="text-md">
                  {tier.quantity} x {currencySymbol}{" "}
                  {tier.price.toLocaleString()}
                </p>
              </div>
            ))}
            <div className="flex flex-row justify-between">
              <p className="lora-bold text-md mb-2">Total</p>
              <p className="lora-regular text-md">
                {currencySymbol} {order.totalPrice.toLocaleString()}
              </p>
            </div>
          </div>
          <div>
            {order.imageSinpeUrl ? (
              <div>
                <p className="lora-bold text-md mb-2">Comprobante de Pago</p>
                <img
                  src={order.imageSinpeUrl}
                  alt="comprobante de pago"
                  className="w-80 h-80"
                />
              </div>
            ) : null}
          </div>
          <Link to={`/orders`}>
            <p className="lora-bold shadow-md uppercase mt-2 mb-2 text-center p-4 bg-secondary">
              Ver mis ordenes
            </p>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

// Countdown Component
const Countdown: React.FC<{ creationDate: string; currentTime: Date }> = ({
  creationDate,
  currentTime
}) => {
  const timeLeft = timeLeftSince(creationDate, currentTime);

  if (!timeLeft) {
    return (
      <p className="lora-bold text-center text-red-600">Se acabo el tiempo</p>
    );
  }

  const { minutes, seconds } = timeLeft;

  if (minutes < 0 || seconds < 0) {
    return (
      <p className="lora-bold text-center text-red-600">
        <p className="lora-bold text-center text-red-600">Se acabo el tiempo</p>
      </p>
    );
  }

  return (
    <p className="lora-bold text-center text-red-600">
      {minutes}:{seconds.toString().padStart(2, "0")}
    </p>
  );
};
