import { baseUrl } from "../constants/enviroment";
import {
  ForgotPasswordPayload,
  ForgotPasswordResponse,
} from "../types/ForgotPassword";

export const forgotPassword = async (
  data: ForgotPasswordPayload,
): Promise<ForgotPasswordResponse> => {
  try {
    const response = await fetch(`${baseUrl}/authentication/forgotPassword`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
