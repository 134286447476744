import { baseUrl } from "../constants/enviroment";
import {
  UpdateSinpeValidationPayload,
  UpdateSinpeValidationResponse,
} from "../types/SinpeValidation";
import { getCookie } from "../utils/getCookie";

export const updateSinpeValidation = async (
  data: UpdateSinpeValidationPayload,
): Promise<UpdateSinpeValidationResponse> => {
  const jwtFromCookie = getCookie("jwt");
  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("id", data.id.toString());

  try {
    const response = await fetch(`${baseUrl}/user/updateSinpeValidation`, {
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${jwtFromCookie}`,
        Accept: "*/*",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
