import { baseUrl } from "../constants/enviroment";
import { getAllPublisherOptionsReponse } from "../types/GetAllPublishers";
import { getCookie } from "../utils/getCookie";

export const getAllPublisherOptions =
  async (): Promise<getAllPublisherOptionsReponse> => {
    try {
      const jwtFromCookie = getCookie("jwt");
      const response = await fetch(
        `${baseUrl}/publisherAdmin/getPublisherOptions`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtFromCookie}`,
          },
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`${errorData.message}`);
      }

      return await response.json();
    } catch (error: any) {
      throw error;
    }
  };
