import { baseUrl } from "../constants/enviroment";
import {
  GetAllEventsPayload,
  GetAllEventsResponse,
} from "../types/GetAllEventsTypes";
import { getCookie } from "../utils/getCookie";

export const getAllPublisherEvents = async (
  data: GetAllEventsPayload,
): Promise<GetAllEventsResponse> => {
  const jwtFromCookie = getCookie("jwt");
  try {
    // Convertir el objeto a URLSearchParams, manejando booleanos y números
    const queryParams = new URLSearchParams(
      Object.entries(data).reduce(
        (acc, [key, value]) => {
          acc[key] = value.toString();
          return acc;
        },
        {} as Record<string, string>,
      ),
    ).toString();

    const response = await fetch(
      `${baseUrl}/publisherAdmin/getAllEvents/?${queryParams}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtFromCookie}`,
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
