import { baseUrl } from "../constants/enviroment";
import {
  ValidateActivationCodePayload,
  ValidateActivationCodeResponse,
} from "../types/ValidateAccountTypes";

export const validateActivationCode = async (
  payload: ValidateActivationCodePayload,
): Promise<ValidateActivationCodeResponse> => {
  try {
    const response = await fetch(
      `${baseUrl}/authentication/validateActivationCode`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return await response.json();
  } catch (error: any) {
    // throw error;
    return error;
  }
};
