import React, { useEffect, useState } from "react";
import { registerEvent } from "../../api/registerEvent";
import { Layout } from "../../components/Layout";
import PublisherIdDropdown from "../../components/PublisherIdDropdown";
import { useToast } from "../../context/ToastContext";
import { useAppContext } from "../../hooks/useAppContext";
import { formatDateString } from "../../utils/formatDate";
import { resizeImage } from "../../utils/resizeImage";

const RegisterEvent: React.FC = () => {
  const { user } = useAppContext();
  const { showToast } = useToast();
  const [name, setName] = useState<string>("");
  const [customUrl, setCustomUrl] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [location, setLocation] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [eventDate, setEventDate] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [publisherId, setPublisherId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<any>(user);
  const [error, setError] = useState<boolean>(false);
  const [isResizing, setIsResizing] = useState<boolean>(false);

  const handleChange =
    (setter: React.Dispatch<React.SetStateAction<any>>) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setter(event.target.value);
    };

    const handleNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const customUrl = e.target.value
        .trim()
        .replace(/[^a-zA-Z0-9 ]/g, "") // Remove all special characters except spaces
        .replace(/ /g, "-") // Replace spaces with hyphens
        .replace(/-+/g, "-") // Replace multiple hyphens with a single one
        .toLowerCase();
    
      setName(e.target.value);
      setCustomUrl(customUrl);
    };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      // Maneja el evento Enter si es necesario
    }
  };

  const validateForm = (
    startDate: string,
    endDate: string,
    eventDate: string
  ) => {
    const currentDate = formatDateString(new Date().toISOString());

    if (
      startDate < currentDate ||
      endDate < currentDate ||
      eventDate < currentDate
    ) {
      setError(true);
      setIsLoading(false);
      showToast("Las fechas no pueden estar en el pasado", "error");
      return false;
    }

    if (startDate > endDate) {
      setError(true);
      setIsLoading(false);
      showToast(
        "La fecha de inicio no puede ser mayor a la fecha de finalización",
        "error"
      );
      return false;
    }

    if (startDate > eventDate) {
      setError(true);
      setIsLoading(false);
      showToast(
        "La fecha de inicio no puede ser mayor a la fecha del evento",
        "error"
      );
      return false;
    }

    if (endDate < eventDate) {
      setError(true);
      setIsLoading(false);
      showToast(
        "La fecha de finalización no puede ser menor a la fecha del evento",
        "error"
      );
      return false;
    }

    if (eventDate < startDate) {
      setError(true);
      setIsLoading(false);
      showToast(
        "La fecha del evento no puede ser menor a la fecha de inicio",
        "error"
      );
      return false;
    }

    if (!location || !description || !file || !name || !publisherId) {
      setError(true);
      setIsLoading(false);
      showToast("Todos los campos son requeridos", "error");
      return false;
    }

    return true; // Si todas las validaciones pasan, retorna true
  };

  const handleRegisterEvent = () => {
    setIsLoading(true);
    const formattedStartDate = formatDateString(startDate);
    const formattedEndDate = formatDateString(endDate);
    const formattedEventDate = formatDateString(eventDate);

    const isValid = validateForm(
      formattedStartDate,
      formattedEndDate,
      formattedEventDate
    );

    if (!isValid) return;

    registerEvent({
      name,
      endDate: formattedEndDate,
      eventDate: formattedEventDate,
      startDate: formattedStartDate,
      location,
      publisherId: publisherId || userData.publishers[0].id,
      description,
      customUrl,
      file: file as File
    })
      .then(() => {
        setIsLoading(false);
        showToast("Evento registrado correctamente", "success");
      })
      .catch((error) => {
        setIsLoading(false);
        showToast(error.message, "error");
      });
  };

  useEffect(() => {
    if(userData?.role === "ROLE_ADMIN") {
      // admins should not have a publisherId preselected
      return;
    }
    setPublisherId(userData.publishers[0].id);
  }, [user]);

  const isAllFilled =
    location &&
    startDate &&
    endDate &&
    eventDate &&
    description &&
    file &&
    name &&
    publisherId;

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      const config = { maxSizeKb: 500, width: 350, height: 350 };

      try {
        setIsResizing(true);
        const resizedFile = await resizeImage(selectedFile, config);
        setFile(resizedFile as File);
      } catch (error) {
        showToast("Error al redimensionar la imagen", "error");
      } finally {
        setIsResizing(false);
      }
    }
  };

  return (
    <Layout>
      <div
        id="register-module"
        className="flex flex-col bg-primary  w-full md:w-[450px] p-2 md:m-auto"
      >
        <div id="register-form">
          <div>
            <form className="flex flex-col">
              <label className="text-lg mb-1 text-tertiary">
                Nombre del evento *
              </label>
              <input
                className="border  mb-2 p-2 text-primary"
                type="text"
                disabled={isLoading}
                value={name}
                onChange={handleNameChange}
              />
              <p className="text-lg mb-1 text-tertiary">
                URL del evento *
              </p>
              <p className="text-lg mb-1 text-tertiary lora-bold">
                {window.location.href.replace(window.location.pathname, "")}/event/?name={customUrl}
              </p>
              <label className="text-lg mb-1 text-tertiary">Ubicación *</label>
              <input
                className="border  mb-2 p-2 text-primary"
                type="text"
                disabled={isLoading}
                value={location}
                onChange={handleChange(setLocation)}
              />
              {userData?.role !== "ROLE_PUBLISHER_ADMIN" && (
                <>
                  <PublisherIdDropdown
                    publisherId={publisherId}
                    setPublisherId={setPublisherId}
                  />
                </>
              )}

              <label className="text-lg mb-1 text-tertiary">
                Fecha de inicio de publicacion en la pagina *
              </label>
              <input
                className="border  mb-2 p-2 text-primary"
                type="datetime-local"
                disabled={isLoading}
                value={startDate}
                onChange={handleChange(setStartDate)}
              />
              <label className="text-lg mb-1 text-tertiary">
                Fecha de finalización de la publicacion en la pagina *
              </label>
              <input
                className="border  text-lg mb-2 p-2 text-primary"
                type="datetime-local"
                disabled={isLoading}
                value={endDate}
                onChange={handleChange(setEndDate)}
              />
              <label className="text-lg mb-1 text-tertiary">
                Fecha en que el evento se llevará a cabo *
              </label>
              <input
                className="border  mb-2 p-2 text-primary"
                type="datetime-local"
                disabled={isLoading}
                value={eventDate}
                onChange={handleChange(setEventDate)}
              />
              <label className="text-lg mb-1 text-tertiary">
                Descripción *
              </label>
              <textarea
                className="border  mb-2 p-2 text-primary resize-none h-[140px]"
                disabled={isLoading}
                value={description}
                onChange={handleChange(setDescription)}
                maxLength={1000}
              />
              <label className="text-lg mb-1 text-tertiary">
                Imagen (debe ser en formato JPG o PNG) *
              </label>
              <input
                className="border  mb-2 p-2 text-tertiary"
                type="file"
                disabled={isLoading}
                onChange={handleFileChange}
                onKeyDown={handleKeyDown}
              />
            </form>
          </div>
          <div id="register-form">
            <button
              className={`w-full  text-lg p-2 mt-4 text-primary ${!isLoading && isAllFilled ? "bg-secondary" : "bg-gray-200 cursor-not-allowed"}`}
              type="button"
              onClick={handleRegisterEvent}
              disabled={isLoading || !isAllFilled}
            >
              {isLoading ? "Registrando..." : "Registrar"}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RegisterEvent;
