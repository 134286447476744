import { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { deletePublisherApprovalUser } from "../../api/deletePublisherApprovalUser";
import { getAllPublisherApproval } from "../../api/getAllPublisherApproval";
import { Layout } from "../../components/Layout";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Pagination from "../../components/utils/Pagination";
import { useToast } from "../../context/ToastContext";
import { useRoute } from "../../hooks/useRoute"; // Ajusta la ruta según tu estructura
import {
  GetAllPublishersApprovalPayload,
  GetAllPublishersApprovalResponse,
  PublisherData
} from "../../types/GetAllPublishersApproval";
const PublishersApproval = () => {
  const { showToast } = useToast();
  const { params } = useRoute();
  const [publisherApprovals, setPublisherApprovals] = useState<PublisherData[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(params.page || "1", 10)
  );
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>(params.search || "");
  const [delayTerm, setDelayTerm] = useState<string>(searchTerm);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  const handleDeleteUser = async (user: PublisherData) => {
    try {
      await deletePublisherApprovalUser(user.publishers[0]?.id, selectedUserId); // Usamos el ID del usuario seleccionado
      setPublisherApprovals((prev) =>
        prev.filter((prevUser) => prevUser.id !== selectedUserId)
      );
      showToast("Usuario eliminado correctamente", "success");
      setOpenConfirmation(false);
    } catch (error) {
      showToast("Ha ocurrido un error al eliminar el usuario", "error");
    }
  };

  useEffect(() => {
    if (searchTerm !== "") {
      setCurrentPage(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchTerm(delayTerm);
    }, 500); // 500ms de retraso

    return () => {
      clearTimeout(handler);
    };
  }, [delayTerm]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (currentPage < 0) return; // Asegúrate de que currentPage sea válido

      const payload: GetAllPublishersApprovalPayload = {
        isActive: true,
        offset: currentPage * 10,
        page: searchTerm ? 0 : currentPage - 1,
        pageNumber: currentPage,
        pageSize: 10,
        paged: true,
        size: 10,
        sort: "id,asc",
        unpaged: false,
        searchTerm: searchTerm // Agrega el término de búsqueda al payload
      };

      try {
        setIsLoading(true);
        const response: GetAllPublishersApprovalResponse =
          await getAllPublisherApproval(payload);
        setPublisherApprovals(response.data.content);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      } catch (error) {
        showToast("Ha ocurrido un error al cargar los usuarios", "error");
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [currentPage, searchTerm, showToast]);

  return (
    <Layout>
      <div className="flex-col w-full p-2 h-full">
        <h1 className="text-secondary bg-primary inline-block px-4 py-2  h-full">
          Lista de Publishers Approvals
        </h1>
        <div className="flex justify-center mt-4">
          <input
            id="search"
            type="text"
            placeholder="Buscar usuario"
            className="w-[350px] p-2 lora-bold "
            value={delayTerm}
            onChange={(e) => setDelayTerm(e.target.value)}
          />
        </div>
        <div className="w-full h-full my-8 min-h-[520px]">
          <div className="hidden sm:flex flex-row py-2 font-bold text-md px-4">
            <div className="w-[80%] text-left">Nombre de publisher</div>
            <div className="w-[70%]">Teléfono</div>
            <div className="w-[80%]">Email</div>
            <div className="w-[5%]"></div>
          </div>
          <div className="flex flex-col w-full h-full gap-2">
            {publisherApprovals.length
              ? publisherApprovals.map((user) => (
                  <div
                    key={user.id}
                    className="flex flex-col sm:flex-row w-full h-full py-2 px-4  bg-primary text-secondary shadow-md shadow-black items-center"
                  >
                    <div className="sm:w-[80%] w-full flex gap-1">
                      <span className="sm:hidden font-bold">
                        Nombre de usuario:
                      </span>
                      {user.username}
                    </div>
                    <div className="sm:w-[70%] w-full flex gap-1">
                      <span className="sm:hidden font-bold">Teléfono:</span>
                      {user.phoneNumber || "N/A"}
                    </div>
                    <div className="sm:w-[80%] w-full flex gap-1">
                      <span className="sm:hidden font-bold">Email:</span>
                      {user.email || "N/A"}
                    </div>
                    <div className="sm:w-[5%] w-full h-full flex justify-end">
                      <FaTrash
                        className="cursor-pointer"
                        onClick={() => {
                          setSelectedUserId(user.id); // Guardamos el ID del usuario seleccionado
                          setOpenConfirmation(true); // Abrimos el modal
                        }}
                      />
                    </div>
                    <ConfirmationModal
                      isOpen={openConfirmation}
                      onClose={() => setOpenConfirmation(false)}
                      onConfirm={() => handleDeleteUser(user)} // Usamos el ID del usuario seleccionado
                      header="Eliminar usuario"
                      body="¿Estás seguro que deseas eliminar este usuario?"
                      confirmText="Eliminar"
                    />
                  </div>
                ))
              : null}
          </div>
        </div>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
    </Layout>
  );
};

export default PublishersApproval;
