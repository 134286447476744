import { ChangeEvent, useEffect, useState } from "react";
import { getAllPublisherOptions } from "../api/getPublisherOptions";
import { useToast } from "../context/ToastContext";
import { PublisherOption } from "../types/GetAllPublishers";

interface PublisherIdInputProps {
  publisherId: string;
  setPublisherId: (publisherId: string) => void;
}

const PublisherIdDropdown: React.FC<PublisherIdInputProps> = ({
  publisherId,
  setPublisherId
}) => {
  const { showToast } = useToast();
  const [options, setOptions] = useState<PublisherOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const options = await getAllPublisherOptions();
        setOptions(options.data);
        setIsLoading(false);
      } catch (error) {
        showToast("Error cargando datos", "error");
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, [showToast]);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPublisherId(event.target.value);
  };

  return (
    <>
      <label className="text-lg mb-1 text-tertiary">
        Selecciona una productora *
      </label>
      <select
        className="border mb-2 p-2 text-primary"
        disabled={isLoading}
        value={publisherId}
        onChange={handleChange}
      >
        <option value="" disabled>
          Seleccione una productora
        </option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

export default PublisherIdDropdown;
