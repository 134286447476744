import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useState } from "react";
import { registerUser } from "../../api/registerUser";
import { resendActivationCode } from "../../api/resendActivationCode";
import { validateActivationCode } from "../../api/validateActivationCode";
import GoogleLoginButton from "../../components/GoogleLoginButton";
import { Layout } from "../../components/Layout";
import { Link } from "../../components/router/Link";
import { appName } from "../../constants/enviroment";
import { useAppContext } from "../../hooks/useAppContext";
import { useRoute } from "../../hooks/useRoute";
import { isValidEmail } from "../../utils/isValidEmail";
import { useTranslation } from "react-i18next";

const LoginRegisterModal = () => {
  const {
    isAuthenticated,
    login,
    isLoading: isAuthLoading,
    authError
  } = useAppContext();
  const { t } = useTranslation("global");

  const [isLogin, setIsLogin] = useState(true);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [username, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isValidationCodeValid, setIsValidationCodeValid] = useState(false);
  const [activationError, setActivationError] = useState("");
  const [registrationError, setRegistrationError] = useState<string | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showValidationForm, setShowValidationForm] = useState(false);
  const [isReadTerms, setIsReadTerms] = useState(false);

  const { setRoute, params } = useRoute();

  const validatePassword = (password: string) => {
    return password.length >= 3;
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setIsEmailValid(isValidEmail(emailValue));
  };

  const handlePasswordLoginChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);
    setIsPasswordValid(validatePassword(passwordValue));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);
    setIsPasswordValid(passwordValue === passwordConfirmation);
  };

  const handlePasswordConfirmationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const passwordConfirmationValue = event.target.value;
    setPasswordConfirmation(passwordConfirmationValue);
    setIsPasswordValid(password === passwordConfirmationValue);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const handleValidationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const validationCodeValue = event.target.value;
    setCode(validationCodeValue);
    setIsValidationCodeValid(validationCodeValue.length === 4);
  };

  const handleAcceptTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsReadTerms(e.target.checked);
  };

  const handleLogin = () => {
    login({ email, password }, `/event/?eventid=${params.eventId}`);
  };

  const handleRegister = async () => {
    setIsLoading(true);
    try {
      const registerUserRequest = await registerUser({
        email,
        password,
        phoneNumber,
        username
      });
      if (registerUserRequest.status_code !== 200) {
        setRegistrationError(registerUserRequest.message);
        return;
      }
      setShowValidationForm(true);
    } catch (error: any) {
      setRegistrationError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidateAccount = async () => {
    try {
      setIsLoading(true);
      const validation = await validateActivationCode({ email, code });
      if (validation.status_code !== 200) {
        setActivationError(validation.message);
        return;
      }

      handleLogin();
    } catch (error) {
      setActivationError(
        "Ha ocurrido un error al activar tu cuenta, intentalo mas tarde"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendValidationCode = async () => {
    try {
      setIsLoading(true);
      const validation = await resendActivationCode({ email });
      if (validation.status_code !== 200) {
        setActivationError(
          "No se ha conseguido enviar el codigo de validacion, intentalo mas tarde"
        );
        return;
      }
      setActivationError(
        "Se ha enviado un nuevo codigo de validacion a tu correo"
      );
    } catch (error) {
      setActivationError("Ha ocurrido un error enviando el nuevo codigo");
    } finally {
      setIsLoading(false);
    }
  };

  const isLoginFormValid = isEmailValid && isPasswordValid;
  const isRegisterFormValid = isEmailValid && isPasswordValid && isReadTerms;

  return (
    <GoogleOAuthProvider clientId={`${clientId}`}>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <Layout>
          <div className="flex flex-col bg-primary w-full md:w-[450px] p-2 md:m-auto">
            {isLogin ? (
              <>
                <div className="mb-4">
                  <h1 className="text-center text-bold text-xl text-tertiary">
                    Bienvenido a{" "}
                    <span className="text-secondary">{appName}</span>
                  </h1>
                </div>
                <form className="flex flex-col">
                  <label className="text-lg mb-1 text-tertiary">Email</label>
                  <input
                    className="border rounded mb-2 p-2 text-primary"
                    type="text"
                    disabled={isAuthLoading}
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <label className="text-lg mb-1 text-tertiary">Password</label>
                  <input
                    className="border rounded text-lg mb-2 p-2 text-primary"
                    type="password"
                    disabled={isAuthLoading}
                    value={password}
                    onChange={handlePasswordLoginChange}
                    onKeyDown={(e) => e.key === "Enter" && handleLogin()}
                  />
                  <button
                    className={`text-lg mt-4 p-2 text-primary ${isLoginFormValid && !isAuthLoading ? "bg-secondary" : "bg-secondary opacity-30 cursor-not-allowed"}`}
                    type="button"
                    onClick={handleLogin}
                    disabled={!isLoginFormValid || isAuthLoading}
                    title={
                      !isLoginFormValid || isAuthLoading
                        ? "Completa el formulario"
                        : "Ingresar"
                    }
                  >
                    {isAuthLoading ? "Ingresando..." : "Ingresar"}
                  </button>
                  <button
                    type="button"
                    className="text-lg uppercase mt-4 p-2 text-primary bg-secondary text-center"
                    onClick={() => setIsLogin(false)}
                  >
                    Registrarse
                  </button>
                  <Link to={`/forgot-password`}>
                    <p className="text-lg uppercase mt-4 p-2 text-primary bg-secondary text-center">
                      Olvide mi contraseña
                    </p>
                  </Link>
                  {authError && (
                    <p className="text-red-500 mt-2">{authError}</p>
                  )}
                </form>
                <div className="mt-4 justify-center w-full items-center text-center">
                  {/* [ST-5]Temporarily comment out Google login button  */}
                  {/* <div className="w-full flex justify-center">
                    <GoogleLoginButton
                      path={`/event/?eventid=${params.eventId}`}
                    />
                  </div> */}
                  <p className="text-tertiary mt-4">
                  {t("auth.by-using-our-services")}{" "}
                    <Link to="/terms-and-conditions">
                      <span className="text-secondary cursor-pointer uppercase">
                        {t("auth.terms-and-conditions")}
                      </span>
                    </Link>
                  </p>
                </div>
              </>
            ) : (
              <>
                {!showValidationForm ? (
                  <>
                    <div className="mb-4">
                      <h1 className="text-center text-bold text-xl text-tertiary">
                        Bienvenido a{" "}
                        <span className="text-secondary">{appName}</span>
                      </h1>
                    </div>
                    <form className="flex flex-col">
                      <label className="text-lg mb-1 text-tertiary">
                        Nombre *
                      </label>
                      <input
                        className="border rounded mb-2 p-2 text-primary"
                        type="text"
                        disabled={isLoading}
                        value={username}
                        onChange={handleChangeName}
                      />
                      <label className="text-lg mb-1 text-tertiary">
                        Email *
                      </label>
                      <input
                        className="border rounded mb-2 p-2 text-primary"
                        type="text"
                        disabled={isLoading}
                        value={email}
                        onChange={handleEmailChange}
                      />
                      <label className="text-lg mb-1 text-tertiary">
                        Telefono *
                      </label>
                      <input
                        className="border rounded mb-2 p-2 text-primary"
                        type="text"
                        disabled={isLoading}
                        value={phoneNumber}
                        onChange={handleChangePhone}
                      />
                      <label className="text-lg mb-1 text-tertiary">
                        Password *
                      </label>
                      <input
                        className="border rounded text-lg mb-2 p-2 text-primary"
                        type="password"
                        disabled={isLoading}
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      <label className="text-lg mb-1 text-tertiary">
                        Confirmar Password *
                      </label>
                      <input
                        className="border rounded text-lg mb-2 p-2 text-primary"
                        type="password"
                        disabled={isLoading}
                        value={passwordConfirmation}
                        onChange={handlePasswordConfirmationChange}
                      />
                      <div className="mt-2">
                        <label className="text-tertiary">
                          <input
                            type="checkbox"
                            disabled={isLoading}
                            className="mr-2"
                            checked={isReadTerms}
                            onChange={handleAcceptTerms}
                          />
                          He leido y acepto los
                          <Link to="/terms-and-conditions">
                            <span className="text-secondary cursor-pointer">
                              {" "}
                              terminos y condiciones
                            </span>
                          </Link>
                        </label>
                      </div>
                      <button
                        className={`text-lg mt-4 p-2 text-primary ${isRegisterFormValid && !isLoading ? "bg-secondary" : "bg-secondary opacity-30 cursor-not-allowed"}`}
                        type="button"
                        onClick={handleRegister}
                        disabled={!isRegisterFormValid || isLoading}
                        title={
                          !isRegisterFormValid || isLoading
                            ? "Completa el formulario"
                            : "Registrar"
                        }
                      >
                        {isLoading ? "Registrando..." : "Registrar"}
                      </button>
                      <button
                        type="button"
                        className="text-lg mt-4 p-2 text-primary bg-secondary text-center"
                        onClick={() => setIsLogin(true)}
                      >
                        Volver al Login
                      </button>
                    </form>
                    {registrationError && (
                      <p className="text-red-500 mt-2">{registrationError}</p>
                    )}
                  </>
                ) : (
                  <form className="flex flex-col">
                    <p className="text-lg mb-1 text-tertiary mb-2">Se ha enviado un codigo de 4 digitos a tu correo electronico, a continuacion deberas ingresarlo para continuar con el proceso de registro</p>
                    <label className="text-lg mb-1 text-tertiary">
                      Codigo de Validacion *
                    </label>
                    <input
                      className="border rounded text-lg mb-2 p-2 text-primary"
                      type="text"
                      disabled={isLoading}
                      value={code}
                      onChange={handleValidationCodeChange}
                      maxLength={4}
                    />
                    <button
                      className={`text-lg mt-4 p-2 text-primary ${isValidationCodeValid && !isLoading ? "bg-secondary" : "bg-secondary opacity-30 cursor-not-allowed"}`}
                      type="button"
                      onClick={handleValidateAccount}
                      disabled={!isValidationCodeValid || isLoading}
                      title={
                        !isValidationCodeValid || isLoading
                          ? "Completa el formulario"
                          : "Validar Codigo"
                      }
                    >
                      {isLoading ? "Validando..." : "Validar Codigo"}
                    </button>
                    <button
                      type="button"
                      className="text-lg mt-4 p-2 text-primary bg-secondary text-center"
                      onClick={handleResendValidationCode}
                    >
                      Reenviar codigo de validacion
                    </button>
                    {activationError && (
                      <p className="text-red-500 mt-2">{activationError}</p>
                    )}
                  </form>
                )}
              </>
            )}
          </div>
        </Layout>
      </div>
    </GoogleOAuthProvider>
  );
};

export default LoginRegisterModal;
