import { Layout } from "../../components/Layout";
import { useAppContext } from "../../hooks/useAppContext";

export const Logout = () => {
  const { user, logout } = useAppContext();

  return (
    <Layout>
      <div
        id="login-module"
        className="flex flex-col bg-primary w-full md:w-[450px] p-2 md:m-auto"
      >
        <div id="login-headers" className="mb-4">
          <h1 className="lora-bold text-center text-bold text-xl text-tertiary">
            Estas autenticado como:{" "}
            <span className="text-secondary">{user?.email}</span>
          </h1>
          <p className="lora-regular text-center text-regular text-xl text-tertiary">
            Deseas cerrar tu sesión?
          </p>
        </div>
        <div id="email-login">
          <form className="flex flex-col">
            <button
              className="lora-bold text-lg mt-4 p-2 text-primary bg-secondary text-center uppercase"
              type="button"
              onClick={logout}
            >
              Cerrar sesión
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};
