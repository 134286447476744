// ToastContext.tsx
import React, { createContext, useCallback, useContext, useState } from "react";
import Toast from "../components/Toast";

// Definir los tipos de Toast
type ToastType = "error" | "success" | "info";

// Definir el tipo para el contexto
interface ToastContextType {
  showToast: (message: string, type?: ToastType, duration?: number) => void;
}

// Crear el contexto
const ToastContext = createContext<ToastContextType | undefined>(undefined);

// Hook para usar el contexto
export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

// Proveedor del contexto
export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [toast, setToast] = useState<{
    message: string;
    show: boolean;
    duration: number;
    type: ToastType;
  }>({
    message: "",
    show: false,
    duration: 3000,
    type: "info"
  });

  const showToast = useCallback(
    (message: string, type: ToastType = "info", duration = 3000) => {
      setToast({ message, show: true, duration, type });
      setTimeout(() => {
        setToast({ message: "", show: false, duration: 3000, type: "info" });
      }, duration);
    },
    []
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast
        message={toast.message}
        show={toast.show}
        duration={toast.duration}
        type={toast.type}
      />
    </ToastContext.Provider>
  );
};
