import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../../components/Layout";
import validateTicket from "../../api/validateTicket";
import jsQR from "jsqr";
import { useToast } from "../../context/ToastContext";
import getTicketInformation from "../../api/getTicketInformation";
import {
  BouncerEventInfo,
  GetTicketInformationResponse
} from "../../types/GetTicketInformation";

export const QRScanAndRedeem = () => {
  const { showToast } = useToast();
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isLoading, setIsLoading] = useState(false); // Track API request in progress
  const [ticketInfo, setTicketInfo] = useState<BouncerEventInfo | null>(null);
  const [scannedCode, setScannedCode] = useState<number | null>(null); // Store the scanned code
  const [isScanning, setIsScanning] = useState(true); // Control whether to scan or not

  useEffect(() => {
    const startCamera = async () => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: "environment" } // Use rear camera
          });
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        } catch (err) {
          showToast("Error accessing camera", "error");
        }
      }
    };

    startCamera();
  }, []);

  const scanQRCode = () => {
    if (!canvasRef.current || !videoRef.current || !isScanning) return;

    const canvas = canvasRef.current;
    const video = videoRef.current;
    const ctx = canvas.getContext("2d");

    if (ctx && video.videoWidth > 0 && video.videoHeight > 0) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Clear the canvas before drawing a new frame
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw the current video frame onto the canvas
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, canvas.width, canvas.height);
      const detectedCode = Number(code?.data);

      if (code && detectedCode) {
        setScannedCode(detectedCode); // Store the detected QR code
        setIsScanning(false); // Stop scanning after code is found
      }

      if (isScanning) {
        requestAnimationFrame(scanQRCode); // Continue scanning if no QR code found
      }
    }
  };

  const fetchTicketInformation = async (ticketId: number) => {
    setIsLoading(true);
    try {
      const response: GetTicketInformationResponse =
        await getTicketInformation(ticketId);
      if (response.status_code === 200) {
        setTicketInfo(response.data); // Store ticket information
      } else {
        showToast(response.message, "error");
        setTicketInfo(null);
      }
    } catch (error) {
      showToast((error as Error).message, "error");
      setTicketInfo(null);
    } finally {
      setIsLoading(false);
      resetScanner();
    }
  };

  useEffect(() => {
    const handleLoadedMetadata = () => {
      if (videoRef.current) {
        videoRef.current.play(); // Play video after metadata is loaded
        requestAnimationFrame(scanQRCode); // Start scanning
      }
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      }
    };
  }, [isScanning]);

  useEffect(() => {
    if (scannedCode !== null) {
      fetchTicketInformation(scannedCode);
    }
  }, [scannedCode]);

  const handleSubmit = async () => {
    if (scannedCode === null) return;

    setIsLoading(true);
    try {
      const validation = await validateTicket({ id: scannedCode });
      if (validation.status_code !== 200) {
        showToast(validation.message, "error");
      } else {
        showToast(validation.message, "success");
      }
    } catch (error) {
      showToast((error as Error).message, "error");
    } finally {
      setIsLoading(false); // Processing finished
      setTicketInfo(null); // Clear ticket information
      resetScanner(); // Reset after submit
    }
  };

  const handleCancel = () => {
    resetScanner(); // Simply reset scanner and resume
  };

  const resetScanner = () => {
    setScannedCode(null); // Reset the scanned code
    setIsScanning(true); // Resume scanning
    requestAnimationFrame(scanQRCode); // Restart the scanning loop
  };

  return (
    <Layout>
      <div
        className={`w-full max-w-[1500px] m-auto ${isLoading ? "opacity-5" : null}`}
      >
        <div className="flex flex-row flex-wrap m-auto justify-center mt-4">
          <div>
            <p className="text-primary text-lg lora-bold">SCAN QR CODE</p>
          </div>
        </div>
        {/* Display buttons and scanned code */}
        {scannedCode && ticketInfo ? (
          <div className="mt-4">
            <p>
              <strong>Event Name:</strong> {ticketInfo.eventName}
            </p>
            <p>
              <strong>Event Date:</strong> {ticketInfo.eventDate}
            </p>
            <p>
              <strong>Publisher Name:</strong> {ticketInfo.publisherName}
            </p>
            <p>Scanned Ticket Code: {scannedCode}</p>
            <button
              className="mr-4 px-4 py-2 bg-blue-500 text-white"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Submit
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white"
              onClick={handleCancel}
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        ) : (
          <p> Scanning...</p>
        )}
        <div className="flex justify-center mt-8">
          {/* Display the video stream for user */}
          <video
            ref={videoRef}
            className="border-2 border-gray-300"
            playsInline
          />

          {/* Canvas is hidden, used only for scanning */}
          <canvas ref={canvasRef} style={{ display: "none" }} />
        </div>
      </div>
    </Layout>
  );
};
