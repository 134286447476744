import { baseUrl } from "../constants/enviroment";
import {
  UpdateUserInformationPayload,
  UpdateUserInformationResponse,
} from "../types/UserProfileUpdate";
import { getCookie } from "../utils/getCookie";

export const updateUserInformation = async (
  data: UpdateUserInformationPayload,
): Promise<UpdateUserInformationResponse> => {
  try {
    const jwtFromCookie = getCookie("jwt");
    const response = await fetch(`${baseUrl}/user/updateUserInformation`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${jwtFromCookie}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`${errorData.message}`);
    }

    return await response.json();
  } catch (error: any) {
    throw error;
  }
};
