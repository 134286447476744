import { useEffect, useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import {
  MdAccessTime,
  MdDateRange,
  MdEdit,
  MdHourglassEmpty,
  MdPlace
} from "react-icons/md";
import { deleteEvent } from "../../api/deleteEvent";
import { deleteEventTier } from "../../api/deleteEventTier";
import { getPublisherEventDetails } from "../../api/getPublisherEventDetails";
import { updatePublisherTiers } from "../../api/updatePublisherTiers";
import { Layout } from "../../components/Layout";
import AddTiersModal from "../../components/modals/AddTiersModal";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import EditEventsModal from "../../components/modals/EditEventsModal";
import EditSpecificTierModal from "../../components/modals/EditSpecificTierModal";
import { useToast } from "../../context/ToastContext";
import { useModalManager } from "../../hooks/useModalManager";
import { useRoute } from "../../hooks/useRoute";
import { EventData, EventTiersData } from "../../types/EventDataTypes";
import { formatNumbersToPriceString } from "../../utils/numberPriceFormat";

const EventPublisherDetails = () => {
  const { showToast } = useToast();
  const { setRoute } = useRoute();
  const {
    openModal,
    openSpecificModal,
    closeModal,
    currentEvent,
    currentTierId
  } = useModalManager();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ids, setIds] = useState<number | null>(null);
  const [eventDetails, setEventDetails] = useState<EventData[] | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const handleDeleteEvent = async (event: EventData) => {
    try {
      await deleteEvent(event.id.toString());
      showToast("Evento eliminado correctamente", "success");
      setOpenConfirmation(false);
      window.history.pushState({}, "", `/publisher-events`);
      setRoute(`/publisher-events`);
    } catch (error: any) {
      showToast(error.message, "error");
    }
  };

  const handleDeleteTier = async () => {
    if (currentTierId !== null) {
      try {
        setIsLoading(true);
        await deleteEventTier(currentTierId.toString());
        showToast("Localidad eliminada correctamente", "success");
        setRefresh((prev) => !prev);
      } catch (error: any) {
        showToast(error.message, "error");
      } finally {
        setIsLoading(false);
      }
    }
    closeModal();
  };

  const handlePositionChange = async (
    tier: EventTiersData,
    newPosition: number
  ) => {
    try {
      setIsLoading(true);
      await updatePublisherTiers({
        id: tier.id,
        position: newPosition
      });
      showToast(
        `Posición del tier ${tier.name} actualizada correctamente`,
        "success"
      );
      setRefresh((prev) => !prev);
    } catch (error: any) {
      showToast(error.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const eventId = searchParams.get("eventId");
    if (eventId) {
      setIds(Number(eventId));
    }
  }, []);

  useEffect(() => {
    const fetchEventDetails = async (ids: number) => {
      try {
        setIsLoading(true);
        const eventResponse = await getPublisherEventDetails(ids);
        setEventDetails(eventResponse.data.content);
      } catch (error) {
        showToast("Ha ocurrido un error al cargar los eventos", "error");
      } finally {
        setIsLoading(false);
      }
    };
    if (ids) {
      fetchEventDetails(ids);
    }
  }, [ids, refresh, showToast]);

  if (!ids || !eventDetails || eventDetails.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-gray-500 text-xl">Event not found</p>
      </div>
    );
  }
  const tierToEdit = eventDetails[0]?.eventTiers.find(
    (tier) => tier.id === currentTierId
  );
  return (
    <Layout>
      <div
        id="event-page"
        className={`container mx-auto p-6 bg-primary shadow-lg ${isLoading ? "opacity-50" : null}`}
      >
        {openConfirmation && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
            <ConfirmationModal
              isOpen={openConfirmation}
              onClose={() => setOpenConfirmation(false)}
              onConfirm={() => handleDeleteEvent(eventDetails[0])}
              header="Eliminar Evento"
              body="¿Estás seguro que deseas eliminar este evento?"
              confirmText="Eliminar"
            />
          </div>
        )}
        <h1 className="text-3xl font-bold text-center text-secondary">
          {eventDetails[0]?.name}
        </h1>
        <h3 className="font-bold mb-6 text-center text-tertiary text-sm">
          {eventDetails[0]?.publisher.name}
        </h3>
        <div className="flex flex-col md:flex-row mb-6">
          <img
            src={eventDetails[0]?.imageUrl}
            alt={eventDetails[0]?.name}
            className="md:w-[350px] md:h-[350px] w-[300px] h-[300px] shadow-md"
          />
          <div className="w-full md:w-1/2 md:pl-6 mt-4 md:mt-0">
            <div className="flex w-full justify-between gap-1 md:gap-[100px]">
              <div className="flex w-full bg-secondary justify-between px-8 py-2">
                <p className="text-primary flex items-center gap-1">
                  <strong>{<MdPlace />}</strong> {eventDetails[0]?.location}
                </p>
                <p className="text-primary flex items-center gap-1">
                  <strong>
                    <MdDateRange />
                  </strong>
                  {new Date(eventDetails[0]?.eventDate).toLocaleDateString()}
                </p>
              </div>
              <div className="flex items-end gap-2">
                <button
                  onClick={() =>
                    openSpecificModal("editEventModal", eventDetails[0])
                  }
                  className="bg-secondary hover:bg-opacity-80 text-primary  h-full px-4 shadow-lg transition-all duration-300"
                >
                  <MdEdit size={20} />
                </button>
                <button
                  className="bg-secondary text-primary px-4 hover:bg-opacity-90 h-full flex justify-center items-center "
                  onClick={() => setOpenConfirmation(true)}
                >
                  <FaTrash className="cursor-pointer " />
                </button>
              </div>
            </div>

            <p className="text-tertiary py-2 text-justify">
              {eventDetails[0]?.description}
            </p>
            <p className="text-tertiary flex items-center gap-1">
              <strong className="text-secondary flex gap-1 items-center">
                <MdAccessTime />
                Inicio de venta de entradas:{" "}
              </strong>

              {new Date(eventDetails[0]?.startDate).toLocaleDateString()}
            </p>
            <p className="text-tertiary flex items-center gap-1">
              <strong className="text-secondary flex gap-1 items-center">
                <MdHourglassEmpty />
                Fin de venta de entradas:{" "}
              </strong>

              {new Date(eventDetails[0]?.endDate).toLocaleDateString()}
            </p>

            <p className="text-tertiary">
              <strong className="text-secondary">Link del evento:</strong>{" "}
              <a
                className="text-blue-500"
                href={`/event/?name=${eventDetails[0]?.customUrl}`}
              >
                {window.location.href.replace(window.location.pathname, "")}
                {`/event/?name=${eventDetails[0]?.customUrl}`}
              </a>
            </p>

            <p className="text-tertiary">
              <strong className="text-secondary">Activo:</strong>{" "}
              {eventDetails[0]?.isActive ? "Si" : "No"}
            </p>
            <p className="text-tertiary">
              <strong className="text-secondary">Evento pagado:</strong>{" "}
              {eventDetails[0]?.isPaid ? "Si" : "No"}
            </p>
          </div>
        </div>

        <h2 className="text-2xl font-semibold mb-4 text-secondary">
          Localidades
        </h2>
        <div>
          <button
            onClick={() => openSpecificModal("addTierModal", eventDetails[0])}
            className="bg-secondary hover:bg-opacity-80 text-primary px-4 py-2 shadow-lg transition-all duration-300 inline-block my-4 max-w-[200px]"
          >
            <FaPlus size={20} />
          </button>
        </div>

        {eventDetails[0]?.eventTiers.length === 0 ? (
          <div className="flex flex-col justify-center  text-center w-full">
            <p className="text-center text-gray-500">
              No hay localidades disponibles para este evento. Si deseas agregar
              localidades, puedes hacerlo desde el botón correspondiente.
            </p>
          </div>
        ) : (
          eventDetails[0]?.eventTiers.map((tier: EventTiersData) => (
            <ul className="space-y-4 mb-6">
              <li key={tier.id} className="flex gap-2">
                <div className="flex flex-col gap-2">
                  <span className="text-secondary">Posición</span>
                  <select
                    value={tier.position}
                    onChange={(e) =>
                      handlePositionChange(tier, Number(e.target.value))
                    }
                    className="border p-2"
                  >
                    {eventDetails[0]?.eventTiers.map((_, index) => (
                      <option key={index} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="p-4 bg-tertiary shadow-md flex flex-col md:flex-row justify-between h-full w-full">
                  <div className="flex flex-row  ">
                    <div className="flex gap-2">
                      <p className="font-bold text-gray-800">{tier.name}</p>
                      <p className="font-bold text-red-500">
                        {tier.isSoldOut && "AGOTADA"}
                      </p>
                    </div>
                    {/* <p className="text-primary">
                      Price: {formatNumbersToPriceString(tier.price)}
                    </p>
                    <p className="text-primary">
                      Sold Quantity: {tier.soldQuantity}/{tier.maxQuantity}
                    </p> */}
                  </div>
                  <p className="text-primary">
                    Price: {formatNumbersToPriceString(tier.price)}
                  </p>
                  <p className="text-primary">
                    Sold Quantity: {tier.soldQuantity}/{tier.maxQuantity}
                  </p>
                  <p>Total Tier Sold: {tier.soldQuantity * tier.price}</p>
                  <div className="flex gap-2">
                    <button
                      onClick={() =>
                        openSpecificModal(
                          "editSpecificTierModal",
                          undefined,
                          tier.id
                        )
                      }
                      className="bg-secondary hover:bg-opacity-80 text-primary shadow-lg transition-all duration-300 px-6"
                    >
                      <MdEdit size={20} />
                    </button>
                    <button
                      onClick={() =>
                        openSpecificModal(
                          "confirmationModal",
                          undefined,
                          tier.id
                        )
                      }
                      className="bg-secondary hover:bg-opacity-80 text-primary  shadow-lg transition-all duration-300 px-6"
                    >
                      <FaTrash size={20} />
                    </button>
                  </div>
                  {openModal === "editSpecificTierModal" && tierToEdit && (
                    <EditSpecificTierModal
                      onHide={() => closeModal()}
                      show={openModal === "editSpecificTierModal"}
                      tier={tierToEdit}
                      refresh={() => setRefresh((prev) => !prev)}
                    />
                  )}
                  {openModal === "confirmationModal" && (
                    <ConfirmationModal
                      isOpen={openModal === "confirmationModal"}
                      onClose={closeModal}
                      onConfirm={handleDeleteTier}
                      header="Eliminar Localidad"
                      body="¿Estás seguro que deseas eliminar esta localidad?"
                      confirmText="Eliminar"
                    />
                  )}
                </div>
              </li>
            </ul>
          ))
        )}

        {eventDetails[0]?.eventTiers.length === 0 ? null : (
          <p className="text-xl text-tertiary">
            Total Event Sold{" "}
            {eventDetails[0]?.eventTiers.reduce(
              (acc, tier) => acc + tier.soldQuantity * tier.price,
              0
            )}
          </p>
        )}

        {openModal === "addTierModal" && currentEvent && (
          <AddTiersModal
            show={openModal === "addTierModal"}
            onHide={closeModal}
            event={currentEvent}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}
        {openModal === "editEventModal" && currentEvent && (
          <EditEventsModal
            show={openModal === "editEventModal"}
            onHide={closeModal}
            event={currentEvent}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}
      </div>
    </Layout>
  );
};

export default EventPublisherDetails;
