import { AppLoader } from "./components/AppLoader";
import { Header } from "./components/Header";
import { Router } from "./components/router/Router";
import { Footer } from "./components/Footer";
import { AppContextProvider } from "./context/AppContext";
import { RouteProvider } from "./context/RouteContext";
import { ToastProvider } from "./context/ToastContext";
import { getProjectConfig } from "./getProjectConfig";

// Translations.
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import global_en from "./translations/en/global.json";
import global_es from "./translations/es/global.json";

// Initilize translations.
i18next.init({
  interpolation: { escapeValue: false },
  lng: getProjectConfig().LAN,
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    }
  }
});

function App() {
  return (
    <I18nextProvider i18n={i18next}>
      <ToastProvider>
        <RouteProvider>
          <AppContextProvider>
            <AppLoader />
            <Header />
            <Router />
            <Footer />
          </AppContextProvider>
        </RouteProvider>
      </ToastProvider>
    </I18nextProvider>
  );
}

export default App;
