import { EventOrderPayloadItem, EventOrderResponse } from "../types/EventOrder";
import { getCookie } from "../utils/getCookie";
import { baseUrl } from "../constants/enviroment";

export const registerEventOrder = async (
  data: EventOrderPayloadItem[],
): Promise<EventOrderResponse> => {
  const jwtFromCookie = getCookie("jwt");
  try {
    const response = await fetch(`${baseUrl}/user/registerEventOrder`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtFromCookie}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (error: any) {
    return error;
  }
};
